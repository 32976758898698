<style scoped>
.price-ship {}
</style>
<template>
    <div :class="'body-ship spaceship'">
        <RouterLink :to="'/market/' + item._id">
            <div class="header-ship">
                <div class="header-title" style="margin-top: 45px;">{{ item.name }}</div>
            </div>
            <div class="image-ship">
                <img src="@/assets/images/dnc_bucket.webp" />
                <span></span>
            </div>
            <div class="footer-ship">
                <img src="../../static/images/logo-list-item.png" />
                {{ priceRound(item.price) }} USDC
            </div>
        </RouterLink>
    </div>
</template>

<script>
export default {
    el: "#app",
    components: {},
    name: "my_assets",
    props: ['item'],
    data() {
        return {

        };
    },
    methods: {
        async doBuyMarketplaceItem() {
            this.$store.dispatch("marketplace/paymentUSDC", {
                item_id: this.item._id
            });

        },
        priceRound(p) {
            if (!p) return "";
            return p.toFixed(0)
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },
    },
    mounted: function () {
    },

    watch: {
    },
};
</script>
