<style scoped>
.small-link {
    font-size: 12px;
    color: white;
}

.spaceship-attr {
    text-align: left;
    padding: 21px 0 7px 0;
}

.spaceship-attr li {
    list-style: none;
}

.btn-opaque {
    opacity: 0.3;
}

.card {
    padding: 16px 32px;
}

.card .rounder-icon {
    border-radius: 5px;
    padding: 3px;
    margin-right: 16px
}

.title-card,
.page-pretitle {
    font-family: 'Azonix', sans-serif;
}

.content-card {
    margin-top: 16px;
    font-size: 26px;
    font-weight: bold;
}

.btn-card {
    background-color: #337C73;
    display: block;
    text-align: center;
    margin-top: 16px;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer
}

.btn-card:hover {
    opacity: 0.5;
}

.upgrading-text {
    color: #F23939;
    font-size: 30px;
    font-family: 'Azonix', sans-serif;
}

.spaceship-attr-upgrading li {
    list-style: none;
}

.upgrading-block {
    background-color: #26282D;
    border-radius: 8px;
    margin-top: 16px;
}

.time-blocking {
    text-align: center;
    border: solid 1px #F23939;
    background-color: #c53e3e;
    display: block;
    padding: 7px;
    margin-top: 32px;
    border-radius: 8px;
}

.card-spaceship {
    background-color: #26282d;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 16px;
}

.ship-class {
    box-shadow: none;
}

ul.spaceship-attr svg {
    width: 18px;
    color: #757171;
}

.page-pretitle h4 {
    font-family: 'Azonix', sans-serif;
}

.text-white a {
    color: white !important;
}
</style>
<template>
    <div class="container-xl">
        <div style="height: 10rem;"></div>
        <div class="row">
            <div class="col-md-12">
                <div class="page-header ">
                    <div class="page-pretitle">Home > My Assets</div>
                </div>
                <div class="row pt-3 text-white">
                    <div class="col-12 col-md-4">
                        <div class="card" style="background-color: #6647ED;">
                            <div class="title-card">
                                <span class="rounder-icon" style="background-color: #8561FF;">
                                    <svg id="Group_1" data-name="Group 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Path_1" data-name="Path 1" d="M0,0H24V24H0Z" fill="none" />
                                        <path id="Path_2" data-name="Path 2" d="M20,4v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_3" data-name="Path 3" d="M20,20v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_4" data-name="Path 4" d="M20,16v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_5" data-name="Path 5" d="M20,12v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_6" data-name="Path 6" d="M20,8v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_7" data-name="Path 7" d="M8,4,8,5A1,1,0,0,1,9,4h6a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_8" data-name="Path 8" d="M4,4v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_9" data-name="Path 9" d="M4,20v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_10" data-name="Path 10" d="M4,16v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_11" data-name="Path 11" d="M4,12v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_12" data-name="Path 12" d="M4,8v.01" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </svg>
                                </span>
                                DNC VAULT
                            </div>
                            <div class="content-card">
                                {{ totalDNC }} DNC
                            </div>
                        </div>
                        <RouterLink to="/my-assets/dnc-history" class="btn-card" style="background-color: #4C34B7;">
                            <svg id="Group_4" data-name="Group 4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path id="Path_20" data-name="Path 20" d="M0,0H24V24H0Z" fill="none" />
                                <path id="Path_21" data-name="Path 21" d="M3.06,13a9,9,0,1,0,.49-4.087" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_22" data-name="Path 22" d="M3,4V9H8" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_23" data-name="Path 23" d="M12,12m-1,0a1,1,0,1,0,1-1,1,1,0,0,0-1,1" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            </svg>

                            History
                        </RouterLink>
                    </div>

                    <div class="col-12 col-md-4">
                        <div class="card" style="background-color: #54C0B2;">
                            <div class="title-card">
                                <span class="rounder-icon" style="background-color: #83CBC5;">
                                    <svg id="Group_2" data-name="Group 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Path_13" data-name="Path 13" d="M0,0H24V24H0Z" fill="none" />
                                        <path id="Path_14" data-name="Path 14" d="M4,4,4,8A4,4,0,0,1,8,4h8a4,4,0,0,1,4,4v8a4,4,0,0,1-4,4H8a4,4,0,0,1-4-4Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_15" data-name="Path 15" d="M9,8a3,3,0,0,0,6,0" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </svg>

                                </span>Selling
                            </div>
                            <div class="content-card">
                                {{ listingDNC }} DNC
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <a class="btn-card" @click="doShowSellingDNC">
                                    <svg id="Group_3" data-name="Group 3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Path_16" data-name="Path 16" d="M0,0H24V24H0Z" fill="none" />
                                        <path id="Path_17" data-name="Path 17" d="M9,12h6" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_18" data-name="Path 18" d="M12,9v6" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_19" data-name="Path 19" d="M4,6V5A1,1,0,0,1,5,4H6m5,0h2m5,0h1a1,1,0,0,1,1,1V6m0,5v2m0,5v1a1,1,0,0,1-1,1H18m-5,0H11M6,20H5a1,1,0,0,1-1-1V18m0-5V11M4,6" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </svg>
                                    Sell
                                </a>
                            </div>
                            <div class="col-6">
                                <RouterLink to="/my-assets/dnc-listing" class="btn-card">
                                    <svg id="Group_4" data-name="Group 4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Path_20" data-name="Path 20" d="M0,0H24V24H0Z" fill="none" />
                                        <path id="Path_21" data-name="Path 21" d="M3.06,13a9,9,0,1,0,.49-4.087" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_22" data-name="Path 22" d="M3,4V9H8" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_23" data-name="Path 23" d="M12,12m-1,0a1,1,0,1,0,1-1,1,1,0,0,0-1,1" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </svg>

                                    DNC Listing
                                </RouterLink>
                            </div>
                        </div>

                    </div>

                    <div class="col-12 col-md-4">

                        <div class="card" style="background-color: rgb(39 41 56);">
                            <div class="title-card">
                                <span class="rounder-icon" style="background-color:#747480">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rocket" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" />
                                        <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" />
                                        <path d="M15 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                    </svg>
                                </span>
                                Assets
                            </div>
                            <div class="content-card">
                                {{ total }} Spaceships
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-6">

                                <RouterLink to="/my-assets/spaceships-selling" class="btn-card" style="background-color:#404354">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-building-store">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 21l18 0" />
                                        <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4" />
                                        <path d="M5 21l0 -10.15" />
                                        <path d="M19 21l0 -10.15" />
                                        <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
                                    </svg>
                                    Listed
                                </RouterLink>
                            </div>
                            <div class="col-6">
                                <RouterLink to="/my-assets" class="btn-card">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rocket">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" />
                                        <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" />
                                        <path d="M15 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                    </svg>

                                    Assets
                                </RouterLink>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <router-view />


        <div id="exampleModal" tabindex="-1" class="modal modal-blur fade show" role="dialog" aria-modal="true" active="true" v-show="listingDNCDialog.show">
            <div class="modal-dialog" id="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Listing DNC to marketplace.
                        </h5>
                        <button @click="listingDNCDialog.show = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="overflow: hidden">
                        <div class="row">
                            <div class="col-sm-6 title-balance">
                                <b>DNC</b> <br />
                                <small>Amount DNC you want to sell </small>
                            </div>
                            <div class="col-sm-6 value-balance">
                                <input class="form-control" v-model="listingDNCDialog.dnc" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 title-balance">
                                <b>Price (USDC)</b> <br />
                                <small>Price you want to sell</small>
                            </div>
                            <div class="col-sm-6 value-balance">
                                <input class="form-control" v-model="listingDNCDialog.price" />
                            </div>
                        </div>

                        <p>
                            <i>Please be informed that a 5% fee will be applied to your transaction.</i>
                        </p>
                        <div class="row mt-5">
                            <div class="col-sm-12 text-center">
                                <button class="btn approve" @click="doListingDNC">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M6 12l6 -9l6 9l-6 9z"></path>
                                        <path d="M6 12l6 -3l6 3l-6 2z"></path>
                                    </svg>
                                    Listing DNC Package to marketplace
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";
import { mapState } from "vuex";

function objectToConcat(object1) {
    let item = [];
    for (const [key, value] of Object.entries(object1)) {
        if (value) {
            item.push(key);
        }
    }
    return item.join(',');
}


export default {
    name: "HomeMarketpalce",
    components: {
    },
    data() {
        return {
            totalDNC: 'Loading ...',
            listingDNC: 'Loading ...',
            listingDialog: {
                show: false,
                item: undefined,
                price: 0
            },
            listingDNCDialog: {
                show: false,
                item: undefined,
                price: 0,
                dnc: 0
            },
            filter: {
                element: {
                    ai: false,
                    alien: false,
                    earth: false
                },
                rarity: {
                    common: false,
                    rare: false,
                    epic: false,
                    legend: false
                },
                price: {
                    from: 0,
                    to: 0
                }
            },
            value: 1,
            items: [],
            total: '...',
            pages: [],
            page: 1,
            sampleUpgrade: null
        };
    },

    methods: {
        viewTransaction(item) {
            return process.env.VUE_APP_BLCEXP + '/tx/' + item.nft.txh
        },
        viewPage(p) {
            this.page = p;
            this.fetchItems();
            document.getElementById("rowresult").scrollIntoView();
        },
        priceRound(p) {
            return '$' + p.toFixed(3)
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },

        fetchDNC: async function () {
            try {
                let { data } = await axios.get('/api/credit/total');
                this.totalDNC = data.amount;

                let { data: data2 } = await axios.get('/api/marketplace/dnc/listing');
                this.listingDNC = data2.amount;
            } catch (error) {
                console.log(error);
            }
        },

        fetchItems: async function () {
            let limit = 20;
            try {
                let filterQuery = {
                    element: objectToConcat(this.filter.element),
                    rarity: objectToConcat(this.filter.rarity),
                    price_from: this.filter.price.from,
                    price_to: this.filter.price.to,
                    page: this.page,
                    limit: limit
                }
                let { data } = await axios.get('/api/my/assets', { params: filterQuery });
                console.log(data);
                this.items = data.data.map(e => {
                    e.state = 0;
                    return e;
                });
                this.sampleUpgrade = this.items[0];
                this.total = data.total;


                let numberPage = Math.ceil(data.total / limit);
                let ps = [];
                for (var i = (this.page - 8); i <= this.page + 8; i++) {
                    if (i > 0 && i <= numberPage) {
                        ps.push(i);
                    }
                }
                this.pages = ps;

            } catch (error) {
                console.log(error);
            }
        },
        filterBulder() {
            let filterQuery = {
                element: objectToConcat(this.filter.element),
                rarity: objectToConcat(this.filter.rarity),
                price_from: this.filter.price.from,
                price_to: this.filter.price.to,
            }
            this.$router.push({ query: filterQuery });
            this.fetchItems();
        },

        doShowSellingDNC() {
            this.listingDNCDialog.show = true;
        },

        doListing(item) {
            let self = this;
            console.log(item);
            this.$swal
                .fire({
                    title: "By proceeding, you will listing spaceship to marketplace. When listing to marketplace, you can not use spaceship on your game.",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Proceed",
                    denyButtonText: `Cancel`,
                })
                .then((result) => {
                    console.log(result);


                    if (result.isConfirmed) {
                        self.listingDialog = {
                            show: true,
                            item: item,
                            price: 0
                        }




                        /*
                        this.$swal.fire({
                            title: "Please wait ...",
                            text: " ",
                            icon: "warning",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        });
                        item.state = 3;
                        this.$store.dispatch("minting/request", {
                            collectionID,
                            onReject: () => {
                                item.state = 0;
                            },
                            callback: function (txid) {
                                item.state = 4;
                                console.log("Minting completed", txid);
                                setTimeout(() => {
                                    self.fetchItems();
                                    //self.silentFetchMinting(item);
                                }, 1000);
                            },
                        });*/
                    }
                });
        },

        doMintNFT(item) {
            let self = this;
            let collectionID = item._id;
            this.$swal
                .fire({
                    title: "By proceeding, you will convert this Spaceship into an NFT on the ETH Network. Please note that this action cannot be undone.",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Proceed",
                    denyButtonText: `Cancel`,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$swal.fire({
                            title: "Please wait ...",
                            text: " ",
                            icon: "warning",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        });
                        item.nft = {
                            mint_status: "lock"
                        };
                        this.$store.dispatch("minting/request", {
                            collectionID,
                            onReject: () => {
                                item.nft = null;
                            },
                            callback: function (txid) {
                                item.nft = {
                                    mint_status: "verify_payment"
                                };
                                console.log("Minting payment request completed", txid);
                                setTimeout(() => {
                                    self.fetchItems();
                                }, 10000);
                            },
                        });
                    }
                });


        },

        async doListingSpaceship() {
            this.$swal.fire({
                title: "Please wait ...",
                text: " ",
                icon: "warning",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            let { data } = await axios.post('/api/marketplace/listing', {
                item: this.listingDialog.item._id,
                price: this.listingDialog.price
            });
            this.listingDialog.show = false;
            this.$swal.fire({
                title: data.msg,
                text: " ",
                icon: data.error ? "error" : "information",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
            });
        },

        async doListingDNC() {
            let totalDNC = parseInt(this.totalDNC);
            let toSell = parseInt(this.listingDNCDialog.dnc);
            let usdtToSell = parseFloat(this.listingDNCDialog.price);

            if (!toSell || !usdtToSell) {
                return;
            }

            if (totalDNC <= toSell) {
                this.$swal.fire({
                    title: "Please enter total DNC to sell less than " + totalDNC,
                    text: " ",
                    icon: "error",
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                });
                return;
            }

            this.$swal.fire({
                title: "Please wait ...",
                text: " ",
                icon: "warning",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            let { data } = await axios.post('/api/marketplace/listing_dnc', {
                dnc: toSell,
                price: usdtToSell
            });
            this.listingDNCDialog.show = false;
            this.$swal.fire({
                title: data.msg,
                text: " ",
                icon: data.error ? "error" : "information",
                showConfirmButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
            });
            // Refresh DNC
            this.fetchDNC();
        }
    },

    computed: {
        ...mapState("account", ["status", "creditAmount", "user"]),
        ...mapState("cart", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "landType",
            "landCollection",
            "landPlotBuyResult",
            "addCartException",
            "showDialogPaymentStripe",
        ]),
        ...mapState("map", ["styleMap"]),
    },
    watch: {
        landPlotBuyResult: function ({ isError }) {
            this.clearSelectionLand();
            if (!isError) {
                this.fetchLands();
            }
        },

        addCartException: function ({ error, title, msg }) {
            this.$swal(title, msg, error ? "error" : false);
        },

        filter: {
            handler(newValue) {
                console.log(newValue);
                this.filterBulder();
            },
            deep: true
        }
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        this.fetchItems();
        // GET DNC
        // totalDNC
        this.fetchDNC();
    },
};
</script>
