const state = {
    styleMap: "mapbox://styles/mapbox/streets-v11",
    openLoading: false
}


const actions = {

    satelliteView({commit}) {
        commit("onSatelliteView");
    },

    openLoading({commit}, {status}) {
        commit("openLoading", {status});
    },
};
const mutations = {

    onSatelliteView(state) {
        state.styleMap = "mapbox://styles/mapbox/satellite-v9";
    },

    openLoading(state, {status}) {
        state.openLoading = status;
    },

};

export const map = {
    namespaced: true,
    state,
    actions,
    mutations
};