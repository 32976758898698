import Vuex from 'vuex';

import { account } from './modules/account';
import { cart } from './modules/cart';
import { shop } from './modules/shop';
import { map } from './modules/map';
import { website } from "./modules/website";
import { collections } from './modules/collections';
import { landCreditTopup } from './modules/landCreditTopup';
import { minting } from "./modules/minting";
import { web3_login } from "./modules/web3_login";
import { swal } from "./modules/swal";
import { marketplace } from "./modules/marketplace";
export const store = new Vuex.Store({
    modules: {
        account,
        cart,
        map,
        collections,
        landCreditTopup,
        minting,
        web3_login,
        swal,
        shop,
        website,
        marketplace
    }
});