<style scoped>
.page {
  display: block;
}
.container-tight {
  max-width: 40rem;
}
</style>
<template>
  <div class="page page-center">
    <div class="container container-tight py-4">
      <!--      // close init connect-->
      <vue-metamask ref="metamask" :initConnect="false"></vue-metamask>

      <div class="card card-md">
        <div class="card-body">
          <h2 class="h2 text-center mb-4">Link Blockchain Wallet Account</h2>
          <div class="row">
            <div class="col-6 text-center">
              <a @click="metamaskLogin" class="btn btn-primary my-4">Connect with Metamask</a>
            </div>
            <div class="col-6 text-center">
              <a href="#" class="btn btn-primary my-4"> Connect with Trustwallet </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMetamask from "vue-metamask";
export default {
  components: {VueMetamask},
  name: "link-wallet",
  data() {
    return {
      data: {},
    };
  },
  methods: {
    metamaskLogin() {
      this.$refs.metamask.init();
    },
  },
};
</script>
