<template>
    <div>
        <div style="padding-top: 8.5rem" class="page-header d-print-none">
            <div class="container-xl">
                <div style="
            border-bottom: 1px solid #404046;
            margin: 0px;
            margin-top: 20px;
            padding-bottom: 15px;
          " class="row">
                    <div class="col-xl-4">
                        <div class="col">
                            <!-- Page pre-title -->
                            <!-- <div class="page-pretitle">Home > Ships</div> -->
                            <div class="page-pretitle sort-by-div">
                                <h4>Frontier Favorites</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 mb-30 mb-xl-0">
                        <div style="padding-top: 15px" class="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-between justify-content-xl-end">
                            <div id="filters" class="d-flex flex-wrap justify-content-center justify-content-xl-end">
                                <button class="button is-checked" data-filter="*">All</button>
                                <button class="button" data-filter=":not(.artwork)">
                                    RARE
                                </button>
                                <button class="button" data-filter=":not(.photography)">
                                    EPIC
                                </button>
                                <button class="button" data-filter=":not(.domain)">
                                    COMMON
                                </button>
                                <button class="button" data-filter=":not(.memes)">
                                    LEGENDARY
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div v-for="(item, index) in topSale" v-bind:key="item.id" class="col-xl-3 col-lg-4 col-sm-6">
                        <RouterLink :to="'detail/' + item._id" class="single-collection" :data-count="'0' + (index + 1)">
                            <div class="collection-img">
                                <img width="40" :src="getImage(item.cache.image)" alt="" />
                            </div>
                            <div class="content">
                                <h5>{{ item.name }}</h5>
                                <span>{{ item.total }}</span>
                            </div>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding-top: 0.5rem" class="page-header d-print-none">
            <div class="container-xl">
                <div style="
            border-bottom: 1px solid #404046;
            margin: 0px;
            margin-top: 20px;
            padding-bottom: 15px;
          " class="row">
                    <div class="col-xl-4">
                        <div class="col">
                            <!-- Page pre-title -->
                            <!-- <div class="page-pretitle">Home > Ships</div> -->
                            <div class="page-pretitle sort-by-div">
                                <h4>Starship Arrivals</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 mb-30 mb-xl-0">
                        <div style="padding-top: 15px" class="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-between justify-content-xl-end">
                            <div id="filters" class="d-flex flex-wrap justify-content-center justify-content-xl-end">
                                <button class="button is-checked" data-filter="*">All</button>
                                <button class="button" data-filter=":not(.artwork)">
                                    RARE
                                </button>
                                <button class="button" data-filter=":not(.photography)">
                                    EPIC
                                </button>
                                <button class="button" data-filter=":not(.domain)">
                                    COMMON
                                </button>
                                <button class="button" data-filter=":not(.memes)">
                                    LEGENDARY
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page body -->
        <div style="padding-bottom: 0.5rem" class="page-body mb-0">
            <div class="container-xl">
                <div class="row row-cards list-ships">
                    <carousel :items-to-show="1" :breakpoints="breakpoints">
                        <slide v-for=" item  in  recentlyListing " v-bind:key="item.id" class="ship-item ">
                            <spaceshipModule :item="item"></spaceshipModule>
                        </slide>

                        <template #addons>
                            <navigation />
                            <pagination />
                        </template>
                    </carousel>
                    <!--<div class="col-lg-2">                                                                                                                                                                                   </div>-->
                </div>
            </div>
        </div>
        <div style="padding-top: 0.5rem" class="page-header d-print-none">
            <div class="container-xl">
                <div style="
            border-bottom: 1px solid #404046;
            margin: 0px;
            margin-top: 20px;
            padding-bottom: 15px;
          " class="row">
                    <div class="col-xl-4">
                        <div class="col">
                            <!-- Page pre-title -->
                            <!-- <div class="page-pretitle">Home > Ships</div> -->
                            <div class="page-pretitle sort-by-div">
                                <h4>Nova Sales</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 mb-30 mb-xl-0">
                        <div style="padding-top: 15px" class="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-between justify-content-xl-end">
                            <div id="filters" class="d-flex flex-wrap justify-content-center justify-content-xl-end">
                                <button class="button is-checked" data-filter="*">All</button>
                                <button class="button" data-filter=":not(.artwork)">
                                    RARE
                                </button>
                                <button class="button" data-filter=":not(.photography)">
                                    EPIC
                                </button>
                                <button class="button" data-filter=":not(.domain)">
                                    COMMON
                                </button>
                                <button class="button" data-filter=":not(.memes)">
                                    LEGENDARY
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page body -->
        <div style="padding-bottom: 6.5rem" class="page-body mb-0">
            <div class="container-xl">
                <div class="row row-cards list-ships">
                    <carousel :items-to-show="1" :autoplay="1000" :breakpoints="breakpoints">
                        <slide v-for=" item  in  recentSales " v-bind:key="item.id" class="ship-item">
                            <spaceshipModule :item="item"></spaceshipModule>
                        </slide>
                        <template #addons>
                            <navigation />
                            <pagination />
                        </template>
                    </carousel>

                    <!--<div class="col-lg-2">                                                                                                                                                                                   </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import { mapState } from "vuex";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import axios from "axios";
import spaceshipModule from "../components/MarketSpaceshipModule.vue";
export default {
    name: "HomeMarketpalce",
    components: {
        // Select2
        Carousel,
        Slide,
        Pagination,
        Navigation,
        spaceshipModule
    },
    data() {
        return {
            recentlyListing: [],
            topSale: [],
            recentSales: [],
            breakpoints: {
                // 700px and up
                100: {
                    itemsToShow: 1.5,
                    snapAlign: 'center',
                },
                700: {
                    itemsToShow: 3.5,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4.5,
                    snapAlign: 'start',
                },
            },
        };
    },

    methods: {

        priceRound(p) {
            if (!p) return "";
            return '$' + p.toFixed(3)
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },
        fetchRecentlyListing() {
            axios.get(`/api/marketplace/recently_sold`, {
                params: {
                    limit: 12
                }
            })
                .then((r) => {
                    let data = r.data;
                    if (!data.error) {
                        this.recentlyListing = data.data;
                    }
                })
                .catch((e) => {
                    // Network error
                    console.log(e);
                });
        },
        fetchTopSale() {
            axios.get(`/api/marketplace/recently_sold`, {
                params: {
                    limit: 8
                }
            })
                .then((r) => {
                    let data = r.data;
                    if (!data.error) {
                        this.topSale = data.data;
                    }
                })
                .catch((e) => {
                    // Network error
                    console.log(e);
                });
        },
        fetchRecentSales() {
            axios.get(`/api/marketplace/last_7_days`)
                .then((r) => {
                    let data = r.data;
                    if (!data.error) {
                        this.recentSales = data.data;
                    }
                })
                .catch((e) => {
                    // Network error
                    console.log(e);
                });
        },
    },

    computed: {
        ...mapState("account", ["status", "creditAmount", "user"]),
        ...mapState("cart", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "landType",
            "landCollection",
            "landPlotBuyResult",
            "addCartException",
            "showDialogPaymentStripe",
        ]),
        ...mapState("map", ["styleMap"]),
    },
    watch: {
        landPlotBuyResult: function ({ isError }) {
            this.clearSelectionLand();
            if (!isError) {
                this.fetchLands();
            }
        },

        addCartException: function ({ error, title, msg }) {
            this.$swal(title, msg, error ? "error" : false);
        },
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        this.fetchRecentlyListing();
        this.fetchRecentSales();
        this.fetchTopSale();
    },
};
</script>
