<template>
  <div>
    <div style="padding-top: 8.5rem" class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center"></div>
        <div
          style="
            border-bottom: 1px solid #404046;
            margin: 0px;
            margin-top: 20px;
          "
          class="row"
        >
          <div class="col-xl-4">
            <div class="col">
              <!-- Page pre-title -->
              <div class="page-pretitle">Home > Ships</div>
              <div class="page-pretitle sort-by-div">
                <h4>Ships</h4>
              </div>
            </div>
          </div>
          <div class="col-xl-8 mb-30 mb-xl-0">
            <div
              style="padding-top: 15px"
              class="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-between justify-content-xl-end"
            >
              <div
                id="filters"
                class="d-flex flex-wrap justify-content-center justify-content-xl-end"
              >
                <button class="button is-checked" data-filter="*">All</button>
                <button class="button" data-filter=":not(.artwork)">
                  RARE
                </button>
                <button class="button" data-filter=":not(.photography)">
                  EPIC
                </button>
                <button class="button" data-filter=":not(.domain)">
                  COMMON
                </button>
                <button class="button" data-filter=":not(.memes)">
                  LEGENDARY
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page body -->
    <div style="padding-bottom: 6.5rem" class="page-body mb-0">
      <div class="container-xl">
        <div class="row row-cards list-ships">
          <div v-for="item in items" v-bind:key="item.id" class="">
            <div :class="'body-ship ' + item.type">
              <RouterLink :to="'/detail/' + item.id">
                <div class="header-ship">
                  <div class="header-title">{{ item.title }}</div>
                  <div :class="'header-type ' + item.type">{{ item.type }}</div>
                </div>
                <div class="image-ship">
                  <img :src="item.image" />
                  <span></span>
                </div>
                <div class="footer-ship">
                  <img src="../../static/images/logo-list-item.png" /> VIEW ITEM
                </div>
              </RouterLink>
            </div>
          </div>
          <!--<div class="col-lg-2">                                                                                                                                                                                   </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { mapState } from "vuex";

export default {
  components: {
    // Select2
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: "Polaris W-47",
          type: "RARE",
          image: process.env.VUE_APP_BASE_URL + "/static/ships/ship1.png",
          total: "$203,621",
        },
        {
          id: 2,
          title: "Polaris W-48",
          type: "EPIC",
          image: process.env.VUE_APP_BASE_URL + "/static/ships/ship2.png",
          total: "$118,321",
        },
        {
          id: 3,
          title: "Polaris W-49",
          type: "COMMON",
          image: process.env.VUE_APP_BASE_URL + "/static/ships/ship3.png",
          total: "$10,429",
        },
        {
          id: 4,
          title: "Polaris W-50",
          type: "LEGENDARY",
          image: process.env.VUE_APP_BASE_URL + "/static/ships/ship4.png",
          total: "$10,229",
        },
        {
          id: 5,
          title: "Polaris W-51",
          type: "RARE",
          image: process.env.VUE_APP_BASE_URL + "/static/ships/ship5.png",
          total: "$9,429",
        },
        {
          id: 6,
          title: "Polaris W-52",
          type: "EPIC",
          image: process.env.VUE_APP_BASE_URL + "/static/ships/ship6.png",
          total: "$8,429",
        },
        {
          id: 7,
          title: "Polaris W-53",
          type: "COMMON",
          image: process.env.VUE_APP_BASE_URL + "/static/ships/ship7.png",
          total: "$7,429",
        },
        {
          id: 8,
          title: "Polaris W-54",
          type: "LEGENDARY",
          image: process.env.VUE_APP_BASE_URL + "/static/ships/ship8.png",
          total: "$6,429",
        },
      ],
    };
  },

  methods: {},

  computed: {
    ...mapState("account", ["status", "creditAmount", "user"]),
    ...mapState("cart", [
      "totalItems",
      "itemsOnCart",
      "totalPrice",
      "itemsPrice",
      "checkoutStatus",
      "landType",
      "landCollection",
      "landPlotBuyResult",
      "addCartException",
      "showDialogPaymentStripe",
    ]),
    ...mapState("map", ["styleMap"]),
  },
  watch: {
    landPlotBuyResult: function ({ isError }) {
      this.clearSelectionLand();
      if (!isError) {
        this.fetchLands();
      }
    },

    addCartException: function ({ error, title, msg }) {
      this.$swal(title, msg, error ? "error" : false);
    },
  },

  // `mounted` is a lifecycle hook which we will explain later
  mounted() {},
};
</script>
