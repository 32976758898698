<style>
#widget_landplot iframe {
    height: 600px !important;
}

.list-configuration b {
    font-size: 13px;
}

.page-pretitle a {
    color: white;
}

.price-text {
    font-size: 23px;
    text-align: center !important;
}

.price-usdt {
    font-size: 15px;
    color: #b0b0b0;
}

.price-history {
    opacity: 0.3;
    text-align: center;
}

.throw-line {
    text-decoration: line-through;
}

.discount-text {
    font-style: italic;
    color: red !important;
    text-align: center !important;
    font-size: 15px;
}

.nft-name {
    font-size: 23px;
}

.disable-btn {
    opacity: 0.3;
}

.viewholder {
    border-radius: 20px;
}

.legend-content {
    width: 100%;
}
</style>
<template>
    <div>
        <div style="padding-top: 8.5rem" class="page-header d-print-none">
            <div class="container-xl">
                <div class="row g-2 align-items-center">
                    <div class="col">
                        <div class="page-pretitle">
                            Marketplace
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="page-body mb-3 mt-3" v-if="item && item.type == 'spaceship'">
            <div class="container-xl">
                <div class="row row-cards">
                    <div class="col-lg-7 col-md-12 detail-image">
                        <div class="list-image-info">

                            <h4>{{ item.name }}</h4>
                            <img :src="getImage(item.class.image)" />
                            <span></span>

                        </div>


                        <br />
                        <div class="image-info">
                            <h4>{{ item.name }}</h4>
                            <div>
                                <SpaceshipVier :code="item.class.code"></SpaceshipVier>
                            </div>
                            <div class="bottom-ship-detail">
                                <img src="../../static/images/alien.png" />
                                <div class="bottom-ship-detail-info">
                                    <span>{{ item.class.name }}</span>
                                    <br />
                                    <i v-if="item.class.element == 0"> Human</i>
                                    <i v-if="item.class.element == 1"> AI</i>
                                    <i v-if="item.class.element == 2"> Alien</i>
                                </div>
                            </div>
                            <div class="numerology-ship">
                            </div>
                        </div>
                        <br />

                        <p></p>
                        <div class="mt-1">
                            <div class="info-detail-ship">
                                <h4 class="mb-4">Information</h4>
                                <p>

                                    <span class="nft-name">
                                        <span v-if="item.class.rarity == 0"> Common</span>
                                        <span v-if="item.class.rarity == 1"> Rare</span>
                                        <span v-if="item.class.rarity == 2"> Epic</span>
                                        <span v-if="item.class.rarity == 3"> Legendary</span> / {{ item.name }}</span>

                                    {{ item.class.description }}
                                </p>
                                <div class="title-configuration">
                                    <img src="../../static/images/icon-title-configuration.png" />
                                    Race
                                    <span></span>
                                </div>
                                <div class="row mt-2" v-if="item.class.type == 0">
                                    <div class="col-5">
                                        <img src="@/assets/images/class0.png" />
                                    </div>
                                    <div class="col">
                                        <div class="text-truncate text-white">
                                            HUMAN
                                        </div>
                                        <div class="text-secondary">Fragile yet fearless, humans use their wits and courage to outmaneuver their foes. Their unpredictable nature gives them an edge against Al.

                                        </div>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="badge bg-primary"></div>
                                    </div>
                                </div>
                                <div class="row mt-2" v-if="item.class.type == 1">
                                    <div class="col-5">
                                        <img src="@/assets/images/class2.png" />
                                    </div>
                                    <div class="col">
                                        <div class="text-truncate text-white">
                                            AI
                                        </div>
                                        <div class="text-secondary">Constructed, not born, Al entities thrive in the harshness of space. Their advanced and precise tactics dominate the Aliens.</div>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="badge bg-primary"></div>
                                    </div>
                                </div>
                                <div class="row mt-2" v-if="item.class.type == 2">
                                    <div class="col-5">
                                        <img src="@/assets/images/class1.png" />
                                    </div>
                                    <div class="col">
                                        <div class="text-truncate text-white">
                                            ALIEN
                                        </div>
                                        <div class="text-secondary">Mighty and enigmatic, aliens wield arcane technologies. Their raw power makes them formidable against Humans. </div>
                                    </div>
                                    <div class="col-auto align-self-center">
                                        <div class="badge bg-primary"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="col-lg-5 col-md-12 detail-ship-right">

                        <div class="info-detail-ship mb-4" v-if="item.class.rarity == 3">

                            <h4 class="mb-4">Legendary</h4>

                            <div class="legend-content">

                                <p>Behold the legendary spaceships, each engineered with the extraordinary capability to transform into formidable robots. In their transformed state, these marvels can hover stationary in the vast expanse of space, poised for strategic advantage. As highly coveted digital collectibles, only 150 units exist in the entire universe, making them a rare and prized possession among commanders.</p>
                                <img v-if="item.class.type == 0" src="@/assets/images/legend/human-legendary.jpg" style="width: 100%" />
                                <img v-if="item.class.type == 1" src="@/assets/images/legend/ai-legendary.jpg" style="width: 100%" />
                                <img v-if="item.class.type == 2" src="@/assets/images/legend/alien-legendary.jpg" style="width: 100%" />
                            </div>
                        </div>

                        <div class="info-detail-ship">

                            <h4 class="mb-4">Stats</h4>

                            <ul class="list-configuration">
                                <li class="engine">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h4>Health</h4>
                                        </div>
                                        <div class="col-sm-8">
                                            <p>
                                                <b>{{ item.item.health }} Health Points (HP)</b>
                                            </p>
                                            This is the spaceship's overall durability at the start of each battle. Health is fully restored when a battle begins. If the Health is fully depleted, the spaceship will be destroyed.
                                        </div>
                                    </div>
                                </li>
                                <li class="engine">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h4>Healing</h4>
                                        </div>
                                        <div class="col-sm-8">
                                            <p>
                                                <b>{{ item.item.healing }} HP/sec</b>
                                            </p>
                                            The spaceship's automatic healing factor to gradually recover health over time during battle.
                                        </div>
                                    </div>
                                </li>
                                <li class="engine">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h4>Gun </h4>
                                        </div>
                                        <div class="col-sm-8">
                                            <p>

                                                <b>{{ item.item.gun }} Gun Points (DP)</b>
                                            </p>
                                            This reflects the attack power of the spaceship's primary gun weaponry and determines the extent of damage inflicted on the enemy with each hit.
                                        </div>
                                    </div>
                                </li>
                                <li class="engine">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h4>Rocket</h4>
                                        </div>
                                        <div class="col-sm-8">
                                            <p>
                                                <b>{{ item.item.rocket }} Rocket Points (RP)</b>
                                            </p>
                                            Indicates the attack power of the spaceship's rockets, measuring the damage dealt to an enemy ship with each rocket strike.
                                        </div>
                                    </div>
                                </li>
                                <li class="engine">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h4>Defense</h4>
                                        </div>
                                        <div class="col-sm-8">
                                            <p>
                                                <b> {{ item.item.defense }} Defense Points (DP)</b>
                                            </p>
                                            This stat quantifies the spaceship's ability to reduce the damage taken from enemy assaults.
                                        </div>
                                    </div>
                                </li>
                                <li class="engine">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h4>Afterburner Recharge Time</h4>
                                        </div>
                                        <div class="col-sm-8">
                                            <p>
                                                <b>{{ item.item.afterburner }} Sec</b>
                                            </p>
                                            This is the recharge duration for the spaceship's afterburner system, which provides temporary speed boosts. Shorter recharge times enable more frequent bursts of speed.
                                        </div>
                                    </div>
                                </li>

                                <li class="engine">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h4>Rocket Recharge Time</h4>
                                        </div>
                                        <div class="col-sm-8">
                                            <p>
                                                <b>{{ item.item.rocket_reload }} Sec</b>
                                            </p>
                                            The cooldown period required before a new rocket can be launched. The shorter the cooldown period, the faster the spaceship can launch rocket attacks.
                                        </div>
                                    </div>
                                </li>

                                <li class="engine">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h4>Rocket Firing Rate</h4>
                                        </div>
                                        <div class="col-sm-8">
                                            <p>

                                                <b>{{ item.item.rocket_fire_rate }} RPM</b>
                                            </p>

                                            The rate at which rockets can be fired from the spaceship’s missile system when a rocket attack is launched.
                                        </div>
                                    </div>
                                </li>

                            </ul>

                            <div class="mb-5">
                                <div class="title-configuration">
                                    <img src="../../static/images/icon-title-configuration.png" />
                                    Owner information :
                                    <a>
                                        <b> {{ item.owner_info.name }}</b>
                                    </a>
                                    <span></span>
                                </div>
                            </div>
                            <button v-if="item.listing_status && paymentStatus.status == 'DEFAULT'" class="btn buynow mt-3 p-3" @click="doBuyMarketplaceItem">
                                BUY NOW {{ item.price }} USDC
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>







        <div class="page-body mb-3 mt-3" v-if="item && item.type == 'dnc'">
            <div class="container-xl">
                <div class="row row-cards">
                    <div class="col-lg-7 col-md-12 detail-image">
                        <div class="list-image-info">

                            <h4>{{ item.name }}</h4>
                            <img src="@/assets/images/dnc_bucket.webp" />
                            <span></span>

                        </div>


                    </div>
                    <div class="col-lg-5 col-md-12 detail-ship-right">



                        <div class="info-detail-ship" style="width:100%">

                            <h4 class="mb-4">Stats</h4>

                            <div class="mb-5">
                                <div class="title-configuration">
                                    <img src="../../static/images/icon-title-configuration.png" />
                                    Owner information :
                                    <a>
                                        <b> {{ item.owner_info.name }}</b>
                                    </a>
                                    <span></span>
                                </div>
                            </div>
                            <button v-if="item.listing_status && paymentStatus.status == 'DEFAULT'" class="btn buynow mt-3 p-3" @click="doBuyMarketplaceItem">
                                BUY NOW {{ item.price }} USDC
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import axios from "axios";
import axios from "axios";
import { mapState } from "vuex";
import SpaceshipVier from "./modules/SpaceshipViewer.vue";
export default {
    components: {
        SpaceshipVier,
    },
    data() {
        return {
            showBuyNowModal: false,
            showSuccess: false,
            nft: {
                name: "",
                item: {}
            },
            item: null
        };
    },

    methods: {
        async doBuyMarketplaceItem() {

            this.$store.dispatch("marketplace/paymentUSDC", {
                item_id: this.item.id
            });

        },
        priceRound(p) {
            return "$" + parseInt(p).toLocaleString();
        },
        getImage(e) {
            if (e) {
                return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-800,h-800';
            }
            return "";
        },

        showBuyNow: function () {

            let isLogined = this.status && this.status.loggedIn;
            if (!isLogined) {
                this.$store.dispatch("swal/show", {
                    title: "",
                    msg: "To initiate payment process, please log into your account.",
                    type: "info",
                    allowOutsideClick: true,
                    showConfirmButton: true
                }, { root: true });
                return;
            }


            //let self = this;
            //self.showBuyNowModal = true;
            //self.showSuccess = true;
            let itemToAdd = this.item;
            itemToAdd.object = "spaceship";

            this.$store.dispatch("shop/removeItemAll");
            this.$store.dispatch("shop/addItems", {
                item: itemToAdd
            });
            this.$store.dispatch("shop/showCheckoutDialog");

        },
        buyNow: function () {
            let self = this;
            self.showSuccess = true;
        },

        getItemInformation: async function ({ id }) {
            try {
                let { data } = await axios.get(`/api/marketplace/item/${id}`);
                this.item = data.item;
                console.log(data.item);
            } catch (error) {
                console.log();
            }
        },

        closeCheckoutDialog: function () {
            this.$store.dispatch("cart/closeCheckoutDialog", {});
        },

        onShowFiatPaymentClick() {
            this.$store.dispatch("cart/showWertNotice", {});
        },

        onShowCryptoPaymentClick() {
            this.$store.dispatch("cart/ethCryptoPayment");
        },

        async closeNoticeDialog() {
            this.$store.dispatch("cart/hideWertNotice");
            /*
            var result = await this.$swal.fire({
                title: "Do you buy item with a visa card with Stripe?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Confirm",
                denyButtonText: `Cancel`,
            });
            if (result.isConfirmed) {
                this.$store.dispatch("map/openLoading", { status: true });
                var responseData = await this.$store.dispatch(
                    "cart/creditPaymentStripe",
                    {
                        type: 2,
                        collection: [],
                        items: [],
                    }
                );
                this.$store.dispatch("map/openLoading", { status: false });
                console.log(responseData, "responseData Stripe");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }*/
        },


        gotoWertPayment() {
            this.$store.dispatch("cart/wertioPayment", {});
        },
        async closeWertDialog() {
            this.$store.dispatch("cart/terminateCartWertIOPayment");
            /*var result = await this.$swal.fire({
                title: "Do you buy a spaceship with a visa card with Stripe?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Confirm",
                denyButtonText: `Cancel`
            });
            if (result.isConfirmed) {
                this.$store.dispatch("map/openLoading", { status: true });
                var responseData = await this.$store.dispatch(
                    "cart/creditPaymentStripe",
                    {
                        type: 2,
                        collection: [],
                        items: []
                    }
                );
                this.$store.dispatch("map/openLoading", { status: false });
                console.log(responseData, "responseData Stript");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }*/
        },
    },

    computed: {
        ...mapState("marketplace", ["paymentStatus"]),
        ...mapState("account", ["status", "creditAmount", "user"]),
        ...mapState("website", ["buyAvaible"])
    },
    watch: {
        paymentStatus: function (status) {
            console.log(status);
        }
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        let id = this.$route.params.id;
        this.getItemInformation({ id });
    },
};
</script>
