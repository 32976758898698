<style scoped>
@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 100%;
  }
}
</style>
<template>
  <div>
    <!-- Page body -->
    <div class="page-body mb-0">
      <div class="container-xl">
        <div class="row row-cards list-ships">
          <div class="col-lg-3"></div>
          <div class="col-lg-5"></div>
          <div class="col-lg-4"></div>
          <!--<div class="col-lg-2">                                                                                                                                                                                   </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { mapState } from "vuex";

export default {
  components: {
    // Select2
  },
  data() {
    return {
      items: [],
    };
  },

  methods: {},

  computed: {
    ...mapState("account", ["status", "creditAmount", "user"]),
    ...mapState("cart", [
      "totalItems",
      "itemsOnCart",
      "totalPrice",
      "itemsPrice",
      "checkoutStatus",
      "landType",
      "landCollection",
      "landPlotBuyResult",
      "addCartException",
      "showDialogPaymentStripe",
    ]),
    ...mapState("map", ["styleMap"]),
  },
  watch: {
    landPlotBuyResult: function ({ isError }) {
      this.clearSelectionLand();
      if (!isError) {
        this.fetchLands();
      }
    },

    addCartException: function ({ error, title, msg }) {
      this.$swal(title, msg, error ? "error" : false);
    },
  },

  // `mounted` is a lifecycle hook which we will explain later
  mounted() {
    //let self = this;
  },
};
</script>
