import WertWidget from '@wert-io/widget-initializer';
// import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer/';
import axios from 'axios';
window.Buffer = Buffer; // needed to use `signSmartContractData` in browser
import Web3 from "web3";

const state = {

    checkoutItem: {},
    checkoutStatus: false,


    itemsOnCart: [],
    itemsPrice: [],
    totalItems: 0,
    totalPrice: 0,
    cartIsOpen: false,
    landType: "",
    landCollection: "",
    isWertDialogShow: false,
    isWertCartNoticeDialogShow: false,
    wertLoaded: false,
    showDialogPaymentStripe: false,
    addCartException: {
        title: "",
        msg: ""
    },
}


const actions = {


    // Action assign authenticate user
    hideDialogPaymentStripe(state) {
        state.showDialogPaymentStripe = false;
    },

    addItems({ commit }, { item }) {
        console.log('add to cart');
        /*
        const LIMIT_ADD_CART = 300;

        let prepair = [];

        items.forEach(e => {
            var duplicated = state.itemsOnCart.find(function (i) {
                if (_.isEqual(e.geometry.bbox, i.geometry.bbox)) {
                    return true;
                }
                return false;
            });

            if (!duplicated) {
                prepair.push(e);
            }
        })

        if (prepair.length + state.itemsOnCart.length > LIMIT_ADD_CART) {
            // Show error 
            commit("AddCartException", {
                error: true,
                title: "",
                msg: "You can only add a maximum of 300 land plots into your cart at once. Please checkout with your current cart and you can continue shopping after."
            });
            return
        }*/
        commit("addItemMutation", { item: item });
        commit("openCart", { status: true });
    },

    removeItem({ commit }, { index }) {

        commit("removeItemMutation", { index });
    },

    removeItemAll({ commit }) {
        commit("removeItemMutationAll");
    },



    showCheckoutDialog({ commit }, { item }) {
        commit("onCheckout", { item });
    },

    closeCheckoutDialog({ commit }) {
        commit("closeCheckoutDialog");
    },

    openCart({ commit }, { status }) {
        commit("openCart", { status });
    },

    updatePrice({ commit }, { price, price_items }) {
        commit("addTotalPrice", { price, price_items });
    },

    openPaymentMethod({ commit }) {
        commit("openPaymentMethod");
    },

    cleanItemsAll({ commit }) {

        commit("cleanItemsAllMutation");
    },







    /* Payment with wert ..etc */
    showWertNotice({ commit }) {
        commit("UICartWertNoticeStatus", true);
        commit("closeCheckoutDialog");
    },

    hideWertNotice({ commit }) {
        commit("UICartWertNoticeStatus", false);
    },



    /**
     * Payment with land credit
     */
    creditPayment({ commit, dispatch, state }) {
        commit("closeCheckoutDialog");
        axios.post(`/api/land/payment/credit`, {
            price: state.totalPrice,
            type: state.landType,
            collection: state.landCollection,
            items: state.itemsOnCart.map(e => {
                return {
                    location: e.geometry.coordinates,
                    bbox: e.geometry.bbox,
                    areas: e.areas
                };
            }),
        }).then(response => {
            if (response.data && !response.data.error) {
                // Transaction completed, refresh map event
                dispatch("account/getCredits", {}, { root: true });


                commit("cleanItemsAllMutation");
                commit("SetLandPlotBuyResult", { error: false, title: "Purchased successfully.", msg: "" })
            } else {
                commit("SetLandPlotBuyResult", { error: true, title: "Unable to complete transaction", msg: response.data.msg })
            }
        }).catch(() => {
            commit("SetLandPlotBuyResult", { error: false, title: "Unable to complete transaction", msg: "Please try again, network error" })
        });
    },

    async creditPaymentStripe({ state }) {
        try {
            const res = await axios
                .post(`/api/stripe/buy`, {
                    price: state.totalPrice,
                    type: state.landType,
                    collection: state.landCollection,
                    items: state.itemsOnCart.map(e => {
                        return {
                            location: e.geometry.coordinates,
                            bbox: e.geometry.bbox,
                            areas: e.areas
                        };
                    })
                })
            return res.data
        } catch (error) {
            // Handle errors
            return {
                error: true,
                msg: "Please try again, network error"
            }
        }
    },

    /**
     * Payment with wertIO gateway 
     */
    wertioPayment({ commit, state }) {
        console.log("Request to get item", state.checkoutItem);
        // Request to backend ready for payment data with wert dot io 
        commit("UICartWertNoticeStatus", false);


        axios
            .post('/api/payment/wertio', {
                id: state.checkoutItem.id
            }).then(response => {
                let config = response.data;
                if (config.error) {
                    commit("UIWertDialogShow", { display: false });
                    commit("SetLandPlotBuyResult", { error: true, msg: config.msg })
                    return;
                }
                let transactionID = config.tid;
                commit("UIWertDialogShow", { display: true });
                try {
                    let signedData = response.data.signedData;
                    const otherWidgetOptions = {
                        partner_id: config.partnerID, //'01FFHQR89W38Y98278392090',
                        commodity: config.commodity,// 'ETH:Ethereum-Goerli',
                        container_id: 'widget_landplot',
                        click_id: transactionID, // unique id of purhase in your system
                        origin: config.origin, // 'https://sandbox.wert.io', // this option needed only for this example to work
                        height: 600,
                        listeners: {
                            'loaded': () => {
                                console.log('loaded')
                                commit("UITopupWertLoadingStatus", true);
                            },
                            'error': (e) => {
                                console.log(e, "Errorrr")
                                axios.patch('/api/payment/status', {
                                    tid: transactionID,
                                    status: 4,
                                    payload: e
                                })
                                commit("showPaymentStripe");

                            },
                            'payment-status': (s) => {
                                console.log(s);
                                switch (s.status) {
                                    case 'progress':
                                        axios.patch('/api/payment/status', {
                                            tid: transactionID,
                                            status: 1,
                                            payload: s
                                        })
                                        break;
                                    case 'failed':
                                        commit("showPaymentStripe");
                                        break
                                    case 'success':
                                        axios.patch('/api/payment/status', {
                                            tid: transactionID,
                                            status: 2,
                                            payload: s
                                        })
                                        setTimeout(() => {
                                            commit("UIWertDialogShow", { display: false });
                                            commit("cleanItemsAllMutation");
                                            commit("SetLandPlotBuyResult", { error: false, title: "Item purchased successfully.", msg: "" })
                                        }, 3000);

                                        break;
                                    default:
                                        break;
                                }
                            },
                            'position': (p) => {
                                console.log(p);
                            }
                        },
                    };

                    const nftOptions = {
                        extra: {
                            item_info: {
                                author: "Nova Frontier X",
                                name: `Spaceship`,
                                seller: "Nova Frontier X",
                            }
                        },
                    };


                    const wertWidget = new WertWidget({
                        ...signedData,
                        ...otherWidgetOptions,
                        ...nftOptions
                    });

                    setTimeout(() => {
                        wertWidget.mount();
                    }, 1000);
                } catch (error) {
                    // Can not connect to wertio 
                    console.log('e', error);
                    commit("UIWertDialogShow", { display: false });
                }
            }).catch(e => {
                commit("UIWertDialogShow", { display: false });
                commit("SetLandPlotBuyResult", { error: true, msg: e.response.data.msg })
            })
    },


    terminateCartWertIOPayment({ commit }) {
        commit("UIWertDialogShow", { display: false });
    },





    /**
     * VueX action for crypto Payment
     */
    async ethCryptoPayment({ commit, dispatch }) {

        // Show popup
        dispatch("swal/show", {
            title: "Please Wait",
            msg: "Payment transaction started",
            type: "success",
            showConfirmButton: false,
            allowOutsideClick: false
        }, { root: true });

        // Get transaction config
        let paymentTransactionData = undefined;
        try {
            let response = await axios.post('/api/payment/eth_crypto', {
                id: state.checkoutItem.id
            });
            if (response.data.error) {
                return dispatch("swal/show", {
                    title: "Something went wrong!",
                    msg: response.data.msg,
                    type: "success",
                }, { root: true });

                //return commit("SetLandPlotBuyResult", { error: true, msg: response.data.msg })

            }
            paymentTransactionData = response.data;
        } catch (error) {
            return dispatch("swal/show", {
                title: "Network error !",
                msg: error.toString,
                type: "success",
            }, { root: true });


            //return commit("SetLandPlotBuyResult", { error: true, msg: error })

        }

        let targetNetworkId = paymentTransactionData.targetNetworkId;
        let targetWallet = paymentTransactionData.targetWallet;
        let amountCurrency = paymentTransactionData.amountCurrency;
        let transactionID = paymentTransactionData.tid;


        //this.$swal("Please login to access", "Require login", "error");
        if (typeof window.ethereum === "undefined") {

            dispatch("swal/show", {
                title: "Something went wrong!",
                msg: "Please install Metamask and connect your wallet",
                type: "success",
            }, { root: true });
            //commit("SetLandPlotBuyResult", { error: true, msg: "Please install Metamask and connect your wallet" });

            await axios.post('/api/payment/eth_crypto/status', {
                tid: transactionID,
                step: 11,
                payload: {},
                result: false,
                content: "Please install Metamask and connect your wallet"
            });
            return
        }

        // Request connect eth network
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: targetNetworkId }],
            });
            // Ask to connect
            await window.ethereum.send("eth_requestAccounts");
            const instance = new Web3(window.ethereum);
            // Get necessary info on your node
            var networkId = await instance.eth.net.getId();
            var coinbase = await instance.eth.getCoinbase();
            var balance = await instance.eth.getBalance(coinbase);
            this.networkId = networkId;
            this.address = coinbase;
            this.balance = balance;
            console.log("initWeb3 networkId: ", networkId);
            console.log("coinbase networkId: ", coinbase);
            console.log("balance networkId: ", balance);
        } catch (error) {
            // User denied account access
            console.error("User denied web3 access", error, targetNetworkId);
            await axios.post('/api/payment/eth_crypto/status', {
                tid: transactionID,
                step: 12,
                payload: {},
                result: false,
                content: "User denied web3 access. Please install Metamask and connect your wallet"
            });
            return dispatch("swal/show", {
                title: "Something went wrong!",
                msg: "User denied web3 access. Please install Metamask and connect your wallet",
                type: "error",
            }, { root: true });
            //return commit("SetLandPlotBuyResult", { error: true, msg: "User denied web3 access. Please install Metamask and connect your wallet" })
        }


        var isWorking = true;

        window.addEventListener("beforeunload", function (e) {

            if (transactionID) {
                axios.post('/api/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 12,
                    payload: {},
                    result: false,
                    content: "User close browser"
                }).then(() => { }).catch(() => { });
            }

            if (isWorking == false) return false;
            var confirmationMessage = "aaaaa";
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage;
        });

        let sendETH = async function sendETH(amount) {
            try {
                // Send request to web3
                let web3 = new Web3(window.ethereum);
                var accounts = await web3.eth.getAccounts();
                var account = accounts[0];

                dispatch("swal/show", {
                    title: "Transaction Processing ...",
                    msg: "IMPORTANT! Please do not close this and check metamask popup!",
                    type: "info",
                    allowOutsideClick: false,
                    showConfirmButton: false
                }, { root: true });

                /* commit("SetLandPlotBlcProcess", {
                    msg: "IMPORTANT! Please do not close this.",
                    title: 'Transaction Processing ...'
                }) */

                await axios.post('/api/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 20,
                    payload: {
                        account: account
                    },
                    result: true,
                    content: "Request open dialog Payment metamask!"
                });

                axios.patch('/api/payment/status', {
                    tid: transactionID,
                    status: 1,
                    payload: {}
                })





                let result = await web3.eth
                    .sendTransaction({
                        from: account,
                        to: targetWallet,
                        value: web3.utils.toWei(amount.toString(), "ether"),
                    });



                if (result) {
                    console.log('ETH Transfer result', result);
                    return result;
                } else {
                    await axios.post('/api/payment/eth_crypto/status', {
                        tid: transactionID,
                        step: 21,
                        payload: {},
                        result: false,
                        content: "Send transaction error."
                    });

                    //commit("SetLandPlotBuyResult", { error: true, msg: "Transaction error." })

                    dispatch("swal/show", {
                        title: "Transaction error!",
                        msg: "",
                        type: "error"
                    }, { root: true });
                }

            } catch (error) {

                await axios.post('/api/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 22,
                    payload: {
                        error: error.message
                    },
                    result: false,
                    content: "Transaction error." + error.message
                });
                dispatch("swal/show", {
                    title: "Transaction error!",
                    msg: error.message,
                    type: "error"
                }, { root: true });
                //commit("SetLandPlotBuyResult", { error: true, msg: "Transaction error." + error.message })
            }
            return
        }
        // After send eth
        let result = await sendETH(amountCurrency)
        isWorking = false;
        if (result) {

            await axios.post('/api/payment/eth_crypto/status', {
                tid: transactionID,
                step: 30,
                payload: result,
                result: false,
                content: "ETH Transfer success result, Validating your transaction ...."
            });

            /*
            commit("SetLandPlotBlcProcess", {
                msg: "Validating your transaction ....",
                title: 'Blockchain transfer success. Please wait.'
            })*/

            dispatch("swal/show", {
                title: "Blockchain transfer success. Please wait.",
                msg: "Validating your transaction ....",
                type: "success",
                allowOutsideClick: false,
                showConfirmButton: false
            }, { root: true });

            await axios.patch('/api/payment/status', {
                tid: transactionID,
                status: 2,
                payload: result
            })

            axios.post('/api/payment/eth_crypto/verify', {
                tid: transactionID,
                txh: result.transactionHash
            }).then(({ data }) => {
                // Exception error !!!
                if (data.error) {
                    // Can not validate transaction ??? 
                    /*commit("SetLandPlotBuyResult", {
                        error: true,
                        msg: "Can not validate transaction. Please contact us!",
                        title: 'Transaction address : ' + result.transactionHash
                    })*/

                    dispatch("swal/show", {
                        title: "Can not validate transaction. Please contact us!",
                        msg: 'Transaction address : ' + result.transactionHash,
                        type: "error",
                        allowOutsideClick: true,
                        showConfirmButton: true
                    }, { root: true });

                    axios.post('/api/land/payment/eth_crypto/status', {
                        tid: transactionID,
                        step: 41,
                        payload: result,
                        result: false,
                        content: "Can not validate transaction. Please contact us!"
                    }).then(() => { }).catch(() => { });
                    return;
                }
                // Verify transaction success !! 
                dispatch("swal/show", {
                    title: "Spaceship purchased successfully.",
                    msg: 'Congratulations! Your transaction has been completed and confirmed by the blockchain.',
                    type: "success"
                }, { root: true });
                /*
                commit("SetLandPlotBuyResult", {
                    error: false,
                    title: "Spaceship purchased successfully.",
                    msg: "Congratulations! Your transaction has been completed and confirmed by the blockchain."
                })*/
                commit("closeCheckoutDialog");

                axios.post('/api/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 40,
                    payload: result,
                    result: true,
                    content: "Congratulations! Your transaction has been completed and confirmed by the blockchain."
                }).then(() => { }).catch(() => { });

            }).catch(() => {
                // Can not validate transaction ??? 
                /*
                commit("SetLandPlotBuyResult", {
                    error: true,
                    msg: "Can not validate transaction. Please contact us!",
                    title: 'Transaction address : ' + result.transactionHash
                })*/

                dispatch("swal/show", {
                    title: "Can not validate transaction. Please contact us!",
                    msg: 'Transaction address : ' + result.transactionHash,
                    type: "error"
                }, { root: true });

                axios.post('/api/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 41,
                    payload: result,
                    result: false,
                    content: "Can not validate transaction. Please contact us!"
                }).then(() => { }).catch(() => { });
            })
        }
    },





};

const mutations = {

    addItemMutation(state, { item }) {

        state.itemsOnCart.push(item);

        state.itemsOnCart = state.itemsOnCart.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        /*
        items.forEach(e => {
            var duplicated = state.itemsOnCart.find(function (i) {
                if (_.isEqual(e.geometry.bbox, i.geometry.bbox)) {
                    return true;
                }
                return false;
            });

            if (!duplicated) {
                state.itemsOnCart.push(e);
            }
        })*/
        state.totalItems = state.itemsOnCart.length;
        // state.totalPrice = 25 * state.totalItems; 
        let totalPrice = 0;
        /* state.itemsOnCart.forEach(e => {
            totalPrice = totalPrice + e.sell_price;
        }) */

        //let totalPrice = state.itemsOnCart.reduce((accumulator, currentValue) => accumulator.sell_price + currentValue, 0);
        state.totalPrice = totalPrice;

        console.log(state.totalPrice, 'pp');
    },

    removeItemMutation(state, { index }) {
        state.itemsOnCart.splice(index, 1);
        state.totalItems = state.itemsOnCart.length;
        // state.totalPrice = 25 * state.totalItems;
    },

    removeItemMutationAll(state) {
        state.itemsOnCart = []
        state.totalItems = 0;
        // state.totalPrice = 25 * state.totalItems;
    },

    openPaymentMethod(state) {
        state.checkoutStatus = !state.checkoutStatus;
    },

    openCart(state, { status }) {
        state.cartIsOpen = status;
    },

    addTotalPrice(state, { price, price_items }) {
        state.totalPrice = price;
        state.itemsPrice = price_items;
    },

    onCheckout(state, { item }) {
        state.checkoutItem = item;
        state.checkoutStatus = true;
    },

    cleanItemsAllMutation(state) {
        state.itemsOnCart = [];
        state.totalItems = 0;
    },

    closeCheckoutDialog(state) {
        state.checkoutStatus = false
    },


    UICartWertNoticeStatus(state, status) {
        state.isWertCartNoticeDialogShow = status;
    },

    UIWertDialogShow(state, { display }) {
        state.isWertDialogShow = display
    },





    showPaymentStripe(state) {
        if (state.showDialogPaymentStripe) {
            state.showDialogPaymentStripe = false
        } else {
            state.showDialogPaymentStripe = true
        }
    },


    UITopupWertLoadingStatus(state, status) {
        state.wertLoaded = status;
    },

    AddCartException(state, { error, title, msg }) {
        state.addCartException = { error, title, msg }
    },

};

export const cart = {
    namespaced: true,
    state,
    actions,
    mutations
};