<style>
.viewholder {
    width: 100%;
    min-height: 500px;
}
</style>
<template>
    <div>
        <canvas class="viewholder" ref="renderCanvas"></canvas>
    </div>
</template>
<script>
import { createScene } from "./scenes/MyFirstScene";
export default {
    props: ['code'],
    components: {
    },
    data() {
        return {}
    },

    methods: {},
    mounted() {
        createScene(this.$refs.renderCanvas, this.code)
    }
}
</script>