<style scoped>
.blindbox {
    background-color: #26282D;
}

.blindbox h2 {
    text-transform: uppercase;
    color: #fff;
    font-size: 25px;
}

.blindbox .org_price {
    color: #7F8385;
    text-decoration: line-through;
}

.btn-cart a {
    padding: 12px 18px;
    background-color: black;
    cursor: pointer;
}

.btn-cart a:hover {
    color: white;
    background-color: #5b5f67;
}

.img-blind {
    width: 100%;
}
</style>
<template>
    <div class="container-xl">
        <div style="height: 10rem;"></div>
        <div class="row filter-col">
            <div class="col-12 col-md-3 mb-2">
                <div class="filter-box mb-4">
                    <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-vercel" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M3 19h18l-9 -15z"></path>
                        </svg> Element</h4>
                    <div id="filters" class="mt-3">
                        <label class="form-check">
                            <input v-model="filter.element.ai" class="form-check-input" type="checkbox">
                            <span class="form-check-label text-class-1">AI</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.element.alien" class="form-check-input" type="checkbox">
                            <span class="form-check-label text-class-2">Alien</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.element.human" class="form-check-input" type="checkbox">
                            <span class="form-check-label text-class-0">Human</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-3 mb-2">
                <div class="filter-box mb-4">
                    <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-loader-quarter" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M12 6l0 -3"></path>
                            <path d="M6 12l-3 0"></path>
                            <path d="M7.75 7.75l-2.15 -2.15"></path>
                        </svg> Rarity</h4>
                    <div id="filters" class="mt-3">
                        <label class="form-check">
                            <input v-model="filter.rarity.common" class="form-check-input" type="checkbox">
                            <span class="form-check-label">Common</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.rarity.rare" class="form-check-input" type="checkbox">
                            <span class="form-check-label">Rare</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.rarity.epic" class="form-check-input" type="checkbox">
                            <span class="form-check-label">Epic</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.rarity.legend" class="form-check-input" type="checkbox">
                            <span class="form-check-label">Legendary</span>
                        </label>
                    </div>
                </div>
            </div>



            <div class="col-12 col-md-3 mb-2">
                <div class="filter-box mb-4">
                    <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-coins" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z"></path>
                            <path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4"></path>
                            <path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z"></path>
                            <path d="M3 6v10c0 .888 .772 1.45 2 2"></path>
                            <path d="M3 11c0 .888 .772 1.45 2 2"></path>
                        </svg> Price Range</h4>
                    <div class="mt-3 row">
                        <div class="col-6">
                            <label class="form-label">From (USD)</label>
                            <div class="input-icon mb-3">
                                <input v-model="filter.price.from" type="text" class="form-control" placeholder="Min">
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="form-label">To (USD)</label>
                            <div class="input-icon mb-3">
                                <input v-model="filter.price.to" type="text" class="form-control" placeholder="Max">
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-12 col-md-3 mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-vercel" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 19h18l-9 -15z"></path>
                </svg> Cart

                <div class="  mb-4">
                    <h4>

                    </h4>
                    <div class="mt-3">
                        <UserCart></UserCart>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5 filter-col">
            <div class="col-md-12 col-lg-12">
                <div id="rowresult">
                    <div class="page-header mx-2">
                        <div class="page-pretitle">Home > Shop</div>
                        <div class="page-pretitle sort-by-div">
                            <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                    <path d="M21 21l-6 -6"></path>
                                </svg> {{ total }} items</h4>
                        </div>
                    </div>
                </div>
                <p></p>
                <div class=" my-3" style="display: inline-block;
    margin: 15px;">
                    <div class="row row-cards blindbox">
                        <div class="col-12 col-md-4">
                            <img class="img-blind my-3" src="@/assets/images/blindbox.jpg" />
                        </div>
                        <div class="col-12  col-md-8 my-5">
                            <h2>Frontier Blind Boxes</h2>
                            <p class="mt-4">
                                Consider getting a Frontier Blind Box, which offers the chance to acquire a spaceship of varying rarity. This option introduces an element of surprise and excitement, as you could unveil a vessel ranging from common to legendary. Join us on this interstellar adventure and discover what the cosmos has in store for you.
                            </p>
                            <p class="price-mod">
                                <span class=" mx-2">$250</span>
                            </p>
                            <div class="btn-cart">
                                <a @click="addBlindboxToCart"> <img src="../../static/images/logo-list-item.png" /> Add to cart</a>
                            </div>
                            <p class="mt-5">
                                <a style="cursor: pointer;" @click="getBlindBoxChance" class="text-white">View probability</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row row-cards list-ships">
                    <div class="ship-item col-6 col-md-4 col-lg-3" v-for="item in items" v-bind:key="item._id">

                        <div :class="'body-ship r-' + item.type">
                            <div v-if="item">
                                <RouterLink :to="`/shop/${item._id}`">
                                    <div class="header-ship">
                                        <div class="header-title" :class="`text-class-${item.type}`">{{ item.name }}</div>
                                        <div :class="'header-type r-' + item.rarity">
                                            <span class="ship-class mx-2" :class="`ship-class-${item.type}`">
                                            </span>
                                            <template v-if="item.rarity == 0">Common</template>
                                            <template v-if="item.rarity == 1">Rare</template>
                                            <template v-if="item.rarity == 2">Epic</template>
                                            <template v-if="item.rarity == 3">Legendary</template>
                                        </div>
                                    </div>
                                    <div class="image-ship">
                                        <img :src="getImage(item.image)" />
                                        <span></span>
                                    </div>
                                    <!--
                                    <div class="price-ship mb-1">
                                        <span class="price-item mx-3"><b>{{ priceRound(item.sell_price) }}</b></span>
                                    </div>
                                    -->
                                </RouterLink>
                                <!--
                                <div class="footer-ship" @click="addToCart(item)" v-if="!item.soldout">
                                    <img src="../../static/images/logo-list-item.png" />
                                    <template v-if="item.under20 < 20">Hurry! Only {{ item.under20 }} left.</template>
                                    <template v-else>Add to cart</template>
                                </div>
                                <div class="footer-ship" v-if="item.soldout">
                                    SOLD OUT
                                </div>
                                -->
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center mt-4 pt-2">
                        <a @click="viewPage(i)" v-for="i in pages" :key="i" class="btn btn-page" :class="{ 'btn-primary': (i == page) }">{{ i }}</a>
                    </div><!--end col-->
                </div>

                <!-- Page body -->
                <div class="page-body mb-5">
                    <div class="container-xl">
                    </div>
                </div>
            </div>
        </div>


        <div id="exampleModal" tabindex="-1" class="modal modal-blur fade show" role="dialog" aria-modal="true" active="true" v-show="chanceDialog.show">
            <div class="modal-dialog" id="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Probability
                        </h5>
                        <button @click="chanceDialog.show = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="overflow: hidden">
                        <p>Unlock your chance to win rare and powerful spaceships with every blind box you open. Are you ready to upgrade your fleet and take on the galaxy? Good luck!</p>

                        <table class="table-chance" v-if="chanceInfo">
                            <tr>
                                <td>Rarity</td>
                                <td>Probability</td>
                            </tr>
                            <tr>
                                <td><b>Common</b></td>
                                <td>{{ getRound(chanceInfo.common) }}</td>
                            </tr>
                            <tr>
                                <td><b>Rare</b></td>
                                <td>{{ getRound(chanceInfo.rare) }}</td>
                            </tr>
                            <tr>
                                <td><b>Epic</b></td>
                                <td>{{ getRound(chanceInfo.epic) }}</td>
                            </tr>
                            <tr>
                                <td><b>Legendary</b></td>
                                <td>{{ getRound(chanceInfo.legend) }}</td>
                            </tr>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";
import { mapState } from "vuex";
import UserCart from "./modules/UserCart";
function objectToConcat(object1) {
    let item = [];
    for (const [key, value] of Object.entries(object1)) {
        if (value) {
            item.push(key);
        }
    }
    return item.join(',');
}


export default {
    name: "HomeMarketpalce",
    components: {
        UserCart
    },
    data() {
        return {
            chanceInfo: {
                common: 0,
                epic: 0,
                rare: 0,
                legend: 0
            },
            chanceDialog: {
                show: false
            },
            filter: {
                element: {
                    ai: false,
                    alien: false,
                    earth: false
                },
                rarity: {
                    common: false,
                    rare: false,
                    epic: false,
                    legend: false
                },
                price: {
                    from: 0,
                    to: 0
                }
            },
            value: 1,
            items: [],
            total: '...',
            pages: [],
            page: 1
        };
    },

    methods: {
        getRound(e) {
            return (Math.round(e * 100) / 100) + "%";
        },
        viewPage(p) {
            this.page = p;
            this.fetchItems();
            document.getElementById("rowresult").scrollIntoView();
        },
        priceRound(p) {
            return "$" + parseInt(p).toLocaleString();
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },

        getBlindBoxChance: async function () {
            this.chanceDialog.show = true;
            try {
                let { data } = await axios.get('/api/my/blindboxs/chance');
                if (!data.error) {
                    this.chanceInfo = data.chance;
                }
            } catch (e) {
                console.log(e);
            }
        },

        fetchItems: async function () {
            let limit = 16;
            try {
                let filterQuery = {
                    element: objectToConcat(this.filter.element),
                    rarity: objectToConcat(this.filter.rarity),
                    price_from: this.filter.price.from,
                    price_to: this.filter.price.to,
                    page: this.page,
                    limit: limit
                }
                let { data } = await axios.get('/api/shop/find', { params: filterQuery });
                console.log(data);
                this.items = data.data;
                this.total = data.total;


                let numberPage = Math.round(data.total / limit);
                let ps = [];
                for (var i = (this.page - 8); i <= this.page + 8; i++) {
                    if (i > 0 && i <= numberPage) {
                        ps.push(i);
                    }
                }
                this.pages = ps;

            } catch (error) {
                console.log(error);
            }
        },
        filterBulder() {
            let filterQuery = {
                element: objectToConcat(this.filter.element),
                rarity: objectToConcat(this.filter.rarity),
                price_from: this.filter.price.from,
                price_to: this.filter.price.to,
            }
            this.$router.push({ query: filterQuery });
            this.fetchItems();
        },


        addBlindboxToCart() {

            let item = {
                "name": "Blindbox",
                "image": "",
                "rarity": 0,
                "type": 2,
                "code": "blindbox",
                "_id": "blindbox_prefix",
                "sell_price": 250,
                "price": 1000,
                "soldout": false,
                "object": "blindbox"
            };

            this.$store.dispatch("shop/addItems", {
                item,
            });

            this.$store.dispatch("swal/show", {
                title: "",
                msg: item.name + " has been added to cart",
                type: "info",
                allowOutsideClick: true,
                showConfirmButton: true
            }, { root: true });
        },

        addToCart(item) {

            let itemToAdd = item;
            itemToAdd.object = "spaceship";

            this.$store.dispatch("shop/addItems", {
                item: itemToAdd,
            });
            this.$store.dispatch("swal/show", {
                title: "",
                msg: item.name + " has been added to cart",
                type: "info",
                allowOutsideClick: true,
                showConfirmButton: true
            }, { root: true });
        },


        closeCheckoutDialog: function () {
            this.$store.dispatch("shop/closeCheckoutDialog", {});
        },
        onShowFiatPaymentClick() {
            this.$store.dispatch("shop/showWertNotice", {});
        },

        onShowCryptoPaymentClick() {
            this.$store.dispatch("shop/doStartETHPayment");
        },

        gotoWertPayment() {
            this.$store.dispatch("shop/wertioPayment", {});
        },

        async closeWertDialog() {
            this.$store.dispatch("shop/terminateCartWertIOPayment");
            /*var result = await this.$swal.fire({
                title: "Do you buy a spaceship with a visa card with Stripe?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Confirm",
                denyButtonText: `Cancel`
            });
            if (result.isConfirmed) {
                var responseData = await this.$store.dispatch(
                    "cart/creditPaymentStripe",
                    {
                        type: 2,
                        collection: [],
                        items: []
                    }
                );
                console.log(responseData, "responseData Stript");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }*/
        },

    },

    computed: {
        ...mapState("account", ["status", "creditAmount", "user"]),

        ...mapState("shop", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "landType",
            "landCollection",
            "landPlotBuyResult",
            "addCartException",
            "showDialogPaymentStripe",
            "isWertCartNoticeDialogShow",
            "isWertDialogShow"
        ])
    },
    watch: {

        addCartException: function ({ error, title, msg }) {
            this.$swal(title, msg, error ? "error" : false);
        },

        filter: {
            handler(newValue) {
                console.log(newValue);
                this.page = 1;
                this.filterBulder();
            },
            deep: true
        }
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        this.fetchItems();
    },
};
</script>
