const state = {
    popup: null,
    hidePopup:null
}

const actions = {

    show({ commit }, payload) {
        commit("show", payload);
    },

    hide({ commit }, payload) {
        commit("hide", payload);
    },

};
const mutations = {

    show(state, payload) {
        state.popup = payload
    },

    hide(state, payload) {
        state.hidePopup = payload
    }, 
};

export const swal = {
    namespaced: true,
    state,
    actions,
    mutations
};