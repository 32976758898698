import WertWidget from '@wert-io/widget-initializer';
// import { v4 as uuidv4 } from 'uuid';
import {
    Buffer
} from 'buffer/';
import axios from 'axios';
window.Buffer = Buffer; // needed to use `signSmartContractData` in browser
import Web3 from "web3";

const state = {
    isShowModal: false,
    isWertDialogShow: false,
    quantity: 0,
    error: null,
    wertLoaded: false,
    isNoticeDialogShow: false,
    showLandSaleDialog: false,
    showPaymentStripeTopup: false
}

const actions = {

    showModal({
        commit
    }) {
        commit("UILandTopupDialogShow", {
            display: true
        });
    },

    showWertNotice({
        commit
    }) {
        commit("UITopupWertNoticeStatus", true);
        commit("UILandTopupDialogShow", {
            display: false
        });
    },
    /* eslint-disable */
    async creditPaymentStripe({
    }, {
        amount
    }) {
        try {
            const res = await axios
                .post(`/api/stripe/buyTopup`, {
                    quantity: amount
                })
            return res.data
        } catch (error) {
            // Handle errors
            return {
                error: true,
                msg: "Please try again, network error"
            }
        }
    },

    /**
     * VueX action for wertio Payment 
     */
    wertioPayment({
        commit,
        dispatch
    }, {
        amount
    }) {
        // Request to backend ready for payment data with wert dot io

        commit("UITopupWertNoticeStatus", false);
        axios
            .post('/api/credit/payment/wertio', {
                quantity: amount
            }).then(response => {
                let config = response.data;
                let transactionID = config.tid;
                commit("UIWertDialogShow", {
                    display: true
                });
                try {
                    let signedData = response.data.signedData;
                    const otherWidgetOptions = {
                        partner_id: config.partnerID, //'01FFHQR89W38Y98278392090',
                        commodity: config.commodity, // 'ETH:Ethereum-Goerli',
                        container_id: 'widget',
                        click_id: transactionID, // unique id of purhase in your system
                        origin: config.origin, // 'https://sandbox.wert.io', // this option needed only for this example to work
                        height: 500,
                        listeners: {
                            'loaded': () => {
                                console.log('loaded')
                                commit("UITopupWertLoadingStatus", true);
                            },
                            'error': (e) => {
                                axios.patch('/api/credit/payment_status', {
                                    tid: transactionID,
                                    status: 4,
                                    payload: e
                                })

                                commit("showPaymentStripeTopup")
                            },
                            'payment-status': (s) => {
                                console.log(s);
                                switch (s.status) {
                                    case 'progress':
                                        axios.patch('/api/credit/payment_status', {
                                            tid: transactionID,
                                            status: 1,
                                            payload: s
                                        })
                                        break;
                                    case 'failed':
                                        commit("showPaymentStripeTopup");
                                        break
                                    case 'success':
                                        axios.patch('/api/credit/payment_status', {
                                            tid: transactionID,
                                            status: 2,
                                            payload: s
                                        })

                                        /*eslint-disable */
                                        try {
                                            fbq('track', 'Purchase', {
                                                value: config.totalUSD,
                                                currency: 'USD'
                                            });
                                        } catch (error) {

                                        }
                                        /*eslint-enable */

                                        setTimeout(() => {
                                            // Reload wallet after 2 seconds
                                            commit("UIWertDialogShow", {
                                                display: false
                                            });
                                            dispatch('account/getCredits', {}, {
                                                root: true
                                            });
                                        }, 3000);

                                        break;
                                    default:
                                        break;
                                }
                            },
                            'position': (p) => {
                                console.log(p);
                            }
                        },
                    };

                    const nftOptions = {
                        extra: {
                            item_info: {
                                author: "Gate of Abyss",
                                name: `Buy ${amount} Land Credit`,
                                seller: "Gate of Abyss",
                            }
                        },
                    };


                    const wertWidget = new WertWidget({
                        ...signedData,
                        ...otherWidgetOptions,
                        ...nftOptions
                    });

                    setTimeout(() => {
                        wertWidget.mount();
                    }, 1000);
                } catch (error) {
                    // Can not connect to wertio 
                    console.log('e', error);
                    commit("UIWertDialogShow", {
                        display: false
                    });
                }
            }).catch(e => {
                commit("UITopupPaymentError", {
                    msg: e.response.data
                });
            })
    },



    /**
     * VueX action for crypto Payment
     */
    async ethCryptoPayment({
        commit,
        dispatch
    }, {
        amount
    }) {

        // Get transaction config
        let paymentTransactionData = undefined;
        try {
            let response = await axios.post('/api/credit/payment/eth_crypto', {
                quantity: amount
            });
            paymentTransactionData = response.data;
        } catch (error) {
            commit("UITopupPaymentError", {
                msg: {
                    msg: "Your network unstable for trust payment transaction",
                    title: 'Payment request  error !!'
                }
            })
            return
        }

        let targetNetworkId = paymentTransactionData.targetNetworkId;
        let targetWallet = paymentTransactionData.targetWallet;
        let amountCurrency = paymentTransactionData.amountCurrency;
        let transactionID = paymentTransactionData.tid;


        //this.$swal("Please login to access", "Require login", "error");
        if (typeof window.ethereum === "undefined") {
            return commit("UITopupPaymentError", {
                msg: {
                    msg: "Please install Metamask and connect your wallet",
                    title: ' '
                }
            })
        }

        // Request connect eth network
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: targetNetworkId
                }],
            });
            // Ask to connect
            await window.ethereum.send("eth_requestAccounts");
            const instance = new Web3(window.ethereum);
            // Get necessary info on your node
            var networkId = await instance.eth.net.getId();
            var coinbase = await instance.eth.getCoinbase();
            var balance = await instance.eth.getBalance(coinbase);
            this.networkId = networkId;
            this.address = coinbase;
            this.balance = balance;
            console.log("initWeb3 networkId: ", networkId);
            console.log("coinbase networkId: ", coinbase);
            console.log("balance networkId: ", balance);
        } catch (error) {
            // User denied account access
            console.error("User denied web3 access", error, targetNetworkId);

            axios.patch('/api/credit/payment_status', {
                tid: transactionID,
                status: 4,
                payload: {
                    "msg": "User denied web3 access",
                    error: error.message
                }
            })

            return commit("UITopupPaymentError", {
                msg: {
                    msg: "User denied web3 access",
                    title: 'Please install Metamask and connect your wallet'
                }
            })
        }

        // amount


        let sendETH = async function sendETH(amount) {
            try {
                // Send request to web3
                let web3 = new Web3(window.ethereum);
                var accounts = await web3.eth.getAccounts();
                var account = accounts[0];

                commit("UITopupPaymentSuccess", {
                    msg: {
                        msg: "IMPORTANT! Please do not close this.",
                        title: 'Transaction Processing ...'
                    }
                })

                axios.patch('/api/credit/payment_status', {
                    tid: transactionID,
                    status: 1,
                    payload: {}
                })

                let result = await web3.eth
                    .sendTransaction({
                        from: account,
                        to: targetWallet,
                        value: web3.utils.toWei(amount.toString(), "ether"),
                    });
                //dispatch("map/openLoading", { status: false });
                if (result) {
                    console.log('ETH Transfer result', result)
                    return result;
                }
            } catch (error) {
                axios.patch('/api/credit/payment_status', {
                    tid: transactionID,
                    status: 4,
                    payload: {
                        "msg": "User denied web3 access",
                        error: error.message
                    }
                })
                commit("UITopupPaymentError", {
                    msg: {
                        msg: error.message,
                        title: 'Transaction error'
                    }
                })
            }
            return
        }
        // After send eth
        let result = await sendETH(amountCurrency)
        if (result) {
            /*
            let paymentResult = await axios.post(`/api/lands/crypto_payment_status`, {
                transaction_id: localStorage.getItem("transaction_crypto_id"),
                transactionHash: this.contractResult.transactionHash
            })*/
            commit("UITopupPaymentSuccess", {
                msg: {
                    msg: "Validating your transaction ....",
                    title: 'Blockchain transfer success. Please wait.'
                }
            })

            axios.patch('/api/credit/payment_status', {
                tid: transactionID,
                status: 2,
                payload: result
            })

            axios.post('/api/credit/payment/eth_crypto/verify', {
                tid: transactionID,
                txh: result.transactionHash
            }).then(() => {
                dispatch('account/getCredits', {}, {
                    root: true
                });
                commit("UITopupPaymentSuccess", {
                    msg: {
                        msg: "Payment completed",
                        title: 'Congratulations! Your transaction has been completed and confirmed by the blockchain.'
                    }
                })
                commit("UILandTopupDialogShow", {
                    display: false
                });
                /*eslint-disable */
                try {
                    fbq('track', 'Purchase', {
                        value: paymentTransactionData.totalUSD,
                        currency: 'USD'
                    });
                } catch (error) {

                }
                /*eslint-enable */
            }).catch(() => {
                // Can not validate transaction ??? 
                commit("UITopupPaymentError", {
                    msg: {
                        msg: "Can not validate transaction. Please contact us!",
                        title: 'Transaction address : ' + result.transactionHash
                    }
                })
            })
        }


        /*
         
            .then(result => {
            this.contractResult = result;
            console.log('contractResult', this.contractResult)
         
            //update status
            this.doUpdatePaymentStatus();
        })
        .catch(e => {
            console.log('Error', e);
            this.$swal(e.message, "", "error");
            this.$store.dispatch("map/openLoading", { status: false });
        });*/


        console.log(commit, amount)

    },


    terminateWertIOPayment({
        commit
    }) {
        commit("UIWertDialogShow", {
            display: false
        });
    },

    terminateLandCreditTopup({
        commit
    }) {
        commit("UILandTopupDialogShow", {
            display: false
        });
    },

    terminalNoticeDialog({
        commit
    }) {
        commit("UITopupWertNoticeStatus", false);
    },

    showLandSaleDialog({
        commit
    }) {
        commit("UIShowLandSaleDialog");
    }

};

const mutations = {

    UILandTopupDialogShow(state, {
        display
    }) {
        state.isShowModal = display
    },
    showPaymentStripeTopup(state) {
        if (state.showPaymentStripeTopup) {
            state.showPaymentStripeTopup = false
        } else {
            state.showPaymentStripeTopup = true
        }
    },

    UIWertDialogShow(state, {
        display
    }) {
        state.isWertDialogShow = display
        state.isShowModal = false
    },

    UITopupPaymentError(state, {
        msg
    }) {
        state.error = msg;
    },

    UITopupPaymentSuccess(state, {
        msg
    }) {
        state.success = msg;
    },

    UITopupWertLoadingStatus(state, status) {
        state.wertLoaded = status;
    },

    UITopupWertNoticeStatus(state, status) {
        state.isNoticeDialogShow = status;
    },

    UIShowLandSaleDialog(state) {
        state.showLandSaleDialog = true
    }
};

export const landCreditTopup = {
    namespaced: true,
    state,
    actions,
    mutations
};