<style scoped>
.page {
    display: block;
}

.page.page-center {
    background-color: #1e293b;
}


.card-login {
    max-width: 600px;
}

.form-control {
    color: white !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #999;
}

input:-webkit-autofill:focus {
    -webkit-text-fill-color: #999;
}

.input-group-text {
    height: 56px;
    color: #ffffff;
    background-color: #071838;
    border: none;
}
</style>
<template>
    <div class="page page-center">
        <div class="container container-tight py-4 card-login">
            <div style="height: 200px;"></div>
            <div class="card card-md bg-white">
                <div class="card-body">
                    <h2 class="h2 text-center mb-4">Change New Password</h2>
                    <form autocomplete="off" novalidate="" @submit="doSubmit">
                        <div class="mb-3">
                            <label class="form-label">Old password</label>
                            <input type="password" v-model="oldPassword" class="form-control" placeholder="Old password" autocomplete="off" />
                        </div>
                        <div class="mb-3">
                            <label class="form-label">New password</label>
                            <input type="password" v-model="newPassword" class="form-control" placeholder="Your password" autocomplete="off" />
                        </div>
                        <div class="mb-2">
                            <label class="form-label"> Retype New Password
                            </label>
                            <div class="input-group input-group-flat">
                                <input type="password" v-model="newPassword2" class="form-control" placeholder="Your password" autocomplete="off" />
                            </div>
                        </div>
                        <div class="form-footer">
                            <button class="btn btn-primary w-100" type="submit">Update Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {
    },
    name: "login-app",
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            newPassword2: "",
        };
    },
    methods: {
        doSubmit: function (e) {
            e.preventDefault();
            if (!this.newPassword) {
                this.$swal("Please enter new Password", "", "error");
                return
            }
            if (this.newPassword != this.newPassword2) {
                this.$swal("Retype New Password not match with New Password", "", "error");
                return
            }
            axios
                .post(`/api/account/change_password`, {
                    old: this.oldPassword,
                    new: this.newPassword,
                })
                .then(r => {
                    let data = r.data;
                    if (!data.error) {
                        this.$swal(data.msg, "", "success")
                        this.$router.push("/");
                    } else {
                        this.$swal(data.msg, "", "error")
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
};
</script>
