<template>
    <div>
        <div class="banner ov-hidden">
            <div id="particles" class="bg-img" style="
          background-image: url(https://www.themelooks.biz/demo/anefty/html/preview/assets/img/bg/banner-bg.png&quot;);
        ">
                <video class="elementor-background-video-hosted elementor-html5-video inited hoverex_resize" autoplay="" muted="" playsinline="" loop="" src="https://wccdn.appcloud.vn/public/nova/trialer.webm" style="width: 100%"></video>
                <div class="elementor-background-overlay"></div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="banner-content text-white mt-xl-5 pt-xl-2 mb-5 mb-lg-0">
                            <h1>Sell your spaceship on our <span>Marketplace</span> </h1>
                            <ul class="list-info text-white" style="opacity: 0">
                                <li>
                                    <h4>
                                        <span class="counter">3</span>k<span class="c1">+</span>
                                    </h4>
                                    <h6>Daily Volume</h6>
                                </li>
                                <li>
                                    <h4>
                                        <span class="counter">9</span>k<span class="c1">+</span>
                                    </h4>
                                    <h6>Daily Sales</h6>
                                </li>
                                <li>
                                    <h4>
                                        <span class="counter">6</span>k<span class="c1">+</span>
                                    </h4>
                                    <h6>Total NFTs</h6>
                                </li>
                            </ul>
                            <div class="button-group">
                                <RouterLink to="/market" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="svg replaced-svg">
                                        <path id="discover" d="M18.878,16.9a4.9,4.9,0,0,0,.683-2.4,4.392,4.392,0,1,0-8.78,0,4.428,4.428,0,0,0,4.39,4.5,4.614,4.614,0,0,0,2.341-.7l3.122,3.2L22,20.1Zm-3.707.1a2.5,2.5,0,1,1,2.439-2.5A2.446,2.446,0,0,1,15.171,17Zm-3.415,3v2A9.884,9.884,0,0,1,2,12,9.884,9.884,0,0,1,11.756,2a9.828,9.828,0,0,1,9.561,8H19.3a7.941,7.941,0,0,0-4.615-5.41V5a1.982,1.982,0,0,1-1.951,2H10.78V9A.991.991,0,0,1,9.8,10H7.854v2H9.8v3H8.829L4.156,10.21a8.311,8.311,0,0,0-.2,1.79A7.915,7.915,0,0,0,11.756,20Z" transform="translate(-2 -2)" fill="#fff"></path>
                                    </svg>
                                    Explore the marketplace
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <section class="pt-120 pb-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="section-title text-white mb-4">
              <h2>Who Trust In Us</h2>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="content text-white mt-n1">
              <p>
                NFTs are non-fungible tokens. They are unique items that you
                can't replace with something else. For example, a one-of-a-kind
                trading card is an NFT – you can't just replace it with any
                other card. If you trade your card for some other card, you have
                something different. These differ from fungible items, which are
                often the same as each other.
              </p>
              <p>
                Example, a one-of-a-kind trading card is an NFT – you can't just
                replace it with any other card. If you These differ from
                fungible items, which are often the same as each other
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-5 pt-1">
          <div class="col-12">
            <div
              class="swiper walletCarousel swiper-initialized swiper-horizontal swiper-pointer-events"
              data-swiper-loop="true"
              data-swiper-items="auto"
              data-swiper-margin="30"
              data-swiper-autoplay="true"
            >
              <div
                class="swiper-wrapper"
                id="swiper-wrapper-f348f59cebaa2e6f"
                aria-live="off"
                style="
                  transform: translate3d(-2634px, 0px, 0px);
                  transition-duration: 0ms;
                "
              >
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="0"
                  role="group"
                  aria-label="1 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://metamask.io/download.html"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet1.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="1"
                  role="group"
                  aria-label="2 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://user.bitski.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet2.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="2"
                  role="group"
                  aria-label="3 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://wallet.klaytn.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet3.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="3"
                  role="group"
                  aria-label="4 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://fortmatic.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet4.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="4"
                  role="group"
                  aria-label="5 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://authereum.com/welcome/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet5.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                  data-swiper-slide-index="5"
                  role="group"
                  aria-label="6 / 6"
                  style="margin-right: 30px"
                >
                  <a href="https://www.venly.io/" target="_blank" class="wallet"
                    ><img src="../assets/marketplace/wallet6.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate-active"
                  data-swiper-slide-index="0"
                  role="group"
                  aria-label="1 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://metamask.io/download.html"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet1.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate-next"
                  data-swiper-slide-index="1"
                  role="group"
                  aria-label="2 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://user.bitski.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet2.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide"
                  data-swiper-slide-index="2"
                  role="group"
                  aria-label="3 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://wallet.klaytn.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet3.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide"
                  data-swiper-slide-index="3"
                  role="group"
                  aria-label="4 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://fortmatic.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet4.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide"
                  data-swiper-slide-index="4"
                  role="group"
                  aria-label="5 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://authereum.com/welcome/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet5.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-prev"
                  data-swiper-slide-index="5"
                  role="group"
                  aria-label="6 / 6"
                  style="margin-right: 30px"
                >
                  <a href="https://www.venly.io/" target="_blank" class="wallet"
                    ><img src="../assets/marketplace/wallet6.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate swiper-slide-active"
                  data-swiper-slide-index="0"
                  role="group"
                  aria-label="1 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://metamask.io/download.html"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet1.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate swiper-slide-next"
                  data-swiper-slide-index="1"
                  role="group"
                  aria-label="2 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://user.bitski.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet2.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="2"
                  role="group"
                  aria-label="3 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://wallet.klaytn.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet3.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="3"
                  role="group"
                  aria-label="4 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://fortmatic.com/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet4.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate"
                  data-swiper-slide-index="4"
                  role="group"
                  aria-label="5 / 6"
                  style="margin-right: 30px"
                >
                  <a
                    href="https://authereum.com/welcome/"
                    target="_blank"
                    class="wallet"
                    ><img src="../assets/marketplace/wallet5.png" alt=""
                  /></a>
                </div>
                <div
                  class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                  data-swiper-slide-index="5"
                  role="group"
                  aria-label="6 / 6"
                  style="margin-right: 30px"
                >
                  <a href="https://www.venly.io/" target="_blank" class="wallet"
                    ><img src="../assets/marketplace/wallet6.png" alt=""
                  /></a>
                </div>
              </div>
              <span
                class="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </section> -->
        <section class="pt-120 pb-120 section-shape">
            <div class="container mt-60 mt-lg-0">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div class="section-title text-white text-center">
                            <h2>Frontier Favorites</h2>
                            <p>
                                A curated collection where space legends are born. Whether praised for their unparalleled speed, deft maneuverability, or sheer aesthetic allure, Frontier Favorites are the crowning jewels of the NovaFrontier universe.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="page-body mb-0">
                    <div class="container-xl">
                        <div class="row row-cards list-ships">
                            <div v-for="item in topNFT" v-bind:key="item.id" class="col-xl-3 col-lg-4 col-sm-6 col-6">
                                <div :class="'body-ship r-' + item.cache.rarity">
                                    <RouterLink :to="'market/' + item._id">
                                        <div class="header-ship">
                                            <div class="header-title">{{ item.name }}</div>
                                            <div :class="'header-type r-' + item.cache.rarity">
                                                <template v-if="item.cache.rarity == 0">Common</template>
                                                <template v-if="item.cache.rarity == 1">Rare</template>
                                                <template v-if="item.cache.rarity == 2">Epic</template>
                                                <template v-if="item.cache.rarity == 3">Legendary</template>
                                            </div>
                                        </div>
                                        <div class="image-ship">
                                            <img :src="getImage(item.cache.image)" />
                                            <span></span>
                                        </div>
                                        <div class="footer-ship">
                                            <img src="../../static/images/logo-list-item.png" /> VIEW
                                            ITEM
                                        </div>
                                    </RouterLink>
                                </div>
                            </div>
                            <!--<div class="col-lg-2">                                                                                                                                                                                   </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-120 pb-120">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div class="section-title text-white text-center">
                            <h2>Nova Sales</h2>
                            <p>
                                This is your gateway to recently concluded spaceship transactions. Discover the vessels that have found new commanders, get insights into the latest market trends, and witness the vibrant pulse of interstellar trade.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-body mb-0">
                <div class="container-xl">
                    <div class="row row-cards list-ships">
                        <div v-for="item in recentlySold" v-bind:key="item.id" class="col-xl-3 col-lg-4 col-sm-6  col-6">
                            <div :class="'body-ship r-' + item.cache.rarity">
                                <RouterLink :to="'market/' + item._id">
                                    <div class="header-ship">
                                        <div class="header-title">{{ item.name }}</div>
                                        <div :class="'header-type r-' + item.cache.rarity">
                                            <template v-if="item.cache.rarity == 0">Common</template>
                                            <template v-if="item.cache.rarity == 1">Rare</template>
                                            <template v-if="item.cache.rarity == 2">Epic</template>
                                            <template v-if="item.cache.rarity == 3">Legendary</template>
                                        </div>
                                    </div>
                                    <div class="image-ship">
                                        <img :src="getImage(item.cache.image)" />
                                        <span></span>
                                    </div>
                                    <div class="footer-ship">
                                        <img src="../../static/images/logo-list-item.png" /> VIEW
                                        ITEM
                                    </div>
                                </RouterLink>
                            </div>
                        </div>
                        <!--<div class="col-lg-2">                                                                                                                                                                                   </div>-->
                    </div>
                </div>
            </div>
        </section>
        <section id="contact" class="pb-120 mt-lg-120 pt-60 pt-lg-0 section-shape section-shape-right">
            <div class="container">
                <div class="row gutter-60">
                    <div class="col-lg-4">
                        <div class="support-img">
                            <img :src="API_URL + '/static/ships/contact.png'" />
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="support-content">
                            <div class="section-title text-white">
                                <h2>Support Base</h2>
                                <p>
                                    Need help? Talk to us. Whether you're trading, purchasing, or simply exploring, we ensure your orbits through our marketplace are stable and your journey is always supported.
                                </p>
                            </div>
                            <form action="/" class="support-form row">
                                <div class="col-lg-6">
                                    <input type="text" name="name" class="form-control" placeholder="Your Name" required="" />
                                </div>
                                <div class="col-lg-6">
                                    <input type="email" name="email" class="form-control" placeholder="Your Email" required="" />
                                </div>
                                <div class="col-12">
                                    <textarea name="message" class="form-control" placeholder="Write Message Here" required=""></textarea>
                                    <button type="submit" class="btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" class="svg replaced-svg">
                                            <path id="mail" d="M11.524,19a6.834,6.834,0,0,1,6.667-7,6.392,6.392,0,0,1,2.857.68V6a1.96,1.96,0,0,0-1.9-2H3.9A1.96,1.96,0,0,0,2,6V18a1.96,1.96,0,0,0,1.9,2h7.7A6.959,6.959,0,0,1,11.524,19ZM3.9,6l7.619,5,7.619-5V8l-7.619,5L3.9,8Zm12.7,16-3.371-3.54,1.343-1.41L16.6,19.17l4.038-4.24L22,16.34Z" transform="translate(-2 -4)" fill="#fff"></path>
                                        </svg>
                                        Send Request
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pt-120 pb-90">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div class="section-title text-white text-center">
                            <h2>Starship Arrivals</h2>
                            <p>
                                The newest entrants to our Marketplace, Starship Arrivals is your first port of call to scout fresh off-the-dock vessels. Stay up-to-date on our newest arrivals.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div v-for="(item, index) in topNFT7Days" v-bind:key="item.id" class="col-xl-3 col-lg-4 col-sm-6">
                        <RouterLink :to="'market/' + item._id" class="single-collection" :data-count="'0' + (index + 1)">
                            <div class="collection-img">
                                <img width="40" :src="getImage(item.cache.image)" alt="" />
                            </div>
                            <div class="content">
                                <h5>{{ item.name }}</h5>
                                <span>{{ item.total }}</span>
                            </div>
                        </RouterLink>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
//import axios from "axios";
import { mapState } from "vuex";
import axios from "axios";
export default {
    components: {
        // Select2
    },
    data() {
        return {
            API_URL: process.env.VUE_APP_BASE_URL,
            recentlySold: [],
            topNFT: [],
            topNFT7Days: []
        };
    },

    methods: {

        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },
        fetchRecentlySold() {
            axios.get(`/api/marketplace/recently_sold`)
                .then((r) => {
                    let data = r.data;
                    if (!data.error) {
                        this.recentlySold = data.data;
                    }
                })
                .catch((e) => {
                    // Network error
                    console.log(e);
                });
        },
        fetchTopNFT() {
            axios.get(`/api/marketplace/top_nft`)
                .then((r) => {
                    let data = r.data;
                    if (!data.error) {
                        this.topNFT = data.data;
                    }
                })
                .catch((e) => {
                    // Network error
                    console.log(e);
                });
        },
        fetchTopNFT7Days() {
            axios.get(`/api/marketplace/last_7_days`)
                .then((r) => {
                    let data = r.data;
                    if (!data.error) {
                        this.topNFT7Days = data.data;
                    }
                })
                .catch((e) => {
                    // Network error
                    console.log(e);
                });
        },

    },

    computed: {
        ...mapState("account", ["status", "creditAmount", "user"]),
        ...mapState("cart", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "landType",
            "landCollection",
            "landPlotBuyResult",
            "addCartException",
            "showDialogPaymentStripe",
        ]),
        ...mapState("map", ["styleMap"]),
    },
    watch: {
        /*
        landPlotBuyResult: function ({ isError }) {
          this.clearSelectionLand();
          if (!isError) {
            this.fetchLands();
          }
        },
    
        addCartException: function ({ error, title, msg }) {
          this.$swal(title, msg, error ? "error" : false);
        },*/
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        //let self = this;
        this.fetchRecentlySold();
        this.fetchTopNFT();
        this.fetchTopNFT7Days();
    },
};
</script>
