<style scoped>
    .page {
        display: block;
    }
</style>
<template>
    <div class="page page-center">
        <div class="container container-narrow py-4">
            <div class="text-center mb-4">
                <a href="." class="navbar-brand navbar-brand-autodark">
                    <img src="/img/logo.58aa872c.png" width="150" alt="Tabler">
                </a>
            </div>

            <div class="card card-md">
                <div class="card-body">
                    <div v-html="data.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        components: {},
        name: "terms_of_service",
        data() {
            return {
                data: {
                    content: ""
                }
            };
        },
        methods: {
            getTermsOfService: function () {
                axios
                    .get(`/api/resource/tos `, {})
                    .then(r => {
                        console.log(r);
                        if (r.data.error) {
                            alert(r.data.msg)
                        } else {
                            this.data = r.data.data;
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
        },
        mounted: function () {
            this.getTermsOfService();
        },
    };
</script>
