<style scoped>
.page {
    display: block;
}

.page.page-center {
    background-color: #1e293b;
}

.card-login {
    max-width: 600px;
}

.form-control {
    color: white !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #999;
}

input:-webkit-autofill:focus {
    -webkit-text-fill-color: #999;
}

.input-group-text {
    height: 56px;
    color: #ffffff;
    background-color: #071838;
    border: none;
}
</style>
<template>
    <div class="page page-center">
        <div class="container container-tight py-4 card-login">

            <div style="height: 200px;"></div>
            <form class="card card-md  bg-white" autocomplete="off" novalidate="false" @submit="doSubmit">
                <div class="card-body">
                    <h2 class="h2 text-center mb-4">Sign up for an account</h2>
                    <div class="mb-3">
                        <label class="form-label">Username</label>
                        <input type="text" v-model="data.username" class="form-control" placeholder="Enter username" autocomplete="off" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Email</label>
                        <input type="email" v-model="data.email" class="form-control" placeholder="Enter email" autocomplete="off" />
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Password</label>
                        <div class="input-group input-group-flat">
                            <input :type="showPassword ? 'text' : 'password'" v-model="data.password" class="form-control" placeholder="Enter password" autocomplete="off" />
                            <span class="input-group-text">
                                <a @click="toggleShow('password')" class="link-secondary" data-bs-toggle="tooltip" aria-label="Show password" data-bs-original-title="Show password"><!-- Download SVG icon from http://tabler-icons.io/i/eye -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" v-if="showPassword" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" v-else viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <circle cx="12" cy="12" r="2"></circle>
                                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                    </svg>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Confirm Password</label>
                        <div class="input-group input-group-flat">
                            <input :type="showReplayPassword ? 'text' : 'password'" v-model="data.confirm_password" class="form-control" placeholder="Confirm password" autocomplete="off" />
                            <span class="input-group-text">
                                <a @click="toggleShow('confirm_password')" class="link-secondary" data-bs-toggle="tooltip" aria-label="Show password" data-bs-original-title="Show password"><!-- Download SVG icon from http://tabler-icons.io/i/eye -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" v-if="showReplayPassword" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" v-else viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <circle cx="12" cy="12" r="2"></circle>
                                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                    </svg>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="data.agree_terms" checked />
                            <span class="form-check-label">
                                <span v-if="data.agree_terms">I have read and agreed to the Terms & Conditions.</span>
                                <span v-else>I agree to the <a href="/tos" target="_blank">Terms & Conditions.</a></span>
                            </span>
                        </label>
                    </div>
                    <div class="form-footer">
                        <button type="submit" class="btn btn-primary w-100">Create new account
                        </button>
                    </div>
                </div>
            </form>
            <div class="text-center text-white mt-3">Already have an account? <router-link to="/login" tabindex="-1" style="color: #4f9fff;">Log in</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    components: {},
    name: "registry-app",
    data() {
        return {
            data: {
                username: "",
                email: "",
                password: "",
                confirm_password: "",
                agree_terms: true
            },
            showPassword: false,
            showReplayPassword: false,
        };
    },
    methods: {
        toggleShow(type) {
            switch (type) {
                case "password":
                    this.showPassword = !this.showPassword;
                    break;
                case "confirm_password":
                    this.showReplayPassword = !this.showReplayPassword;
                    break;
            }
        },
        doSubmit: function (e) {
            e.preventDefault();

            if (this.data.password != this.data.confirm_password) {
                this.$swal("Your password does not match.", "", "error");
                return;
            }

            if (!this.data.agree_terms) {
                this.$swal("You have not agreed to the terms and conditions!", "", "error");
                return;
            }
            axios
                .post(`/api/account/registry`, {
                    username: this.data.username,
                    email: this.data.email,
                    password: this.data.password,
                })
                .then(r => {
                    console.log(r);
                    if (r.data.error) {
                        this.$swal(r.data.msg, "", "error");
                    } else {
                        this.$swal("A verification email has been sent to you. Please follow the instructions within to activate your account. If you encounter any issues, contact us for support.", "", "success");
                        // this.data = {
                        //   username: "",
                        //   email: "",
                        //   password: "",
                        //   confirm_password: "",
                        // };
                        this.$router.push("/login");
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
};
</script>
