<style scoped>
.text-red {
    color: red;
}

.text-remove {
    margin-left: 20px;
    color: red !important;
    cursor: pointer;
}
</style>

<template>
    <div class="container-xl">
        <div style="height: 200px;"></div>
        <div class="row  text-achivement">
            <div class="col-12">
                <div class="card card-md">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-10">
                                <h3 class="h1">Stake your penguins to give your spaceship an extra boost.</h3>
                                <div class="markdown text-secondary">
                                    Connect your wallet for us to fetch your penguins. The more penguins you own, the more spaceships you can boost! Penguins that rank higher in rarity (in order of: Common → Rare → Legendary → 1 of 1) offer a stronger boost to spaceships.

                                    <p>
                                        <button @click="doFetchPeguins" class="btn buynow mt-3 p-3 "><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-wallet" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path>
                                                <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
                                            </svg>CONNECT YOUR WALLET</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center mt-4 pt-2">
                <table class="table table-vcenter table-mobile-md card-table text-white tbl-my-assets table-bordered">
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Photo</th>
                        <th>Spaceship</th>
                    </tr>
                    <tr v-for="(item, index) in items" v-bind:key="item._id">
                        <td>
                            #{{ index + 1 }}
                        </td>
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            <img :src="item.image" height="100px" style="height: 100px;" />
                        </td>
                        <td>
                            <div v-if="!item.spaceship">
                                <a class="btn btn-xs" @click="onShowSelectSpaceship(item._id)">Assign spaceship</a>
                            </div>
                            <div v-if="item.spaceship">
                                <a v-if="item.spaceship" style="text-align: center">
                                    <div class="image-ship">
                                        <img :src="getImage(item.spaceship.item.image)" style="width: 50px;" />
                                    </div>
                                </a>
                                <span class="ship-class mx-2" :class="`ship-class-${item.spaceship.item.element}`">
                                </span>
                                <b> {{ item.spaceship.item.name }}</b>
                                <a @click="doRemoveAssignGP(item._id)" class="text-remove">Remove</a>
                            </div>
                        </td>
                    </tr>
                </table>
                <!--
                <a @click="viewPage(i)" v-for="i in pages" :key="i" class="btn btn-page" :class="{ 'btn-primary': (i == page) }">{{ i }}</a>
                -->
            </div><!--end col-->
        </div>

        <!-- Modal spaceships -->

        <div class="modal modal-blur fade show" id="toup-payment-method" tabindex="-1" role="dialog" aria-modal="true" style="display: block;" v-if="isShowSpaceshipModal">
            <div class="modal-dialog modal-dialog-centered  modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Select spaceship</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModalSpaceship"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6 text-center" v-for="item in spaceships" v-bind:key="item._id">
                                <a v-if="item" style="text-align: center">
                                    <div class="image-ship">
                                        <img :src="getImage(item.image)" style="width: 50px;" />
                                    </div>
                                </a>
                                <b> {{ item.name }}</b>
                                <!--
                                <span class="ship-class mx-2" :class="`ship-class-${item.element}`">
                                </span>
                                -->
                                <br />
                                <span :class="'header-type r-' + item.rarity">
                                    <template v-if="item.rarity == 0">Common</template>
                                    <template v-if="item.rarity == 1">Rare</template>
                                    <template v-if="item.rarity == 2">Epic</template>
                                    <template v-if="item.rarity == 3">Legendary</template>
                                </span>
                                <br />
                                <a v-if="isAvaibleAssign(item._id)" class="btn btn-page btn-primary" @click="doSelectSpaceship(item._id)">Select</a>
                            </div>
                        </div>

                        <table v-if="false" class="table table-vcenter table-mobile-md card-table text-white tbl-my-assets table-bordered table-scroll">
                            <tr>
                                <th>Name</th>
                                <th>Select</th>
                            </tr>
                            <tr v-for="item in spaceships" v-bind:key="item._id">
                                <td>
                                    <a v-if="item" style="text-align: center">
                                        <div class="image-ship">
                                            <img :src="getImage(item.image)" style="width: 50px;" />
                                        </div>
                                    </a>
                                    <span class="ship-class mx-2" :class="`ship-class-${item.element}`">
                                    </span>
                                    <b> {{ item.name }}</b>
                                </td>
                                <td>
                                    <div :class="'header-type r-' + item.rarity">
                                        <template v-if="item.rarity == 0">Common</template>
                                        <template v-if="item.rarity == 1">Rare</template>
                                        <template v-if="item.rarity == 2">Epic</template>
                                        <template v-if="item.rarity == 3">Legendary</template>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div class="row">
                            <div class="col-12 text-center mt-4 pt-2">
                                <a @click="viewPage(i)" v-for="i in pages" :key="i" class="btn btn-page" :class="{ 'btn-primary': (i == page) }">{{ i }}</a>
                            </div><!--end col-->
                        </div>




                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";


export default {
    name: "HomeMarketpalce",
    components: {
    },
    data() {
        return {
            isShowSpaceshipModal: false,
            filter: {
                element: {
                    ai: false,
                    alien: false,
                    earth: false
                },
                rarity: {
                    common: false,
                    rare: false,
                    epic: false,
                    legend: false
                },
                price: {
                    from: 0,
                    to: 0
                }
            },
            value: 1,
            items: [],
            total: '...',
            pages: [],
            page: 1,
            spaceships: [],
            selectedPeguinID: null
        };
    },

    methods: {

        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },
        doFetchPeguins: async function () {
            let { data } = await axios.get('/api/fetch/peguins');
            console.log(data);
            this.getPeguins();
        },

        getPeguins: async function () {
            let { data } = await axios.get('/api/my/peguins');
            this.items = data.data;
        },

        onShowSelectSpaceship: async function (peguinID) {
            this.selectedPeguinID = peguinID;
            this.isShowSpaceshipModal = true;
            this.fetchItems();
        },
        closeModalSpaceship: function () {
            this.isShowSpaceshipModal = false;
        },

        isAvaibleAssign: function (spaceshipID) {


            let count = this.items.filter(c => {
                if (!c.spaceship || c.spaceship == null) {
                    return false
                }
                if (c.spaceship != null && c.spaceship.id != spaceshipID) {
                    return false
                }
                return true;
            }).length;
            return count == 0;

        },

        fetchItems: async function () {
            let limit = 6;
            try {
                let filterQuery = {
                    element: "",
                    rarity: "",
                    price_from: "",
                    price_to: "",
                    page: this.page,
                    limit: limit
                }
                let { data } = await axios.get('/api/my/assets', { params: filterQuery });
                console.log(data);
                this.spaceships = data.data.map(e => {
                    e.state = 0;
                    return e;
                });
                this.total = data.total;

                let numberPage = Math.ceil(data.total / limit);
                let ps = [];
                for (var i = (this.page - 8); i <= this.page + 8; i++) {
                    if (i > 0 && i <= numberPage) {
                        ps.push(i);
                    }
                }
                this.pages = ps;

            } catch (error) {
                console.log(error);
            }
        },
        viewPage(p) {
            this.page = p;
            this.fetchItems();
        },

        async doSelectSpaceship(spaceshipID) {
            let { data } = await axios.post('/api/my/peguins/assign', {
                spaceship: spaceshipID,
                peguin: this.selectedPeguinID
            });
            if (data.error) {
                this.$swal
                    .fire({
                        icon: "error",
                        title: data.msg
                    });
                return;
            }

            this.$swal
                .fire({
                    icon: "success",
                    //title: data.msg
                });
            console.log(data);
            this.closeModalSpaceship();
            this.getPeguins();
        },
        async doRemoveAssignGP(peguinID) {
            let { data } = await axios.delete('/api/my/peguins/assign/' + peguinID);
            if (data.error) {
                this.$swal
                    .fire({
                        icon: "error",
                        title: data.msg
                    });
                return;
            }

            this.$swal
                .fire({
                    icon: "success",
                });
            this.getPeguins();
        }
    },

    computed: {
    },
    watch: {
    },

    mounted() {
        this.getPeguins();
    },
};
</script>
