import axios from 'axios';
window.Buffer = Buffer; // needed to use `signSmartContractData` in browser
import Web3 from "web3";

const state = {
    mintingStep: 0,
    error: null,
    isMinting: false
}

const actions = {

    /**
     * Make request for minting nft from collection id
     */
    async request({ state, dispatch }, { collectionID, onReject, callback }) {

        if (state.isMinting) {
            return;
        }

        console.log("User start minting request");
        // Get transaction config
        let paymentTransactionData = undefined;
        try {
            let response = await axios.post('/api/minting/request/', {
                item_id: collectionID
            });
            paymentTransactionData = response.data;
            if (paymentTransactionData.error) { 
                dispatch("swal/show", {
                    title: "",
                    msg: paymentTransactionData.msg,
                    type: "error",
                    showConfirmButton: true,
                    allowOutsideClick: true
                }, { root: true }); 
                return;
            } 
        } catch (error) { 
            dispatch("swal/show", {
                title: "Payment request  error !!",
                msg: error.response.data.msg,
                type: "error",
                showConfirmButton: true,
                allowOutsideClick: true
            }, { root: true }); 
            return
        }

        let targetNetworkId = paymentTransactionData.targetNetworkId;
        let targetWallet = paymentTransactionData.targetWallet;
        let amountCurrency = paymentTransactionData.amountCurrency;
        let transactionID = paymentTransactionData.tid;
  
        if (typeof window.ethereum === "undefined") { 
            dispatch("swal/show", {
                title: " ",
                msg: "Please install Metamask and connect your wallet",
                type: "error",
                showConfirmButton: true,
                allowOutsideClick: true
            }, { root: true }); 
            return; 
        }

        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: targetNetworkId }],
            });
        } catch (err2) {
            console.log(err2);
        }
        state.isMinting = true; 

        // Request connect eth network
        try {
            console.log("MINTING", "Open request dialog"); 
            // Ask to connect
            await window.ethereum.send("eth_requestAccounts"); 

        } catch (error) {
            // User denied account access
            console.error("User denied web3 access", error, targetNetworkId);

            axios.patch('/api/minting/request/status', {
                tid: transactionID,
                status: 4,
                payload: {
                    "msg": "User denied web3 access",
                    error: error.message
                }
            })


            dispatch("swal/show", {
                title: "Minting failed",
                msg: "Error",
                type: "error",
                showConfirmButton: true,
                allowOutsideClick: true
            }, { root: true }); 
            onReject(); 
        }

        // amount


        let sendETH = async function sendETH(amount) {
            try {
                // Send request to web3
                let web3 = new Web3(window.ethereum);
                var accounts = await web3.eth.getAccounts();
                var account = accounts[0];


                dispatch("swal/show", {
                    title: "Transaction Processing ...",
                    msg: "IMPORTANT! Please do not close this and check metamask popup!",
                    type: "info",
                    allowOutsideClick: false,
                    showConfirmButton: false
                }, { root: true });
 

                axios.patch('/api/minting/request/status', {
                    tid: transactionID,
                    status: 1,
                    receiver: account,
                    payload: {}
                })

                console.log({
                    from: account,
                    to: targetWallet,
                    value: web3.utils.toWei(amount.toString(), "ether"),
                });

                let result = await web3.eth
                    .sendTransaction({
                        from: account,
                        to: targetWallet,
                        value: web3.utils.toWei(amount.toString(), "ether"),
                    });

                state.isMinting = false;
                //dispatch("map/openLoading", { status: false });
                if (result) {
                    console.log('ETH Transfer result', result)
                    return result;
                }
            } catch (error) {
                state.isMinting = false;
                onReject();
                console.log(error);
                axios.patch('/api/minting/request/status', {
                    tid: transactionID,
                    status: 4,
                    payload: {
                        "msg": "User denied web3 access",
                        error: error.message
                    }
                }).then(() => {

                    dispatch("swal/show", {
                        title: "Transaction error",
                        msg: error.message,
                        type: "error",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });


                }).catch(() => {

                    dispatch("swal/show", {
                        title: "Transaction error",
                        msg: error.message,
                        type: "error",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });

                })
            }
            return
        }
        // After send eth
        let result = await sendETH(amountCurrency)
        if (result) {

            dispatch("swal/show", {
                title: "Minting initiated. Validating your transaction.",
                msg: "DO NOT close or refresh the page. Please wait until the status changes.",
                type: "info",
                showConfirmButton: false,
                allowOutsideClick: false
            }, { root: true });
 
            await axios.patch('/api/minting/request/status', {
                tid: transactionID,
                status: 2,
                payload: result
            })
 
            // Verify and go to minting step /api/credit/payment/eth_crypto/verify

            try {
                let paymentVerifyRequest = await axios.post('/api/minting/request/verify_payment', {
                tid: transactionID,
                txh: result.transactionHash
            }); 
            if(paymentVerifyRequest.data){
                 if (paymentVerifyRequest.data.error) {
                    dispatch("swal/show", {
                        title: "",
                        msg: paymentVerifyRequest.data.msg,
                        type: "error",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });
                 }else{
                    callback(result.transactionHash); 
                    dispatch("swal/show", {
                        title: "Congratulations",
                        msg: "Your NFT minting task added to queue.",
                        type: "success",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });
                 }
            }else{
                dispatch("swal/show", {
                    title: " ",
                    msg: "Unable request to server, please try again.",
                    type: "error",
                    showConfirmButton: true,
                    allowOutsideClick: true
                }, { root: true });
            } 
            } catch (error) {
                dispatch("swal/show", {
                    title: " ",
                    msg: "Cannot validate transaction. Please contact us!",
                    type: "error",
                    showConfirmButton: true,
                    allowOutsideClick: true
                }, { root: true });
            }
            
        }


    },

};

const mutations = {

};

export const minting = {
    namespaced: true,
    state,
    actions,
    mutations
};