<template>
    <div class="container-xl">
        <div style="height: 10rem;"></div>
        <div class="row filter-col">
            <div class="col-md-12 col-lg-3">

                <div>
                    <div class="filter-box mb-4">
                        <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-vercel" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M3 19h18l-9 -15z"></path>
                            </svg> Element</h4>
                        <div id="filters" class="mt-3">
                            <label class="form-check">
                                <input v-model="filter.element.dnc" class="form-check-input" type="checkbox">
                                <span class="form-check-label text-class">DNC Pack</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.element.ai" class="form-check-input" type="checkbox">
                                <span class="form-check-label text-class-1">AI</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.element.alien" class="form-check-input" type="checkbox">
                                <span class="form-check-label text-class-2">Alien</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.element.human" class="form-check-input" type="checkbox">
                                <span class="form-check-label text-class-0">Earth Human</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="filter-box mb-4">
                        <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-loader-quarter" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 6l0 -3"></path>
                                <path d="M6 12l-3 0"></path>
                                <path d="M7.75 7.75l-2.15 -2.15"></path>
                            </svg> Rarity</h4>
                        <div id="filters" class="mt-3">
                            <label class="form-check">
                                <input v-model="filter.rarity.common" class="form-check-input" type="checkbox">
                                <span class="form-check-label">Common</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.rarity.rare" class="form-check-input" type="checkbox">
                                <span class="form-check-label">Rare</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.rarity.epic" class="form-check-input" type="checkbox">
                                <span class="form-check-label">Epic</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.rarity.legend" class="form-check-input" type="checkbox">
                                <span class="form-check-label">Legendary</span>
                            </label>
                        </div>
                    </div>
                </div>



                <div class="info-detail-ship mt-3 mb-4">
                    <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-coins" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z"></path>
                            <path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4"></path>
                            <path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z"></path>
                            <path d="M3 6v10c0 .888 .772 1.45 2 2"></path>
                            <path d="M3 11c0 .888 .772 1.45 2 2"></path>
                        </svg> Price Range</h4>
                    <div class="mt-3 row">
                        <div class="col-6">
                            <label class="form-label">From (USD)</label>
                            <div class="input-icon mb-3">
                                <input v-model="filter.price.from" type="text" class="form-control" placeholder="Min">
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="form-label">To (USD)</label>
                            <div class="input-icon mb-3">
                                <input v-model="filter.price.to" type="text" class="form-control" placeholder="Max">
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div class="col-md-12 col-lg-9">
                <div id="rowresult">
                    <div class="page-header mx-2">
                        <div class="page-pretitle">Home > Marketplace</div>
                        <div class="page-pretitle sort-by-div">
                            <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                    <path d="M21 21l-6 -6"></path>
                                </svg> {{ total }} items</h4>
                        </div>
                    </div>
                </div>
                <div class="row row-cards list-ships">
                    <div class="ship-item col-6 col-md-4 col-lg-3" v-for="item in items" v-bind:key="item._id">
                        <spaceshipModule v-if="item.type == 'spaceship'" :item="item"></spaceshipModule>
                        <marketDNCModule v-if="item.type == 'dnc'" :item="item"></marketDNCModule>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center mt-4 pt-2">
                        <a @click="viewPage(i)" v-for="i in pages" :key="i" class="btn btn-page" :class="{ 'btn-primary': (i == page) }">{{ i }}</a>
                    </div><!--end col-->
                </div>

                <!-- Page body -->
                <div class="page-body mb-5">
                    <div class="container-xl">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";
import { mapState } from "vuex";
import spaceshipModule from "../components/MarketSpaceshipModule.vue";
import marketDNCModule from "../components/MarketDNCModule.vue";

function objectToConcat(object1) {
    let item = [];
    for (const [key, value] of Object.entries(object1)) {
        if (value) {
            item.push(key);
        }
    }
    return item.join(',');
}


export default {
    name: "HomeMarketpalce",
    components: {
        spaceshipModule,
        marketDNCModule
    },
    data() {
        return {
            filter: {
                element: {
                    dnc: false,
                    ai: false,
                    alien: false,
                    earth: false
                },
                rarity: {
                    common: false,
                    rare: false,
                    epic: false,
                    legend: false
                },
                price: {
                    from: 0,
                    to: 0
                }
            },
            value: 1,
            items: [],
            total: '...',
            pages: [],
            page: 1
        };
    },

    methods: {
        viewPage(p) {
            this.page = p;
            this.fetchItems();
            document.getElementById("rowresult").scrollIntoView();
        },
        priceRound(p) {
            return '$' + p.toFixed(3)
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },

        fetchItems: async function () {
            let limit = 20;
            try {
                let filterQuery = {
                    element: objectToConcat(this.filter.element),
                    rarity: objectToConcat(this.filter.rarity),
                    price_from: this.filter.price.from,
                    price_to: this.filter.price.to,
                    page: this.page,
                    limit: limit
                }
                let { data } = await axios.get('/api/marketplace/find', { params: filterQuery });
                console.log(data);
                this.items = data.data;
                this.total = data.total;


                let numberPage = Math.round(data.total / limit);
                let ps = [];
                for (var i = (this.page - 8); i <= this.page + 8; i++) {
                    if (i > 0 && i <= numberPage) {
                        ps.push(i);
                    }
                }
                this.pages = ps;

            } catch (error) {
                console.log(error);
            }
        },
        filterBulder() {
            let filterQuery = {
                element: objectToConcat(this.filter.element),
                rarity: objectToConcat(this.filter.rarity),
                price_from: this.filter.price.from,
                price_to: this.filter.price.to,
            }
            this.$router.push({ query: filterQuery });
            this.fetchItems();
        }
    },

    computed: {
        ...mapState("account", ["status", "creditAmount", "user"]),
        ...mapState("cart", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "landType",
            "landCollection",
            "landPlotBuyResult",
            "addCartException",
            "showDialogPaymentStripe",
        ]),
        ...mapState("map", ["styleMap"]),
    },
    watch: {
        landPlotBuyResult: function ({ isError }) {
            this.clearSelectionLand();
            if (!isError) {
                this.fetchLands();
            }
        },

        addCartException: function ({ error, title, msg }) {
            this.$swal(title, msg, error ? "error" : false);
        },

        filter: {
            handler(newValue) {
                console.log(newValue);
                this.filterBulder();
            },
            deep: true
        }
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        this.fetchItems();
    },
};
</script>
