const state = {
    itemCollection: [],
}


const actions = {

    selectCollection({ commit }, { collection }) {

        commit("addItemMutation", { collection });
    },

};

const mutations = {

    addItemMutation(state, { collection }) {
        console.log('collection', collection)
        state.itemCollection = collection;
    },


};

export const collections = {
    namespaced: true,
    state,
    actions,
    mutations
};