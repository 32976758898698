<style scoped>
.card {
    overflow: hidden;
}

.icon-status>svg {
    width: 120px;
    height: 120px;
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 980px;
    }
}

.text-location {
    max-width: 250px;
}
</style>
<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body my-0">
                <div class="container bg-white py-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <a href="#" @click="backHistory" tabindex="-1">
                                <h2 class="page-title">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                        <line x1="5" y1="12" x2="11" y2="18"></line>
                                        <line x1="5" y1="12" x2="11" y2="6"></line>
                                    </svg>
                                    Back
                                </h2>
                            </a>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <h3 class="text-azure">
                                Land Collections
                            </h3>
                            <h2 class="page-title">
                                {{ itemCollection.name }}
                            </h2>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <div class="section-title">
                                <h4 class="text-azure">LAND ANALYSIS</h4>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-8 divide-y">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <p>
                                                        <b>Number Of Buildings</b>
                                                    </p>
                                                    <p class="d-flex mb-2">
                                                        <span>No information yet</span>
                                                        <sup>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler icon-tabler-info-circle" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <circle cx="12" cy="12" r="9"></circle>
                                                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                                                <polyline points="11 12 12 12 12 16 13 16"></polyline>
                                                            </svg>
                                                        </sup>
                                                    </p>
                                                </div>
                                                <div class="col-lg-4">
                                                    <p>
                                                        <b>Average Daily Transactions</b>
                                                    </p>
                                                    <p class="d-flex mb-2">
                                                        <span>No information yet</span>
                                                        <sup>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler icon-tabler-info-circle" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <circle cx="12" cy="12" r="9"></circle>
                                                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                                                <polyline points="11 12 12 12 12 16 13 16"></polyline>
                                                            </svg>
                                                        </sup>
                                                    </p>
                                                </div>
                                                <div class="col-lg-4">
                                                    <p>
                                                        <b>Total Transaction Volume</b>
                                                    </p>
                                                    <p class="d-flex mb-2">
                                                        <span>No information yet</span>
                                                        <sup>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler icon-tabler-info-circle" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <circle cx="12" cy="12" r="9"></circle>
                                                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                                                <polyline points="11 12 12 12 12 16 13 16"></polyline>
                                                            </svg>
                                                        </sup>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <p>
                                                        <b>Estimated Daily Earnings</b>
                                                    </p>
                                                    <p class="d-flex mb-2">
                                                        <span>No information yet</span>
                                                        <sup>
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler icon-tabler-info-circle" width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <circle cx="12" cy="12" r="9"></circle>
                                                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                                                <polyline points="11 12 12 12 12 16 13 16"></polyline>
                                                            </svg>
                                                        </sup>
                                                    </p>
                                                </div>
                                                <div class="col-lg-4">
                                                    <p>
                                                        <b>Population Density</b>
                                                    </p>
                                                    <p class="mb-2">High</p>
                                                </div>
                                                <div class="col-lg-4">
                                                    <p>
                                                        <b>Land Value</b>
                                                    </p>
                                                    <p class="mb-2">High</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div id="chart-completion-tasks-2">
                                                <div id="apexchartsqdnn2s4ri" class="apexcharts-canvas apexchartsqdnn2s4ri apexcharts-theme-light" style="width: 371px; height: 240px;">
                                                    <svg id="SvgjsSvg1573" width="371" height="240" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev" class="apexcharts-svg apexcharts-zoomable hovering-zoom" xmlns:data="ApexChartsNS" transform="translate(0, 0)" style="background: transparent;">
                                                        <g id="SvgjsG1575" class="apexcharts-inner apexcharts-graphical" transform="translate(31.859375, 10)">
                                                            <defs id="SvgjsDefs1574">
                                                                <clipPath id="gridRectMaskqdnn2s4ri">
                                                                    <rect id="SvgjsRect1585" width="345.140625" height="203" x="-3" y="-1" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fff"></rect>
                                                                </clipPath>
                                                                <clipPath id="forecastMaskqdnn2s4ri"></clipPath>
                                                                <clipPath id="nonForecastMaskqdnn2s4ri"></clipPath>
                                                                <clipPath id="gridRectMarkerMaskqdnn2s4ri">
                                                                    <rect id="SvgjsRect1586" width="343.140625" height="205" x="-2" y="-2" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fff"></rect>
                                                                </clipPath>
                                                            </defs>
                                                            <line id="SvgjsLine1581" x1="56.0234375" y1="0" x2="56.0234375" y2="201" stroke="#b6b6b6" stroke-dasharray="3" stroke-linecap="butt" class="apexcharts-xcrosshairs" x="56.0234375" y="0" width="1" height="201" fill="#b1b9c4" filter="none" fill-opacity="0.9" stroke-width="1"></line>
                                                            <line id="SvgjsLine1596" x1="0" y1="202" x2="0" y2="208" stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-xaxis-tick"></line>
                                                            <line id="SvgjsLine1597" x1="56.5234375" y1="202" x2="56.5234375" y2="208" stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-xaxis-tick"></line>
                                                            <line id="SvgjsLine1598" x1="113.046875" y1="202" x2="113.046875" y2="208" stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-xaxis-tick"></line>
                                                            <line id="SvgjsLine1599" x1="169.5703125" y1="202" x2="169.5703125" y2="208" stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-xaxis-tick"></line>
                                                            <line id="SvgjsLine1600" x1="226.09375" y1="202" x2="226.09375" y2="208" stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-xaxis-tick"></line>
                                                            <line id="SvgjsLine1601" x1="282.6171875" y1="202" x2="282.6171875" y2="208" stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt" class="apexcharts-xaxis-tick"></line>
                                                            <g id="SvgjsG1592" class="apexcharts-grid">
                                                                <g id="SvgjsG1593" class="apexcharts-gridlines-horizontal">
                                                                    <line id="SvgjsLine1603" x1="0" y1="40.2" x2="339.140625" y2="40.2" stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt" class="apexcharts-gridline"></line>
                                                                    <line id="SvgjsLine1604" x1="0" y1="80.4" x2="339.140625" y2="80.4" stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt" class="apexcharts-gridline"></line>
                                                                    <line id="SvgjsLine1605" x1="0" y1="120.60000000000001" x2="339.140625" y2="120.60000000000001" stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt" class="apexcharts-gridline"></line>
                                                                    <line id="SvgjsLine1606" x1="0" y1="160.8" x2="339.140625" y2="160.8" stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt" class="apexcharts-gridline"></line>
                                                                </g>
                                                                <g id="SvgjsG1594" class="apexcharts-gridlines-vertical"></g>
                                                                <line id="SvgjsLine1609" x1="0" y1="201" x2="339.140625" y2="201" stroke="transparent" stroke-dasharray="0" stroke-linecap="butt"></line>
                                                                <line id="SvgjsLine1608" x1="0" y1="1" x2="0" y2="201" stroke="transparent" stroke-dasharray="0" stroke-linecap="butt"></line>
                                                            </g>
                                                            <g id="SvgjsG1587" class="apexcharts-line-series apexcharts-plot-series">
                                                                <g id="SvgjsG1588" class="apexcharts-series" seriesName="Tasksxcompletion" data:longestSeries="true" rel="1" data:realIndex="0">
                                                                    <path id="SvgjsPath1591" d="M 0 138.69C 19.783203125 138.69 36.740234375 174.87 56.5234375 174.87C 76.306640625 174.87 93.263671875 14.069999999999993 113.046875 14.069999999999993C 132.830078125 14.069999999999993 149.787109375 94.46999999999998 169.5703125 94.46999999999998C 189.353515625 94.46999999999998 206.310546875 110.55 226.09375 110.55C 245.876953125 110.55 262.833984375 70.35 282.6171875 70.35C 302.400390625 70.35 319.357421875 168.84 339.140625 168.84" fill="none" fill-opacity="1" stroke="rgba(32,107,196,1)" stroke-opacity="1" stroke-linecap="round" stroke-width="2" stroke-dasharray="0" class="apexcharts-line" index="0" clip-path="url(#gridRectMaskqdnn2s4ri)" pathTo="M 0 138.69C 19.783203125 138.69 36.740234375 174.87 56.5234375 174.87C 76.306640625 174.87 93.263671875 14.069999999999993 113.046875 14.069999999999993C 132.830078125 14.069999999999993 149.787109375 94.46999999999998 169.5703125 94.46999999999998C 189.353515625 94.46999999999998 206.310546875 110.55 226.09375 110.55C 245.876953125 110.55 262.833984375 70.35 282.6171875 70.35C 302.400390625 70.35 319.357421875 168.84 339.140625 168.84" pathFrom="M -1 201 L -1 201 L 56.5234375 201 L 113.046875 201 L 169.5703125 201 L 226.09375 201 L 282.6171875 201 L 339.140625 201" fill-rule="evenodd"></path>
                                                                    <g id="SvgjsG1589" class="apexcharts-series-markers-wrap" data:realIndex="0">
                                                                        <g class="apexcharts-series-markers">
                                                                            <circle id="SvgjsCircle1659" r="0" cx="56.5234375" cy="174.87" class="apexcharts-marker w30xpdtcq no-pointer-events" stroke="#ffffff" fill="#206bc4" fill-opacity="1" stroke-width="2" stroke-opacity="0.9" default-marker-size="0"></circle>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                                <g id="SvgjsG1590" class="apexcharts-datalabels" data:realIndex="0"></g>
                                                            </g>
                                                            <g id="SvgjsG1595" class="apexcharts-grid-borders">
                                                                <line id="SvgjsLine1602" x1="0" y1="0" x2="339.140625" y2="0" stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1607" x1="0" y1="201" x2="339.140625" y2="201" stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt" class="apexcharts-gridline"></line>
                                                                <line id="SvgjsLine1633" x1="0" y1="202" x2="339.140625" y2="202" stroke="#e0e0e0" stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"></line>
                                                            </g>
                                                            <line id="SvgjsLine1654" x1="0" y1="0" x2="339.140625" y2="0" stroke="#b6b6b6" stroke-dasharray="0" stroke-width="1" stroke-linecap="butt" class="apexcharts-ycrosshairs"></line>
                                                            <line id="SvgjsLine1655" x1="0" y1="0" x2="339.140625" y2="0" stroke-dasharray="0" stroke-width="0" stroke-linecap="butt" class="apexcharts-ycrosshairs-hidden"></line>
                                                            <g id="SvgjsG1656" class="apexcharts-yaxis-annotations"></g>
                                                            <g id="SvgjsG1657" class="apexcharts-xaxis-annotations"></g>
                                                            <g id="SvgjsG1658" class="apexcharts-point-annotations"></g>
                                                            <rect id="SvgjsRect1660" width="0" height="0" x="0" y="0" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe" class="apexcharts-zoom-rect"></rect>
                                                            <rect id="SvgjsRect1661" width="0" height="0" x="0" y="0" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe" class="apexcharts-selection-rect"></rect>
                                                        </g>
                                                        <rect id="SvgjsRect1580" width="0" height="0" x="0" y="0" rx="0" ry="0" opacity="1" stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe"></rect>
                                                        <g id="SvgjsG1634" class="apexcharts-yaxis" rel="0" transform="translate(17.859375, 0)">
                                                            <g id="SvgjsG1635" class="apexcharts-yaxis-texts-g">
                                                                <text id="SvgjsText1637" font-family="inherit" x="4" y="11.5" text-anchor="end" dominant-baseline="auto" font-size="11px" font-weight="400" fill="#373d3f" class="apexcharts-text apexcharts-yaxis-label " style="font-family: inherit;">
                                                                    <tspan id="SvgjsTspan1638">500</tspan>
                                                                    <title>500</title>
                                                                </text>
                                                                <text id="SvgjsText1640" font-family="inherit" x="4" y="51.7" text-anchor="end" dominant-baseline="auto" font-size="11px" font-weight="400" fill="#373d3f" class="apexcharts-text apexcharts-yaxis-label " style="font-family: inherit;">
                                                                    <tspan id="SvgjsTspan1641">400</tspan>
                                                                    <title>400</title>
                                                                </text>
                                                                <text id="SvgjsText1643" font-family="inherit" x="4" y="91.9" text-anchor="end" dominant-baseline="auto" font-size="11px" font-weight="400" fill="#373d3f" class="apexcharts-text apexcharts-yaxis-label " style="font-family: inherit;">
                                                                    <tspan id="SvgjsTspan1644">300</tspan>
                                                                    <title>300</title>
                                                                </text>
                                                                <text id="SvgjsText1646" font-family="inherit" x="4" y="132.10000000000002" text-anchor="end" dominant-baseline="auto" font-size="11px" font-weight="400" fill="#373d3f" class="apexcharts-text apexcharts-yaxis-label " style="font-family: inherit;">
                                                                    <tspan id="SvgjsTspan1647">200</tspan>
                                                                    <title>200</title>
                                                                </text>
                                                                <text id="SvgjsText1649" font-family="inherit" x="4" y="172.3" text-anchor="end" dominant-baseline="auto" font-size="11px" font-weight="400" fill="#373d3f" class="apexcharts-text apexcharts-yaxis-label " style="font-family: inherit;">
                                                                    <tspan id="SvgjsTspan1650">100</tspan>
                                                                    <title>100</title>
                                                                </text>
                                                                <text id="SvgjsText1652" font-family="inherit" x="4" y="212.5" text-anchor="end" dominant-baseline="auto" font-size="11px" font-weight="400" fill="#373d3f" class="apexcharts-text apexcharts-yaxis-label " style="font-family: inherit;">
                                                                    <tspan id="SvgjsTspan1653">0</tspan>
                                                                    <title>0</title>
                                                                </text>
                                                            </g>
                                                        </g>
                                                        <g id="SvgjsG1576" class="apexcharts-annotations"></g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <div class="section-title">
                                <h4 class="text-azure mb-0">LAND PLOTS ({{ data.count }})</h4>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="row g-2 align-items-center mb-2">
                                <div class="col-auto ms-auto d-print-none">
                                    <div class="btn-list">
                                        <a href="#" class="btn btn-outline-primary" @click="modal_add_land = !modal_add_land">
                                            Add
                                        </a>

                                        <a href="#" class="btn btn-outline-danger" v-if="btn_manipulation" @click="removeLandsInCollectrions()">
                                            Remove
                                        </a>

                                        <a href="#" class="btn btn-outline-dark d-sm-inline-block" @click="toggleAll()">
                                            Select All
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="card">
                                <div class="table-responsive">
                                    <table class="table table-vcenter card-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Land ID (Coordinate)</th>
                                                <th>Country</th>
                                                <th>Valuation</th>
                                                <th>Select</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in data.data" v-bind:key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td class="text-truncate text-location">
                                                    {{ item.location.bbox.join(', ') }}
                                                </td>
                                                <td>{{ item.areas[0].country }}</td>
                                                <td>${{ item.price ? item.price : 25 }}</td>
                                                <td>
                                                    <input class="form-check-input" @change="unmarkAll()" :value="item._id" v-model="markedRows" type="checkbox">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-blur fade show" id="modal-report" tabindex="-1" v-if="modal_add_land" style="display: block;" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">My land plots </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modal_add_land = !modal_add_land"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="table-responsive">
                                    <table class="table table-vcenter card-table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Address</th>
                                                <th>Unique Code</th>
                                                <th>Purchase Date</th>
                                                <th>Select</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in items_lands.data" v-bind:key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>
                                                    <div class="text-truncate text-location">
                                                        {{ item.location.bbox.join(', ') }}
                                                    </div>
                                                </td>
                                                <td>{{ item._id }}</td>
                                                <td>{{ item.created_at }}</td>
                                                <td>
                                                    <input class="form-check-input" @change="unmarkLands()" :value="item._id" v-model="markedLandRows" type="checkbox">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a href="#" class="btn btn-primary ms-auto" @click="addLandsToCollectrions">
                            Add to collections
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
    el: "#app",
    components: {},
    name: "collections_detail",
    data() {
        return {
            data: [],
            data_table: [],
            modal_add_land: false,
            ntf_data: [],

            allMarked: false,
            markedRows: [],
            btn_manipulation: false,

            landsMarked: false,
            markedLandRows: [],

            items_lands: []
        };
    },
    methods: {
        fetchLandsInCollectrions: function () {
            var key = this.$route.query.key;

            axios
                .get(`/api/collections/lands`, {
                    params: {
                        collection_id: key,
                    }
                })
                .then(r => {
                    if (r.data.error) {
                        this.$swal(r.data.msg);
                    } else {
                        this.data = r.data;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        unmarkAll() {
            this.allMarked = false;
            this.manipulation();
            console.log('markedRows', this.markedRows);
        },
        toggleAll() {
            console.log('markedRows', this.markedRows);
            console.log('allMarked', this.allMarked);

            this.markedRows = !this.allMarked
                ? this.data.data.map(row => row._id)
                : [];
            this.manipulation();
            this.allMarked = !this.allMarked;
        },
        manipulation() {
            if (this.markedRows.length > 0) {
                this.btn_manipulation = true;
            } else {
                this.btn_manipulation = false;
            }
        },

        removeLandsInCollectrions: function () {
            var key = this.$route.query.key;

            if (!this.markedRows || this.markedRows.length < 1) {
                this.$swal("No land has been selected yet!", "", "error");
                return;
            }
            axios
                .post(`/api/collections/remove_lands`, {
                    collection_id: key,
                    items: this.markedRows
                })
                .then(r => {
                    if (r.data.error) {
                        this.$swal(r.data.msg);
                    } else {
                        this.$swal("Successful implementation", "", "success");
                        this.cleanData();
                        this.fetchLandsInCollectrions();
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        unmarkLands() {
            this.landsMarked = false;
            console.log('markedLandRows', this.markedLandRows);
        },

        fetchLandsIndividual: function () {
            axios
                .get(`/api/lands/my_assets`, {
                    params: {
                        type: 'individual',
                    }
                })
                .then(r => {
                    if (r.data.error) {
                        this.$swal(r.data.msg);
                    } else {
                        this.items_lands = r.data;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        addLandsToCollectrions: function () {
            var key = this.$route.query.key;
            if (!this.markedLandRows || this.markedLandRows.length < 1) {
                this.$swal("No land has been selected yet!", "", "error");
                return;
            }
            axios
                .post(`/api/collections/add_lands`, {
                    collection_id: key,
                    items: this.markedLandRows
                })
                .then(r => {
                    if (r.data.error) {
                        this.$swal(r.data.msg);
                    } else {
                        this.$swal("Successful implementation", "", "success");
                        this.cleanData();
                        this.fetchLandsIndividual();
                        this.fetchLandsInCollectrions();
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        backHistory() {
            this.$router.push({
                path: '/assets',
                query: { redirected: "detail" }
            });
        },

        cleanData() {
            this.allMarked = false;
            this.markedRows = [];
            this.btn_manipulation = false;

            this.landsMarked = false;
            this.markedLandRows = [];
        }
    },
    mounted: function () {
        this.fetchLandsInCollectrions();
    },
    computed: {
        ...mapState("collections", ["itemCollection"]),
    },

    watch: {
        "$route.query.key": function () {
            this.cleanData();
            this.fetchLandsInCollectrions();
        },
        "modal_add_land": function () {
            if (this.modal_add_land === true) {
                this.fetchLandsIndividual();
            }
        }
    },
};
</script>
