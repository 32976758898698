<style scoped>
.card {
  overflow: hidden;
}

.icon-status > svg {
  width: 120px;
  height: 120px;
}

.mw-250 {
  max-width: 250px;
}

/*.list-group-item:active, .list-group-item:focus, .list-group-item:hover {
        background-color: rgba(var(--tblr-muted-rgb), .04);
    }*/
.list-group-item:hover .align-items-center {
  background-color: rgba(var(--tblr-muted-rgb), 0.04);
}

.list-plots {
  display: block;
  overflow: hidden;
}

.hash-tag {
  max-width: 250px;
  display: inline-block;
  vertical-align: bottom;
}

a {
  cursor: pointer;
}

.list-plots {
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
}

.h-ratio-100 {
  --tblr-aspect-ratio: 100%;
  height: 100%;
}

.tr-active {
  background-color: #f7e06e;
}

.list-scroll-350 {
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
}

.hightlight-collection {
  background-color: #f7e06e;
}
.text-reset {
  cursor: pointer;
}
</style>
<template>
  <div class="page-wrapper">
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <h2 class="page-title">
              My Assets
              <a
                class="btn btn-default btn-xs pull-right mx-3"
                @click="refreshMyAssets"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-reload"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747"
                  ></path>
                  <path d="M20 4v5h-5"></path>
                </svg>
                Refresh</a
              >
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="card">
          <div class="row g-0">
            <div class="col-12 col-lg-4 d-md-block border-end">
              <div class="col-12">
                <div class="card-header">
                  <h3 class="card-title strong">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-packages"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z"></path>
                      <path d="M2 13.5v5.5l5 3"></path>
                      <path d="M7 16.545l5 -3.03"></path>
                      <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z"></path>
                      <path d="M12 19l5 3"></path>
                      <path d="M17 16.5l5 -3"></path>
                      <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5"></path>
                      <path d="M7 5.03v5.455"></path>
                      <path d="M12 8l5 -3"></path>
                    </svg>
                    Land Collections
                  </h3>
                </div>
                <div
                  class="list-group list-group-flush list-group-hoverable list-scroll-350"
                >
                  <div
                    class="list-group-item p-0"
                    v-for="(item, index) in collections_items"
                    v-bind:key="index"
                  >
                    <div class="row align-items-center p-2 m-0">
                      <div class="col-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-box-multiple"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <rect
                            x="7"
                            y="3"
                            width="14"
                            height="14"
                            rx="2"
                          ></rect>
                          <path
                            d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2"
                          ></path>
                        </svg>
                      </div>
                      <div class="col">
                        <span
                          @click="openLandsInCollectionTitle(item._id)"
                          class="text-reset"
                          >{{ item.name }} ({{ item.total }})</span
                        >
                        <a
                          href="#"
                          class="list-group-item-actions ms-1"
                          @click="showModalSetName(item)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-pencil"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path
                              d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"
                            ></path>
                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                          </svg>
                        </a>

                        <a
                          v-if="item.total == 0"
                          href="#"
                          class="list-group-item-actions ms-1"
                          @click="showModalRemove(item)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-x"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path d="M18 6l-12 12"></path>
                            <path d="M6 6l12 12"></path>
                          </svg>
                        </a>
                      </div>
                      <div class="col-auto">
                        <template v-if="!item.state">
                          <a
                            v-if="item.total > 0 && item.allow_mint"
                            class="btn btn-default btn-sm"
                            @click="doMintNFT(item)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-currency-ethereum"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M6 12l6 -9l6 9l-6 9z"></path>
                              <path d="M6 12l6 -3l6 3l-6 2z"></path>
                            </svg>
                            Mint NFT
                          </a>
                        </template>
                        <template v-if="item.state == 1">
                          <a
                            class="btn btn-danger btn-sm"
                            @click="fetchMintingStatus(item)"
                          >
                            Minting ...
                          </a>
                        </template>
                        <template v-if="item.state == 2">
                          <a
                            class="btn btn-success btn-sm"
                            @click="doViewNFT(item)"
                          >
                            NFT
                          </a>
                        </template>
                      </div>
                      <div class="col-auto">
                        <a @click="openLandsInCollection(item._id)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            v-if="collection_active == item._id"
                            class="icon icon-tabler icon-tabler-chevron-down"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            v-else
                            class="icon icon-tabler icon-tabler-chevron-left"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <polyline points="15 6 9 12 15 18"></polyline>
                          </svg>
                        </a>
                      </div>
                    </div>

                    <div
                      class="list-plots"
                      v-if="collection_active == item._id"
                    >
                      <div class="col text-end mt-1 mx-1">
                        <a
                          v-if="!item.state"
                          href="#"
                          class="btn btn-default btn-sm"
                          :class="{ disabled: !markedLandRows.length }"
                          @click="showModalMoveLand()"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-hand-move"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"></path>
                            <path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5"></path>
                            <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"></path>
                            <path
                              d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"
                            ></path>
                            <path
                              d="M2.541 5.594a13.487 13.487 0 0 1 2.46 -1.427"
                            ></path>
                            <path
                              d="M14 3.458c1.32 .354 2.558 .902 3.685 1.612"
                            ></path>
                          </svg>
                          Remove or transfer to another collection
                        </a>
                      </div>
                      <table
                        class="table card-table table-responsive"
                        v-if="collections_lands_items.length"
                      >
                        <tbody>
                          <tr
                            v-for="(land, index) in collections_lands_items"
                            v-bind:key="index"
                            :class="{ 'hightlight-collection': land.active }"
                          >
                            <td class="text-nowrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-drag-drop-2"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <rect
                                  x="8"
                                  y="8"
                                  width="12"
                                  height="12"
                                  rx="2"
                                ></rect>
                                <line x1="4" y1="4" x2="4" y2="4.01"></line>
                                <line x1="8" y1="4" x2="8" y2="4.01"></line>
                                <line x1="12" y1="4" x2="12" y2="4.01"></line>
                                <line x1="16" y1="4" x2="16" y2="4.01"></line>
                                <line x1="4" y1="8" x2="4" y2="8.01"></line>
                                <line x1="4" y1="12" x2="4" y2="12.01"></line>
                                <line x1="4" y1="16" x2="4" y2="16.01"></line>
                              </svg>
                            </td>
                            <td>
                              <div class="text-truncate hash-tag">
                                {{ land.location.bbox.join(", ") }}
                              </div>
                            </td>
                            <td class="w-1">
                              <input
                                type="checkbox"
                                class="form-check-input m-0 align-middle"
                                @change="unmarkLands(land)"
                                :value="land._id"
                                v-model="markedLandRows"
                                checked=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else class="text-center py-3">
                        <span class="text-mute"><i>Collection empty</i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="hr mb-0 mt-4"></div>
                <div class="card-header">
                  <h3 class="card-title strong">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-box"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <polyline
                        points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"
                      ></polyline>
                      <line x1="12" y1="12" x2="20" y2="7.5"></line>
                      <line x1="12" y1="12" x2="12" y2="21"></line>
                      <line x1="12" y1="12" x2="4" y2="7.5"></line>
                    </svg>
                    Individual Land Plots
                  </h3>
                </div>
                <div class="list-group list-group-flush list-group-hoverable">
                  <div class="list-plots">
                    <div class="col py-2 px-2 text-end">
                      <a
                        class="btn btn-default btn-sm ms-1"
                        :class="{ disabled: !markedLandPlotRows.length }"
                        @click="showModalMoveToCollection()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-hand-move"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"></path>
                          <path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5"></path>
                          <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"></path>
                          <path
                            d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"
                          ></path>
                          <path
                            d="M2.541 5.594a13.487 13.487 0 0 1 2.46 -1.427"
                          ></path>
                          <path
                            d="M14 3.458c1.32 .354 2.558 .902 3.685 1.612"
                          ></path>
                        </svg>
                        Add to collection
                      </a>
                      <a
                        class="btn btn-default btn-sm ms-1"
                        :class="{ disabled: !markedLandPlotRows.length }"
                        @click="showModalCreateCollection()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-circle-plus"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <circle cx="12" cy="12" r="9"></circle>
                          <line x1="9" y1="12" x2="15" y2="12"></line>
                          <line x1="12" y1="9" x2="12" y2="15"></line>
                        </svg>
                        Create new collection
                      </a>
                    </div>

                    <div class="list-scroll-350">
                      <table class="table card-table table-vcenter freeland">
                        <tbody>
                          <tr
                            v-for="(land, index) in lands_items"
                            v-bind:key="index"
                            :class="{ 'tr-active': land.active }"
                          >
                            <td class="text-nowrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-drag-drop-2"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <rect
                                  x="8"
                                  y="8"
                                  width="12"
                                  height="12"
                                  rx="2"
                                ></rect>
                                <line x1="4" y1="4" x2="4" y2="4.01"></line>
                                <line x1="8" y1="4" x2="8" y2="4.01"></line>
                                <line x1="12" y1="4" x2="12" y2="4.01"></line>
                                <line x1="16" y1="4" x2="16" y2="4.01"></line>
                                <line x1="4" y1="8" x2="4" y2="8.01"></line>
                                <line x1="4" y1="12" x2="4" y2="12.01"></line>
                                <line x1="4" y1="16" x2="4" y2="16.01"></line>
                              </svg>
                            </td>
                            <td class="w-100">
                              <div class="text-truncate hash-tag">
                                {{ land.location.bbox.join(", ") }}
                              </div>
                            </td>
                            <td class="w-1">
                              <input
                                type="checkbox"
                                class="form-check-input m-0 align-middle"
                                @change="unmarkLands(land)"
                                :value="land._id"
                                v-model="markedLandPlotRows"
                                checked=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-8">
              <div class="card-body">
                <div class="mapbox-row">
                  <div class="ratio h-ratio-100">
                    <div>
                      <div id="map-assets" class="w-100 h-100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal modal-blur fade show"
    id="modal-collection"
    style="display: block"
    tabindex="-1"
    aria-modal="true"
    role="dialog"
    v-if="modalEditCreateCollections"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="modalEditCreate.type == 'create'">
            Create new collection
          </h5>
          <h5 class="modal-title" v-else>Rename land collection</h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="modalEditCreateCollections = !modalEditCreateCollections"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label">Collection name</label>
            <input
              type="text"
              class="form-control"
              v-model="modalEditCreate.name"
              :class="{
                'is-invalid': !modalEditCreate.name && collection_name_error,
              }"
              v-on:keyup.enter="onSubmit"
              placeholder="Enter collection name"
            />
            <div class="invalid-feedback">
              Collection name has not been entered.
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="modalEditCreate.type == 'create'"
            type="button"
            class="btn btn-primary"
            @click="onSubmit()"
          >
            Create
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click="onSubmit()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal modal-blur fade show"
    id="modal-moveland"
    style="display: block"
    tabindex="-1"
    aria-modal="true"
    role="dialog"
    v-if="modalMoveLand"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Move land plot to another</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="modalMoveLand = !modalMoveLand"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-check">
              <input
                type="radio"
                v-model="move_type"
                class="form-check-input"
                name="form-stars"
                value="individual"
                checked=""
              />
              <span class="form-check-label">Remove from land collection</span>
            </label>
            <label class="form-check">
              <input
                type="radio"
                v-model="move_type"
                class="form-check-input"
                name="form-stars"
                value="collection"
              />
              <span class="form-check-label"
                >Transfer to another land collection</span
              >
            </label>
            <div v-if="move_type === 'collection'">
              <select
                class="form-select"
                :class="{
                  'is-invalid':
                    !collection_selected && collection_selected_error,
                }"
                v-model="collection_selected"
              >
                <optgroup label="Add to land collection"></optgroup>
                <option
                  v-for="(clt, index) in collection_ar"
                  v-bind:key="index"
                  :value="clt.id"
                >
                  {{ clt.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                No collections have been selected yet.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="onMoveLands">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal modal-blur fade show"
    id="move-to-collection"
    style="display: block"
    tabindex="-1"
    aria-modal="true"
    role="dialog"
    v-if="modalMoveToCollection"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add to collection</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="modalMoveToCollection = !modalMoveToCollection"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <div>
              <label class="form-label">Select land collection</label>
              <select
                class="form-select"
                :class="{
                  'is-invalid':
                    !collection_selected && collection_selected_error,
                }"
                v-model="collection_selected"
              >
                <optgroup label="Add to land collection"></optgroup>
                <option
                  v-for="(clt, index) in collection_ar"
                  v-bind:key="index"
                  :value="clt.id"
                >
                  {{ clt.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                No collections have been selected yet.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="onMoveToCollection"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Grid, GRID_CLICK_EVENT } from "./grid";
import { mapState } from "vuex";
import * as turf from "@turf/turf";
let map = undefined;
var selectedCells = [];
var userLandItems = [];

export default {
  el: "#app",
  components: {},
  name: "my_assets",

  data() {
    return {
      collection_active: false,
      collections_items: [],
      collections_lands_items: [],
      lands_items: [],

      landsMarked: false,
      markedLandRows: [],

      modalEditCreateCollections: false,
      modalEditCreate: {
        id: 0,
        name: "",
        type: "",
      },

      landPlotMarked: false,
      markedLandPlotRows: [],
      modalMoveLand: false,
      move_type: "individual",

      collection_selected: undefined,
      collection_ar: [],
      collection_selected_error: false,
      collection_is_open: "",
      collection_name_error: false,

      access_token_mapbox:
        "pk.eyJ1IjoiZGVpcGFyb3VzIiwiYSI6ImNsY29pYWhicDFjeGczbm84MzA0Ynk1czkifQ.iVsD87dg6-t4eFzrUAkCeQ",
      modalMoveToCollection: false,
      modalCreateCollection: false,

      modal_mint: false,
      ntf_data: [],
      contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
      targetNetworkId: process.env.VUE_APP_NETWORK_ID,
      ExplorerURL: process.env.VUE_APP_EXPLORER_URL,
      NETWORK: process.env.VUE_APP_NETWORK,

      connected: false,
      contractResult: [],
      networkId: "",
      address: "",
      balance: "",
    };
  },
  methods: {
    refreshMyAssets: function () {
      let self = this;
      this.$swal(
        "Please wait for your assets to synchronize with the blockchain network.",
        "",
        "success"
      );
      axios.post("/api/minting/refresh/my_assets", {}).then((r) => {
        console.log(r);
        if (r.data && r.data.error) {
          this.$swal(r.data.msg, "", "error");
        } else {
          self.fetchCollections();
        }
      });
    },

    silentFetchMinting: function (item) {
      let self = this;
      axios
        .post(`/api/minting/request/mint_status`, {
          collectionID: item._id,
        })
        .then((r) => {
          console.log("Fetch minting status with result", r.data);
          if (r.data && r.data.state) {
            let state = r.data.state;
            if (state == 2) {
              // SUCESS
              item.state = 2;
              self.fetchCollections();
            }
            if (state == 0) {
              // FAIL
              item.state = 0;
            }
            if (state == 1) {
              setTimeout(() => {
                self.silentFetchMinting(item);
              }, 1000);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    fetchMintingStatus: function (item) {
      axios
        .post(`/api/minting/request/mint_status`, {
          collectionID: item._id,
        })
        .then((r) => {
          if (r.data && r.data.state == 2) {
            item.state = 2;
            this.$swal("NFT mint process completed", "", "success");
          } else {
            if (r.data.error == true) {
              item.state = 0;
              this.$swal(r.data.msg, "", "error");
            } else {
              this.$swal
                .fire({
                  title: "NFT minting in progress…",
                  showDenyButton: true,
                  showCancelButton: false,
                  confirmButtonText: "View transaction",
                  denyButtonText: `Cancel`,
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.open(process.env.VUE_APP_ETHERSCAN + item.txid);
                  }
                });
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    doViewNFT: function (item) {
      this.$swal
        .fire({
          title: "Your Land Collection NFT has been minted.",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "View on OpenSea",
          denyButtonText: `View Transaction`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.open(process.env.VUE_APP_OPENSEA + item.uriid);
          }
          if (result.isDenied) {
            window.open(process.env.VUE_APP_ETHERSCAN + item.txid);
          }
        });
    },
    doMintNFT: function (item) {
      //
      let self = this;
      let collectionID = item._id;
      //console.log(item);

      this.$swal
        .fire({
          title:
            "By proceeding, you will convert this Land Collection into an NFT on the ETH Network. Please note that this action cannot be undone. Once minted, you cannot add or remove land plots from this NFT land collection until the release of our marketplace.",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Proceed",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: "Please wait ...",
              text: " ",
              icon: "warning",
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
            }); //("Please wait ...", " ", "success");
            //self.fetchCollections();
            item.state = 3;
            this.$store.dispatch("minting/request", {
              collectionID,
              onReject: () => {
                item.state = 0;
              },
              callback: function (txid) {
                item.state = 4;
                console.log("Minting completed", txid);
                setTimeout(() => {
                  self.fetchCollections();
                  self.silentFetchMinting(item);
                }, 5000);
              },
            });
          }
        });
    },
    fetchCollections: function () {
      axios
        .get(`/api/collections/fetch`, {})
        .then((r) => {
          if (r.data.error) {
            this.$swal(r.data.msg);
          } else {
            this.collections_items = r.data.data;
            map.addSource("ready-cells-selected", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [],
              },
            });

            map.addLayer({
              id: "ready-cells-selected",
              source: "ready-cells-selected",
              type: "fill",
              paint: {
                "fill-color": "#ffff00",
                "fill-opacity": 0.4,
              },
            });
            map.addSource("ready-cells-selectedLine", {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [],
              },
            });

            map.addLayer({
              id: "ready-cells-selectedLine",
              source: "ready-cells-selectedLine",
              type: "line",
              paint: {
                "line-color": "purple",
                "line-width": 2,
                "line-opacity": 0.6,
              },
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    fetchLandsInCollection: function (ID) {
      this.collection_is_open = ID;
      axios
        .get(`/api/collections/lands`, {
          params: {
            collection_id: ID,
          },
        })
        .then((r) => {
          //let data = r.data;
          //console.log(data);

          if (r.data.error) {
            this.$swal(r.data.msg);
          } else {
            this.collections_lands_items = r.data.data;
            userLandItems = userLandItems.concat(r.data.data);
            var readyItemsSelected = [];
            var readyItemsSelectedLine = [];
            var union = undefined;
            var points = [];
            r.data.data.forEach((item2) => {
              let bbox = item2.location.bbox;
              if (bbox) {
                let coordinates = item2.location.coordinates;
                const cell = {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    bbox,
                    coordinates,
                  },
                };
                readyItemsSelected.push(cell);
                readyItemsSelectedLine.push({
                  type: "Feature",
                  properties: {
                    fill: "#0f0",
                  },
                  geometry: {
                    type: "Polygon",
                    coordinates: coordinates,
                  },
                });
                points.push(item2.center.coordinates);
              }
            });
            for (var i = 0; i < readyItemsSelectedLine.length; i++) {
              if (i == 0) {
                continue;
              }
              if (i == 1) {
                union = turf.union(
                  readyItemsSelectedLine[0],
                  readyItemsSelectedLine[1]
                );
              } else {
                union = turf.union(union, readyItemsSelectedLine[i]);
              }
            }
            // console.log(readyItemsSelected, "readyItemsSelected");

            const source = map.getSource("ready-cells-selected");
            source.setData({
              type: "FeatureCollection",
              features: readyItemsSelected,
            });

            const sourceLine = map.getSource("ready-cells-selectedLine");
            if (r.data.data.length > 0) {
              if (union == undefined) {
                union = readyItemsSelectedLine[0];
              }
              sourceLine.setData(union);
              const pointFeatures = turf.featureCollection(
                points.map((point) => turf.point(point))
              );

              const center = turf.centroid(pointFeatures);
              console.log(center, "centercentercenter");
              map.flyTo({
                center: r.data.data[0].center.coordinates,
                zoom: 13,
              });
            }

            //this.updateMapMark();
          }
          /*
                    //checkbox land of the collection in the list
                    this.markedLandRows = this.collections_lands_items.map(e => {
                        return e._id;
                                                                        this.collections_lands_items.forEach(item => {
                        let bbox = item.location.bbox;
                        if (bbox) {
                            let coordinates = item.location.coordinates;
                            const cell = {
                                type: "Feature",
                                geometry: {
                                    type: "Polygon",
                                    bbox,
                                    coordinates,
                                                                                                        const cellIndex = readyItems.findIndex(x => x.geometry.bbox.toString() === bbox.toString());
                            if (cellIndex === -1) readyItems.push(cell);
                                                                                                        if (map) {
                        map.setZoom(13)
                        map.flyTo({
                            center: this.collections_lands_items[0].location.coordinates[0][0]
                                                                                                        const source = map.getSource("ready-cells");
                    source.setData({
                        type: "FeatureCollection",
                        features: readyItems,
                                                */
        })
        .catch((e) => {
          console.log(e);
        });
    },

    openLandsInCollection: function (ID) {
      this.cleanLandsOnMap();

      if (this.collection_active === ID) {
        this.collection_active = false;
      } else {
        this.collection_active = ID;
        this.fetchLandsInCollection(ID);
      }
    },

    openLandsInCollectionTitle: function (ID) {
      this.cleanLandsOnMap();
      this.collection_active = ID;
      this.fetchLandsInCollection(ID);
    },

    cleanLandsOnMap() {
      selectedCells = [];
      this.markedLandPlotRows = [];
      this.markedLandRows = [];
      this.collections_lands_items = [];

      const source = map.getSource("ready-cells");
      source.setData({
        type: "FeatureCollection",
        features: selectedCells,
      });
    },

    unmarkLands(item) {
      console.log("item", item);

      this.landsMarked = false;

      this.showLandOnMap(item);
    },

    fetchLandsPlots: function () {
      axios
        .get(`/api/lands/my_assets`, {
          params: {
            type: "individual",
            // mint_status: true
          },
        })
        .then((r) => {
          //let data = r.data;
          //console.log(data);

          if (r.data.error) {
            this.$swal(r.data.msg);
          } else {
            this.lands_items = r.data.data.map((e) => {
              e.active = false;
              return e;
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    showModalRemove(item) {
      this.$swal
        .fire({
          title: `Are you sure you want to delete this land collection?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Yes",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            // Do remove land collection

            axios
              .post(`/api/collections/remove`, {
                id: item._id,
              })
              .then((res) => {
                if (res.data && !res.data.error) {
                  this.fetchCollections();
                } else {
                  this.$swal(res.data.msg, "Sorry", "error");
                }
              })
              .catch((e) => {
                console.log(e);
                this.$swal("Please try again, network error", "Sorry", "error");
              });
          }
        });
    },
    showModalSetName(item) {
      this.modalEditCreate = {
        id: item._id,
        name: item.name,
        type: "edit",
      };
      this.modalEditCreateCollections = !this.modalEditCreateCollections;
    },

    onSubmit() {
      if (!this.modalEditCreate.name) {
        this.collection_name_error = true;
        return;
      }

      if (this.modalEditCreate.type === "create") {
        this.onCreateCollection();
      }
      if (this.modalEditCreate.type === "edit") {
        this.onEditCollectionName();
      }
    },

    onEditCollectionName() {
      axios
        .post(`/api/collections/setname`, {
          id: this.modalEditCreate.id,
          name: this.modalEditCreate.name,
        })
        .then((res) => {
          if (res.data && !res.data.error) {
            //this.$swal("Success", "", "success");
            this.fetchCollections();
            this.modalEditCreateCollections = !this.modalEditCreateCollections;
          } else {
            this.$swal(res.data.msg, "Sorry", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal("Please try again, network error", "Sorry", "error");
        });
    },

    showModalMoveLand() {
      console.log("markedLandRows", this.markedLandRows);

      this.modalMoveLand = !this.modalMoveLand;
      this.getMyCollection("except");
    },

    showModalMoveToCollection() {
      this.move_type = "collection";
      this.modalMoveToCollection = !this.modalMoveToCollection;
      this.getMyCollection();
    },

    getMyCollection(except) {
      let self = this;
      let param = {};
      if (except) {
        param = {
          params: {
            except: this.collection_active,
          },
        };
      }

      axios.get(`/api/collections/items`, param).then((response) => {
        if (response.data && !response.data.error) {
          self.collection_ar = response.data.items.filter((e) => {
            return !e.state;
          });
        } else {
          this.$swal(response.data.msg, "Sorry", "error");
        }
      });
    },

    onMoveLands() {
      if (this.move_type === "collection" && !this.collection_selected) {
        this.collection_selected_error = true;
        return;
      }

      axios
        .post(`/api/collections/move_land`, {
          collection_id: this.collection_selected,
          type: this.move_type,
          lands: this.markedLandRows,
        })
        .then((res) => {
          if (res.data && !res.data.error) {
            this.fetchLandsInCollection(this.collection_is_open);
            this.modalMoveLand = !this.modalMoveLand;
            this.cleanDataMove();
            this.fetchCollections();
            if (this.move_type === "individual") {
              this.fetchLandsPlots();
            }
          } else {
            this.$swal(res.data.msg, "Sorry", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal("Please try again, network error", "Sorry", "error");
        });
    },

    onMoveToCollection() {
      if (!this.collection_selected) {
        this.collection_selected_error = true;
        return;
      }

      axios
        .post(`/api/collections/move_land`, {
          collection_id: this.collection_selected,
          type: "collection",
          lands: this.markedLandPlotRows,
        })
        .then((res) => {
          if (res.data && !res.data.error) {
            this.fetchLandsInCollection(this.collection_selected);
            this.markedLandPlotRows = !this.markedLandPlotRows;
            this.cleanDataMove();
            this.fetchLandsPlots();
            this.fetchCollections();
          } else {
            this.$swal(res.data.msg, "Sorry", "error");
          }
          this.modalMoveToCollection = !this.modalMoveToCollection;
        })
        .catch((e) => {
          console.log(e);
          this.$swal("Please try again, network error", "Sorry", "error");
        });
    },

    cleanDataMove() {
      this.markedLandRows = [];
      this.move_type = "individual";
      this.collection_selected = undefined;
      this.collection_selected_error = false;
      this.markedLandPlotRows = [];
    },

    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (p) => {
            if (map) {
              map.setZoom(13);
              map.flyTo({
                center: [p.coords.longitude, p.coords.latitude],
              });
            }
          },
          (e) => {
            //map.setZoom(3)
            console.log(e);
          },
          { timeout: 10000 }
        );
      } else {
        //Geolocation is not supported by this browser
        console.log("Can not get location");
        //map.setZoom(3)
      }
    },

    showLandOnMap(item) {
      const selectedCellsId = "ready-cells";
      let bbox = item.location.bbox;

      console.log("bbox", bbox);

      const cellIndex = selectedCells.findIndex(
        (x) => x.geometry.bbox.toString() === bbox.toString()
      );
      if (cellIndex === -1) {
        const coordinates = item.location.coordinates;
        const cell = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            bbox,
            coordinates,
          },
          areas: undefined,
        };
        console.log("Select land item", cell);
        selectedCells.push(cell);
      } else {
        selectedCells.splice(cellIndex, 1);
      }

      const source = map.getSource(selectedCellsId);
      source.setData({
        type: "FeatureCollection",
        features: selectedCells,
      });

      if (map) {
        map.setZoom(13);
        map.flyTo({
          center: item.location.coordinates[0][0],
        });
      }
    },

    showModalCreateCollection() {
      this.modalEditCreate = {
        id: "",
        name: "",
        type: "create",
      };
      this.modalEditCreateCollections = !this.modalEditCreateCollections;
    },

    onCreateCollection() {
      axios
        .post(`/api/collection/create_and_move`, {
          name: this.modalEditCreate.name,
          lands: this.markedLandPlotRows,
        })
        .then((res) => {
          if (res.data && !res.data.error) {
            this.fetchLandsPlots();
            this.fetchCollections();
            this.modalEditCreateCollections = !this.modalEditCreateCollections;
            this.cleanDataMove();
          } else {
            this.$swal(res.data.msg, "Sorry", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal("Please try again, network error", "Sorry", "error");
        });
    },

    doMintLand(item) {
      let self = this;
      if (!this.connected) {
        this.connect();
      }
      this.$swal
        .fire({
          title: "Do you want to mint it ?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Confirm",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          if (!result.isConfirmed) {
            return;
          }

          axios
            .post(`/api/lands/mint`, {
              id: item._id,
            })
            .then((response) => {
              if (response.data && !response.data.error) {
                self.ntf_data = response.data;
                self.callContract();
              } else {
                this.$swal(response.data.msg, "Sorry", "error");
              }
            })
            .catch((e) => {
              console.log(e);
              this.$swal("Please try again, network error", "Sorry", "error");
            });
        });
    },

    doUpdateMintStatus(item) {
      axios
        .post(`/api/mint/status`, {
          tokenId: parseInt(item.events.Transfer.returnValues.tokenId),
          transaction_hash: item.transactionHash,
          from: item.from,
          to: item.to,
          status: item.status,
        })
        .then((response) => {
          if (response.data && !response.data.error) {
            console.log(response.data);
            // this.modal_mint = true;
            this.fetchLands();
          } else {
            this.$swal(response.data.msg, "Sorry", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal("Please try again, network error", "Sorry", "error");
        });
    },

    onDetailColletions: function (item) {
      this.$store.dispatch("collections/selectCollection", {
        collection: item,
      });
      this.$router.push(`/assets/collections?key=` + item._id);
    },

    initMapbox() {
      let self = this;
      // @formatter:off
      // eslint-disable-next-line no-undef
      mapboxgl.accessToken = this.access_token_mapbox;
      // eslint-disable-next-line no-unused-vars, no-undef
      map = new mapboxgl.Map({
        container: "map-assets",
        style: "mapbox://styles/mapbox/streets-v12",
        zoom: 1.5,
        center: [103.7302585, 25.4262305],
      });
      // eslint-disable-next-line no-undef
      const geocoder = new MapboxGeocoder({
        // Initialize the geocoder
        // eslint-disable-next-line no-undef
        accessToken: mapboxgl.accessToken, // Set the access token
        // eslint-disable-next-line no-undef
        mapboxgl: mapboxgl, // Set the mapbox-gl instance
        marker: false, // Do not use the default marker style
      });

      map.addControl(geocoder);

      const grid1 = new Grid({
        gridWidth: 0.4,
        gridHeight: 0.4,
        minZoom: 11,
        maxZoom: 19,
        units: "kilometers",
        paint: {
          "line-opacity": 0.2,
        },
      });
      map.addControl(grid1);

      map.on("moveend", () => {
        //this.fetchLandsPlots();
      });

      map["boxZoom"].disable();

      map.on("load", async () => {
        const selectedCellsId = "ready-cells";
        //self.fetchLandsPlots();

        map.addSource(selectedCellsId, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: selectedCells,
          },
        });
        map.addLayer({
          id: selectedCellsId,
          source: selectedCellsId,
          type: "fill",
          paint: {
            "fill-color": "#0000ff",
            "fill-opacity": 0.6,
          },
        });

        /* layer for touch cell id */
        map.addSource("touch-cell-id", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });
        map.addLayer({
          id: "touch-cell-id",
          source: "touch-cell-id",
          type: "fill",
          paint: {
            "fill-color": "#f7e06e",
            "fill-opacity": 0.3,
          },
        });

        map.addSource("myplot-cells", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });
        map.addLayer({
          id: "myplot-cells",
          source: "myplot-cells",
          type: "fill",
          paint: {
            "fill-color": "#000000",
            "fill-opacity": 0.2,
          },
        });

        self.showAllMyLand();

        // Catch event when click to my land on map
        map.on(GRID_CLICK_EVENT, (event) => {
          const bbox = event.bbox;
          // Kiem tra xem co phai la ele da co ng mua chua
          const found = userLandItems.findIndex(
            (x) => x.location.bbox.toString() === bbox.toString()
          );
          if (found !== -1) {
            let foundItem = userLandItems[found].location.bbox;

            // Find in in vidual items
            let total = self.lands_items.filter((e) => {
              return e.location.bbox.toString() == foundItem.toString();
            }).length;
            self.lands_items = self.lands_items.map((e, index) => {
              e.active = e.location.bbox.toString() == bbox.toString();

              if (e.active === true) {
                console.log("to index", index);
                this.scrollTableCenter(index);
              }
              // if (e.location.bbox.toString() == bbox.toString()) {
              //     var line = +document.querySelector('#line').value;
              //     var rows = table.querySelectorAll('tr');
              //
              //     rows.forEach(row => row.classList.remove('active'))
              //     rows[line].classList.add('active');
              //     rows[line].scrollIntoView({
              //         behavior: 'smooth',
              //         block: 'center'
              //     });
              // }

              return e;
            });
            map.getSource("touch-cell-id").setData({
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    bbox,
                    coordinates: userLandItems[found].location.coordinates,
                  },
                  areas: undefined,
                },
              ],
            });

            if (total) {
              this.collections_lands_items = this.collections_lands_items.map(
                (e) => {
                  e.active = false;
                  return e;
                }
              );
              return;
            } else {
              console.log(foundItem, "Request to server to get information");
              // Get land collection and item inside collection
              axios
                .post(`/api/lands/my_assets/collection`, {
                  item: foundItem,
                })
                .then((response) => {
                  console.log(response.data);
                  if (!response.data.error && response.data.collection_id) {
                    let foundCollectionID = response.data.collection_id;
                    //self.collections_items.forEach

                    self.collection_active = foundCollectionID;

                    axios
                      .get(`/api/collections/lands`, {
                        params: {
                          collection_id: foundCollectionID,
                        },
                      })
                      .then((r) => {
                        if (!r.data.error) {
                          this.collections_lands_items = r.data.data.map(
                            (e) => {
                              console.log(e);
                              e.active =
                                e.location.bbox.toString() ==
                                foundItem.toString();
                              return e;
                            }
                          );

                          var table = document.querySelector(
                            ".hightlight-collection"
                          );
                          //var rows = table.querySelectorAll('td');
                          table.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                })
                .catch((e) => {
                  console.log(e);
                  this.$swal(
                    "Please try again, network error",
                    "Sorry",
                    "error"
                  );
                });
            }
          } else {
            self.lands_items = self.lands_items.map((e) => {
              e.active = false;
              return e;
            });
            map.getSource("touch-cell-id").setData({
              type: "FeatureCollection",
              features: [],
            });
          }
        });
      });
    },

    showAllMyLand: function () {
      axios
        .get(`/api/lands/my_assets`, {
          params: {
            type: "all",
          },
        })
        .then((r) => {
          //let data = r.data;
          //console.log(data);
          userLandItems = r.data.data;
          this.updateMapMark();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateMapMark: function () {
      map.getSource("myplot-cells").setData({
        type: "FeatureCollection",
        features: userLandItems.map((item) => {
          //console.log(item.location)
          const bbox = item.location.bbox;
          const coordinates = item.location.coordinates;
          return {
            type: "Feature",
            geometry: {
              type: "Polygon",
              bbox,
              coordinates,
            },
            areas: undefined,
          };
        }),
      });
    },

    scrollTableCenter: function (line) {
      var table = document.querySelector(".freeland");

      var rows = table.querySelectorAll("tr");

      rows[line].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
  computed: {
    ...mapState("minting", ["error", "success"]),
  },
  watch: {
    error: function (value) {
      this.$swal(value.msg, value.title, "error");
      this.fetchCollections();
    },
    success: function (value) {
      //this.fetchCollections();
      //this.$swal(value.msg, value.title, "success")
      this.$swal.fire({
        title: value.title,
        text: value.msg,
        icon: !value.hide ? "success" : "warning",
        showConfirmButton: !value.hide ? true : false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
  },
  mounted: function () {
    let self = this;
    setTimeout(() => {
      self.getCurrentLocation();
    }, 100);

    this.fetchLandsPlots();
    this.fetchCollections();

    this.initMapbox();

    //this.fetchAll()
  },
};
</script>
