<style scoped>
.price-ship {}
</style>
<template>
    <div :class="'body-ship ' + item.type">
        <RouterLink :to="'/market/' + item._id" v-if="item.cache">
            <div class="header-ship">
                <div class="header-title" :class="`xtext-class-${item.cache.element}`">{{ item.name }}</div>
                <div :class="'header-type r-' + item.cache.rarity">
                    <span class="ship-class mx-2" :class="`ship-class-${item.cache.element}`">
                    </span>
                    <template v-if="item.cache.rarity == 0">Common</template>
                    <template v-if="item.cache.rarity == 1">Rare</template>
                    <template v-if="item.cache.rarity == 2">Epic</template>
                    <template v-if="item.cache.rarity == 3">Legendary</template>
                </div>
            </div>
            <div class="image-ship">
                <img :src="getImage(item.cache.image)" />
                <span></span>
            </div>
            <div class="footer-ship">
                <img src="../../static/images/logo-list-item.png" /> {{ priceRound(item.price) }} USDC
            </div>
        </RouterLink>
    </div>
</template>

<script>
export default {
    el: "#app",
    components: {},
    name: "my_assets",
    props: ['item'],
    data() {
        return {

        };
    },
    methods: {
        priceRound(p) {
            if (!p) return "";
            return p.toFixed(0)
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },
    },
    mounted: function () {
    },

    watch: {
    },
};
</script>
