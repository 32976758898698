<style scoped>
.text-red {
    color: red;
}

.disableEarning {
    opacity: 0.2;
}
</style>

<template>
    <div class="container-xl">
        <div style="height: 200px;"></div>
        <div class="row  text-achivement">
            <div class="col-12">
                <div class="card card-md">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-10">
                                <h3 class="h1">Stake your GOALAND to earn DNC daily.</h3>
                                <div class="markdown text-secondary">
                                    Connect your wallet for us to fetch your land plots. The more land plots you own, the more DNC you earn!

                                    <p>
                                        <button @click="doFetchLands" class="btn buynow mt-3 p-3 disable-btn"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-wallet" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path>
                                                <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
                                            </svg> CONNECT YOUR WALLET</button>
                                    </p>
                                    <p class="text-red">Staking of GOALAND will be available soon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center mt-4 pt-2">
                <table class="table table-vcenter table-mobile-md card-table text-white tbl-my-assets table-bordered">
                    <tr>
                        <th>Number of GOALAND Plots</th>
                        <th>DNC Earnings</th>
                        <th>Description</th>
                        <th>Next refresh</th>
                    </tr>
                    <tr v-if="item.total > 0">
                        <td>
                            {{ item.total }} Land Plots
                        </td>
                        <td>
                            <!-- {{ item.dailyEarn }} DNC -->
                        </td>
                        <td>
                            <!-- You will earn {{ item.dailyEarn }} DNC daily. -->
                        </td>
                        <td>
                            <span v-if="!item.enable">{{ displayTime(item.next_time) }}</span>
                            <a class="disableEarning btn" v-else @xclick="claimDNCEarning">Collect DNC</a>
                        </td>
                    </tr>

                    <tr v-else>
                        <td>
                            0 Land Plots
                        </td>
                        <td>
                            0 DNC
                        </td>
                        <td>
                            You will earn 0 DNC daily.
                        </td>
                        <td>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";
import moment from "moment";


export default {
    name: "HomeMarketpalce",
    components: {
    },
    data() {
        return {
            item: {
                total: 0,
                dailyEarn: 0,
                enable: false,
                next_time: false
            },
            loading: {
                fetchLands: false
            }
        };
    },

    methods: {

        doFetchLands: async function () {
            if (this.loading.fetchLands) {
                return;
            }
            this.loading.fetchLands = true;
            try {
                let { data } = await axios.get('/api/fetch/lands');
                this.getMyLandPlots();
                if (data.error) {
                    this.$swal.fire({
                        title: data.msg,
                        icon: 'error'
                    });
                } else {
                    this.$swal.fire({
                        title: 'Action successful',
                        icon: 'success'
                    });
                }
                this.getMyLandPlots();
            } catch (error) {
                this.$swal.fire({
                    title: 'Request error, possibly due to a network connection issue. Please try again. ',
                    icon: 'error'
                });
            } finally {
                this.loading.fetchLands = false;
            }


        },

        getMyLandPlots: async function () {
            try {
                let { data } = await axios.get('/api/my/lands');
                this.item = data;
            } catch (e) {
                console.log(e);
            }
        },
        claimDNCEarning: async function () {
            this.$swal.fire({
                title: 'Your request is being processed',
                icon: 'info'
            });
            try {
                let { data } = await axios.post('/api/my/lands/claim');
                if (data.error) {
                    this.$swal.fire({
                        title: data.msg,
                        icon: 'error'
                    });
                } else {
                    this.$swal.fire({
                        title: 'Success. DNC collected.',
                        icon: 'success'
                    });
                }
                this.getMyLandPlots();
            } catch (error) {
                this.$swal.fire({
                    title: 'Request error, possibly due to a network connection issue. Please try again. ',
                    icon: 'error'
                });
            }
        },
        displayTime: function (time) {
            return moment(time).format('LLL');
        }
    },

    computed: {
    },
    watch: {
    },

    mounted() {
        this.getMyLandPlots();
    },
};
</script>
