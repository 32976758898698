<style scoped>
.card {
    overflow: hidden;
}

.icon-status>svg {
    width: 120px;
    height: 120px;
}

.mw-250 {
    max-width: 250px;
}

/*.list-group-item:active, .list-group-item:focus, .list-group-item:hover {
        background-color: rgba(var(--tblr-muted-rgb), .04);
    }*/
.list-group-item:hover .align-items-center {
    background-color: rgba(var(--tblr-muted-rgb), .04);
}

.list-plots {
    display: block;
    overflow: hidden;
}

.hash-tag {
    max-width: 250px;
    display: inline-block;
    vertical-align: bottom;
}

a {
    cursor: pointer;
}

.list-plots {
    -webkit-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
}


.h-ratio-100 {
    --tblr-aspect-ratio: 100%;
    height: 100%;
}

.tr-active {
    background-color: #f7e06e;
}

.list-scroll-815 {
    max-height: 815px;
    overflow: hidden;
    overflow-y: auto;

}
</style>
<template>
    <div class="page-wrapper">
        <div class="page-body">
            <div class="container-xl">
                <div class="card">
                    <div class="row g-0">
                        <div class="col-12 col-lg-4 d-md-block border-end">
                            <div class="col-12">
                                <div class="card-header">
                                    <h3 class="card-title strong">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-packages" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z"></path>
                                            <path d="M2 13.5v5.5l5 3"></path>
                                            <path d="M7 16.545l5 -3.03"></path>
                                            <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z"></path>
                                            <path d="M12 19l5 3"></path>
                                            <path d="M17 16.5l5 -3"></path>
                                            <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5"></path>
                                            <path d="M7 5.03v5.455"></path>
                                            <path d="M12 8l5 -3"></path>
                                        </svg>
                                        Land in the collection: {{ collection_info.name }}
                                    </h3>
                                </div>
                                <div class="list-scroll-815">
                                    <table class="table card-table table-vcenter">
                                        <tbody>
                                            <tr v-for="(land, index) in lands_items" v-bind:key="index" :class="{ 'tr-active': (land.active) }">
                                                <td class="text-nowrap">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-drag-drop-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <rect x="8" y="8" width="12" height="12" rx="2"></rect>
                                                        <line x1="4" y1="4" x2="4" y2="4.01"></line>
                                                        <line x1="8" y1="4" x2="8" y2="4.01"></line>
                                                        <line x1="12" y1="4" x2="12" y2="4.01"></line>
                                                        <line x1="16" y1="4" x2="16" y2="4.01"></line>
                                                        <line x1="4" y1="8" x2="4" y2="8.01"></line>
                                                        <line x1="4" y1="12" x2="4" y2="12.01"></line>
                                                        <line x1="4" y1="16" x2="4" y2="16.01"></line>
                                                    </svg>
                                                </td>
                                                <td class="w-100">
                                                    <div class="text-truncate hash-tag">
                                                        {{ land.location.bbox.join(', ') }}
                                                    </div>
                                                </td>
                                                <td class="w-1 ">
                                                    <input type="checkbox" class="form-check-input m-0 align-middle" @change="unmarkLands(land)" :value="land._id" v-model="markedLandPlotRows" checked="">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-8 ">
                            <div class="card-body">
                                <div class="mapbox-row">
                                    <div class="ratio h-ratio-100">
                                        <div>
                                            <div id="map-assets" class="w-100 h-100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import { Grid, GRID_CLICK_EVENT } from "./grid";

let map = undefined;
var selectedCells = [];
var userLandItems = [];

export default {
    el: "#app",
    components: {},
    name: "collection_land",

    data() {
        return {
            collection_active: false,
            collections_lands_items: [],
            lands_items: [],
            collection_info: [],

            landsMarked: false,
            markedLandRows: [],

            landPlotMarked: false,
            markedLandPlotRows: [],
            move_type: "individual",

            collection_selected: undefined,
            collection_ar: [],
            collection_selected_error: false,
            collection_is_open: "",
            collection_name_error: false,

            access_token_mapbox: "pk.eyJ1IjoiZGVpcGFyb3VzIiwiYSI6ImNsY29pYWhicDFjeGczbm84MzA0Ynk1czkifQ.iVsD87dg6-t4eFzrUAkCeQ",

        };
    },
    methods: {
        doMintNFT: function () {
            this.$swal("Minting feature is development", "Soon", "success");
        },

        fetchLandsInCollection: function () {
            var uriid = this.$route.params.id;
            axios
                .get(`/api/collection/detail`, {
                    params: {
                        uriid: uriid
                    }
                })
                .then(r => {
                    let data = r.data;
                    console.log(data);

                    if (r.data.error) {
                        this.$swal(r.data.msg);
                    } else {
                        this.lands_items = r.data.data;
                        this.collection_info = r.data.info;

                        userLandItems = userLandItems.concat(r.data.data)
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        unmarkLands(item) {
            console.log('item', item)

            this.landsMarked = false;

            this.showLandOnMap(item);
        },

        getCurrentLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((p) => {
                    if (map) {

                        map.setZoom(13)
                        map.flyTo({
                            center: [p.coords.longitude, p.coords.latitude]
                        })
                    }
                }, (e) => {
                    //map.setZoom(3)
                    console.log(e);
                }, { timeout: 10000 });
            } else {
                //Geolocation is not supported by this browser
                console.log("Can not get location")
                //map.setZoom(3)
            }
        },

        showLandOnMap(item) {
            const selectedCellsId = "ready-cells";
            let bbox = item.location.bbox;

            console.log('bbox', bbox)

            const cellIndex = selectedCells.findIndex(x => x.geometry.bbox.toString() === bbox.toString());
            if (cellIndex === -1) {
                const coordinates = item.location.coordinates;
                const cell = {
                    type: "Feature",
                    geometry: {
                        type: "Polygon",
                        bbox,
                        coordinates,
                    },
                    areas: undefined
                };
                console.log("Select land item", cell);
                selectedCells.push(cell);
            } else {
                selectedCells.splice(cellIndex, 1);
            }

            const source = map.getSource(selectedCellsId);
            source.setData({
                type: "FeatureCollection",
                features: selectedCells,
            });

            if (map) {
                map.setZoom(13)
                map.flyTo({
                    center: item.location.coordinates[0][0]
                })
            }
        },

        initMapbox() {
            let self = this;
            // @formatter:off
            // eslint-disable-next-line no-undef
            mapboxgl.accessToken = this.access_token_mapbox;
            // eslint-disable-next-line no-unused-vars, no-undef
            map = new mapboxgl.Map({
                container: "map-assets",
                style: "mapbox://styles/mapbox/streets-v12",
                zoom: 1.5,
                center: [103.7302585, 25.4262305],
            });
            // eslint-disable-next-line no-undef
            const geocoder = new MapboxGeocoder({
                // Initialize the geocoder
                // eslint-disable-next-line no-undef
                accessToken: mapboxgl.accessToken, // Set the access token
                // eslint-disable-next-line no-undef
                mapboxgl: mapboxgl, // Set the mapbox-gl instance
                marker: false, // Do not use the default marker style
            });

            map.addControl(geocoder);

            const grid1 = new Grid({
                gridWidth: 0.4,
                gridHeight: 0.4,
                minZoom: 11,
                maxZoom: 19,
                units: "kilometers",
                paint: {
                    "line-opacity": 0.2,
                },
            });
            map.addControl(grid1);

            map.on("moveend", () => {

            });

            map['boxZoom'].disable();

            map.on("load", async () => {
                const selectedCellsId = "ready-cells";

                console.log("load");


                map.addSource(selectedCellsId, {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: selectedCells,
                    },
                });
                map.addLayer({
                    id: selectedCellsId,
                    source: selectedCellsId,
                    type: "fill",
                    paint: {
                        "fill-color": "#0000ff",
                        "fill-opacity": 0.6,
                    },
                });



                /* layer for touch cell id */
                map.addSource('touch-cell-id', {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                });
                map.addLayer({
                    id: 'touch-cell-id',
                    source: 'touch-cell-id',
                    type: "fill",
                    paint: {
                        "fill-color": "#f7e06e",
                        "fill-opacity": 0.3,
                    },
                });

                map.addSource("myplot-cells", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    }
                });
                map.addLayer({
                    id: "myplot-cells",
                    source: "myplot-cells",
                    type: "fill",
                    paint: {
                        "fill-color": "#000000",
                        "fill-opacity": 0.2,
                    },
                });


                // Catch event when click to my land on map
                map.on(GRID_CLICK_EVENT, event => {
                    const bbox = event.bbox;
                    // Kiem tra xem co phai la ele da co ng mua chua
                    const found = userLandItems.findIndex(x => x.location.bbox.toString() === bbox.toString());
                    if (found !== -1) {
                        console.log(self.lands_items);
                        console.log(userLandItems[found].location.bbox.toString(), "Found click item")
                        self.lands_items = self.lands_items.map((e, index) => {
                            e.active = (e.location.bbox.toString() == bbox.toString())

                            if (e.active === true) {
                                this.scrollTableCenter(index)
                            }
                            // if (e.location.bbox.toString() == bbox.toString()) {
                            //     var line = +document.querySelector('#line').value;
                            //     var rows = table.querySelectorAll('tr');
                            //
                            //     rows.forEach(row => row.classList.remove('active'))
                            //     rows[line].classList.add('active');
                            //     rows[line].scrollIntoView({
                            //         behavior: 'smooth',
                            //         block: 'center'
                            //     });
                            // }

                            return e;
                        })
                        map.getSource("touch-cell-id")
                            .setData({
                                type: "FeatureCollection",
                                features: [{
                                    type: "Feature",
                                    geometry: {
                                        type: "Polygon",
                                        bbox,
                                        coordinates: userLandItems[found].location.coordinates,
                                    },
                                    areas: undefined
                                }],
                            })


                        return;
                    } else {
                        self.lands_items = self.lands_items.map(e => {
                            e.active = false
                            return e;
                        })
                        map.getSource("touch-cell-id").setData({
                            type: "FeatureCollection",
                            features: [],
                        })
                    }
                });





            });
        },

        scrollTableCenter: function (line) {
            var table = document.querySelector('table');

            var rows = table.querySelectorAll('tr');

            rows[line].scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }

    },
    mounted: function () {
        let self = this;
        setTimeout(() => {
            self.getCurrentLocation();
        }, 2000);


        this.initMapbox();

        this.fetchLandsInCollection();
    },

    watch: {},
};
</script>
