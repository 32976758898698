import {
    createApp
} from 'vue'
import axios from "axios";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import './assets/css/tabler.css'
// import 'sweetalert2/dist/sweetalert2.min.css';
// import './assets/css/style.css'

import './assets/marketplace/bootstrap.min.css'
import './assets/marketplace/bootstrap.min.css'
import './assets/marketplace/bootstrap.min.css'
import './assets/marketplace/swiper-bundle.min.css'
import './assets/marketplace/swiper-bundle.min.css'
import './assets/marketplace/swiper-bundle.min.css'
import './assets/marketplace/magnific-popup.css'
import './assets/marketplace/style.css'
import './assets/marketplace/custom.css'
import './assets/css/style.css'
import VueCarousel from 'vue-carousel';
import {
    ServerTable
} from 'v-tables-3';
import LottiePlayer from 'lottie-player-vue';

import App from './App.vue'
import {
    router
} from './helpers';
import {
    store
} from './store';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers = {
        'Authorization': 'JWT ' + localStorage.getItem('jwt'),
        'Content-Type': 'application/json'
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


function refreshAccessToken() {
    return axios
        .post(`/api/account/refresh_token`, {
            refresh_token: localStorage.getItem('jwt_refresh')
        })
        .then(r => {
            let data = r.data;
            if (data.error) {
                throw "Can not retry token";
            } else {
                localStorage.setItem("jwt", data.access_token);
                return data.access_token;
            }
        })
}

// Response interceptor for API calls
axios.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const access_token = await refreshAccessToken();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        } catch (error) {
            // Can not get refresh access token
            // Raise logout event
            return Promise.reject("Can not refresh token");
        }
        console.log("retry request");
        return axios(originalRequest);
    }
    return Promise.reject(error);
});




const app = createApp(App)
window.axios = require('axios');
app.use(router)
app.use(VueSweetalert2);
app.use(store)
app.use(LottiePlayer);
app.use(ServerTable)
app.use(VueCarousel);


import vClickOutside from "click-outside-vue3"
app.use(vClickOutside)
import VueUploadComponent from 'vue-upload-component';
app.component('file-upload', VueUploadComponent)

app.mount('#app')