<style scoped>
.icon-status>svg {
    width: 120px;
    height: 120px;
}
</style>
<template>
    <div class="page page-center">
        <div class="container container-narrow py-4">




            <div data-v-a4cab136="" style="height: 200px;"></div>
            <div class="card card-md text-white">
                <div class="card-body text-center">
                    <h2 class="h2 text-center mb-4 text-white">
                        {{ title }}
                    </h2>

                    <div class="mb-3 icon-status">
                        <svg v-if="error" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-alert-circle text-red" width="124" height="124" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="12" cy="12" r="9"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check text-green" width="124" height="124" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="12" cy="12" r="9"></circle>
                            <path d="M9 12l2 2l4 -4"></path>
                        </svg>
                    </div>

                    <div class="mb-3">
                        {{ content }}
                    </div>
                    <div class="form-footer" v-if="!error">
                        <RouterLink to="/login" class="btn btn-primary">Go to Login Page</RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    components: {},
    name: "registry-app",
    data() {
        return {
            error: "",
            title: "",
            content: "",
        };
    },
    methods: {
        doActiveEmail: function () {
            var key = this.$route.query.key;
            axios
                .get(`/api/account/active/${key}`)
                .then(r => {
                    console.log(r);
                    this.error = r.data.error;
                    if (r.data.error) {
                        this.title = "Error !";
                        this.content = r.data.msg;
                    } else {
                        this.title = "Your account has been activated!";
                        this.content = r.data.msg;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
    mounted: function () {
        this.doActiveEmail();
    },
};
</script>
