<style scoped>
.wertNoticeBar {
    background-color: #c41414;
    color: white;
    text-align: center;
}
</style>
<template>
    <div v-if="checkoutStatus" id="exampleModal" tabindex="-1" class="modal modal-blur fade show" role="dialog" aria-modal="true" active="true">
        <div class="modal-dialog" id="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        CHECKOUT
                        <p>Purchase.</p>
                    </h5>
                    <button @click="closeCheckoutDialog" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="overflow: hidden">
                    <div class="row">
                        <div class="col-sm-6 title-balance">Price</div>
                        <div class="col-sm-6 value-balance">{{ totalPrice }} USD</div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-sm-6 text-center">
                            <button @click="onShowFiatPaymentClick" class="btn approve">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"></path>
                                    <path d="M3 10l18 0"></path>
                                    <path d="M7 15l.01 0"></path>
                                    <path d="M11 15l2 0"></path>
                                </svg>

                                Credit / Debit Card</button>
                        </div>
                        <div class="col-sm-6 text-center">
                            <button @click="onShowCryptoPaymentClick" class="btn approve">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M6 12l6 -9l6 9l-6 9z"></path>
                                    <path d="M6 12l6 -3l6 3l-6 2z"></path>
                                </svg>

                                Cryptocurrency
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Modals -->
    <div class="modal modal-blur fade show" id="toup-weirt3" tabindex="-1" role="dialog" aria-modal="true" style="display: block" v-if="isWertCartNoticeDialogShow">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center">
                        Please Have Your Mobile Phone With You For Secure Identification.
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeNoticeDialog"></button>
                </div>
                <div class="modal-body" style="font-size: 11px">
                    <p>
                        By proceeding with the purchase of Nova Frontier X ("Transaction"), you
                        (“User”) understand that your payment and information will be
                        processed by Wert (“SHA2 Solutions”). The User will be redirected to
                        Wert's payment module during the checkout process and the User must
                        read and accept the Terms and Conditions and Privacy Policy prior to
                        finalizing their purchase. Nova Frontier X and The Tipsy
                        Company are not responsible for the handling or processing of any
                        payment or personal information related to the User as part of the
                        Transaction.
                    </p>
                    <p class="text-center">
                        <a class="btn approve" @click="gotoWertPayment">PROCEED</a>
                    </p>
                </div>
            </div>
        </div>
    </div>



    <div class="modal modal-blur fade show" id="toup-weirt2" tabindex="-1" role="dialog" aria-modal="true" style="display: block" v-if="isWertDialogShow">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">WertIO Payment</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeWertDialog"></button>
                </div>
                <div class="modal-body" style="padding: 0; min-height: 600px">
                    <div v-if="!wertLoaded" class="loading-wert">
                        Loading ..
                    </div>
                    <div class="wertNoticeBar" v-if="wertNoticeBar">
                        {{ wertNoticeBar }}
                    </div>
                    <div id="widget_landplot"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import axios from "axios"; 
import { mapState } from "vuex";

export default {
    name: "HomeMarketpalce",
    components: {
    },
    data() {
        return {
        };
    },

    methods: {

        closeNoticeDialog: function () {
            this.$store.dispatch("shop/hideWertNotice");
        },

        closeCheckoutDialog: function () {
            this.$store.dispatch("shop/closeCheckoutDialog", {});
        },
        async onShowFiatPaymentClick() {
            var result = await this.$swal.fire({
                title: "Credit / Debit Card",
                text: "Select your payment method.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Stripe (Default)",
                denyButtonText: `Wert.io`
            });
            if (result.isConfirmed) {
                // Somethings
                var responseData = await this.$store.dispatch(
                    "shop/creditPaymentStripe",
                    {
                        type: 2,
                        collection: [],
                        items: []
                    }
                );
                console.log(responseData, "responseData Stript");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }
            if (result.isDenied) {

                this.$store.dispatch("shop/showWertNotice", {});
            }
        },

        onShowCryptoPaymentClick() {
            this.$store.dispatch("shop/doStartETHPayment");
        },

        gotoWertPayment() {
            this.$store.dispatch("shop/wertioPayment", {});
        },

        async closeWertDialog() {
            this.$store.dispatch("shop/terminateCartWertIOPayment");
            if (this.itemsOnCart.length == 0) {
                return;
            }
            if (!this.showStripeFlag) {
                return;
            }
            var result = await this.$swal.fire({
                title: "Your payment could not be processed. Please try again and ensure your card details are entered correctly.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Try again",
                denyButtonText: `Cancel`
            });
            if (result.isConfirmed) {
                var responseData = await this.$store.dispatch(
                    "shop/creditPaymentStripe",
                    {
                        type: 2,
                        collection: [],
                        items: []
                    }
                );
                console.log(responseData, "responseData Stript");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }
        },

    },

    computed: {
        ...mapState("shop", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "addCartException",
            "showDialogPaymentStripe",
            "isWertCartNoticeDialogShow",
            "isWertDialogShow",
            "wertLoaded",
            "wertNoticeBar",
            "showStripeFlag"
        ]),
    },
    watch: {
    },

    mounted() {
    },
};
</script>
