import {
    createRouter,
    createWebHistory
} from 'vue-router'

import AboutPage from '../pages/AboutPage.vue'
// import MapPage from '../pages/MapPage.vue'
import ListNFT from '../pages/ListNFT.vue'
import HomeMarketpalce from '../pages/HomeMarketpalce.vue'
import HomeNova from '../pages/HomeNova.vue'
import ShopItems from '../pages/ShopItems.vue'
//import Home from '../pages/HomeIntro.vue'
import DashbroadUser from '../pages/DashbroadUser.vue'
import DetailNFT from '../pages/DetailNFT.vue'
import LoginPage from '../pages/LoginPage.vue'
import RegistryPage from '../pages/RegistryPage.vue'
import EmailActive from '../pages/EmailActive.vue'
import ForgotPasswordSendEmail from '../pages/ForgotPasswordSendEmail.vue'
import ForgotPasswordUpdate from '../pages/ForgotPasswordUpdate.vue'
import ConnectWallet from '../pages/ConnectWallet.vue'
import TermsOfService from '../pages/TermsOfService.vue'
// import StripePaymentVue from '../pages/StripePayment.vue'
import MyAssets from '../pages/MyAssets.vue'
import CollectionDetails from '../pages/CollectionDetails.vue'
import ChangePassword from "../pages/ChangePassword.vue";
import AssetsMap from '../pages/AssetsMap.vue'
import CollectionLand from '../pages/CollectionLand.vue'
import ChangeProfile from "../pages/ChangeProfile.vue";
import TopItems from "../pages/TopItems.vue";
import Leaderboard from "../pages/Leaderboard.vue";
import PeguinsPage from "../pages/PeguinsPage.vue";
import GinExchange from "../pages/GinExchange.vue";
import LandsPage from "../pages/LandsPage.vue";
import ShopDetail from "../pages/ShopDetail.vue";
import MarketplaceDetail from "../pages/MarketplaceDetail.vue";
const routes = [{
    path: '/',
    component: HomeNova
},
{
    path: '/market/list',
    component: ListNFT
},
{
    path: '/market/:id',
    component: MarketplaceDetail
},
{
    path: '/market',
    component: HomeMarketpalce
},
{
    path: '/shop',
    component: ShopItems
},
{
    path: '/shop/:id',
    component: ShopDetail
},
{
    path: '/contact',
    component: AboutPage
},
{
    path: '/login',
    component: LoginPage,
    name: "login"
},
{
    path: '/registry',
    component: RegistryPage,
    name: "registry"
},
{
    path: '/email_active',
    component: EmailActive,
    name: "email_active"
},
{
    path: '/forgot_password_send_email',
    component: ForgotPasswordSendEmail,
    name: "forgot_password_send_email"
},
{
    path: '/forgot_password_update',
    component: ForgotPasswordUpdate,
    name: "forgot_password_update"
},
{
    path: '/connect_wallet',
    component: ConnectWallet,
    name: "connect_wallet"
},
{
    path: '/terms_of_service',
    component: TermsOfService,
    name: "terms_of_service"
},
{
    path: '/detail/:id',
    component: DetailNFT,
    name: "detail"
},
{
    path: '/dashbroad',
    component: DashbroadUser,
    name: "DashbroadUser"
},
{
    path: '/altura',
    component: () => import('../pages/AlturaLogin.vue'),
    name: "AlturaLogin"
},
{
    path: '/my-assets',
    component: MyAssets,
    name: "myassets",
    children: [
        {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: '',
            component: () => import('../pages/my-assets/MySpaceships.vue'),
        },
        {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: 'spaceships',
            component: () => import('../pages/my-assets/MySpaceships.vue'),
        },
        {
            // UserPosts will be rendered inside User's <router-view>
            // when /user/:id/posts is matched
            path: 'dnc-listing',
            component: () => import('../pages/my-assets/DNCListing.vue'),
        },
        {
            path: 'spaceships-selling',
            component: () => import('../pages/my-assets/SpaceshipsListing.vue'),
        },
        {
            path: 'dnc-history',
            component: () => import('../pages/my-assets/DNCHistory.vue')
        }
    ],
},
{
    path: '/assets/collections',
    component: CollectionDetails,
    name: "collection_details"
},
{
    path: '/change-password',
    component: ChangePassword,
    name: "change-password"
},
{
    path: '/change-profile',
    component: ChangeProfile,
    name: "change-profile"
},
{
    path: '/assets',
    component: AssetsMap,
    name: "assets"
},
{
    path: '/item/:id',
    component: CollectionLand,
    name: "collection_land"
},
{
    path: '/top',
    component: TopItems,
    name: "top_items"
},
{
    path: '/leaderboard',
    component: Leaderboard,
    name: "leaderboards"
},

{
    path: '/my/goaland',
    component: LandsPage,
    name: "goaland"
},

{
    path: '/my/gin-exchange',
    component: GinExchange,
    name: "ginexchange"
},

{
    path: '/my/penguins',
    component: PeguinsPage,
    name: "peguins"
},

{
    path: '/tos',
    component: () => import('../pages/TermOfServices.vue'),
    name: "tos"
},

{
    path: '/privacy',
    component: () => import('../pages/PrivacyPolicy.vue'),
    name: "privacy"
},
{
    path: '/shop/stripe_payment/:result',
    component: () => import('../pages/StripePaymentResult.vue'),
    name: "stripe_callback"
},
{
    path: '/my/blindbox',
    component: () => import('../pages/BlindboxItems.vue'),
    name: "blindbox"
},
{
    path: '/my/redeem',
    name: "Redeem",
    component: () => import('../pages/RedeemSpaceship.vue')
}

]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})


router.beforeEach(async (to, from, next) => {

    if (to.path == '/login') {
        next();
        return;
    }

    let jwtToken = localStorage.getItem('jwt');

    let requireLogin = ['ginexchange', 'goaland', 'peguins', 'change-profile', 'assets', 'myassets', 'change-password'];
    if (!jwtToken && requireLogin.includes(to.name)) {
        next({
            path: '/'
        });
        return;
    }


    if (!jwtToken) {
        switch (to.name) {
            case 'login' || 'registry' || 'forgot_password_send_email' || 'forgot_password_update' || 'terms_of_service' || 'email_active':
                next({
                    path: '/'
                });
                break;
            default:
                next();
                break;
        }
        return;
    } else {
        next();
    }
});