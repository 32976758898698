<style scoped>
.page {
    display: block;
}

.page.page-center {
    background-color: #1e293b;
}


.card-login {
    max-width: 600px;
}

.form-control {
    color: white !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #999;
}

input:-webkit-autofill:focus {
    -webkit-text-fill-color: #999;
}

.input-group-text {
    height: 56px;
    color: #ffffff;
    background-color: #071838;
    border: none;
}
</style>
<template>
    <div class="page page-center">
        <div class="container container-tight py-4 card-login">

            <div style="height: 200px;"></div>
            <div class="card card-md bg-white">
                <div class="card-body">
                    <h2 class="h2 text-center mb-4">Forgot Password</h2>
                    <form autocomplete="off" novalidate="true" @submit="doSubmit">
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input type="email" v-model="email" class="form-control" placeholder="Enter your email" autocomplete="off" />
                        </div>

                        <div class="form-footer">
                            <button class="btn btn-primary w-100" type="submit">Reset Password</button>
                        </div>
                    </form>
                </div>
                <div class="hr-text text-center">or</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <router-link class="btn w-100" to="/login" tabindex="-1">Login</router-link>
                        </div>
                        <div class="col">
                            <router-link class="btn w-100" to="/registry" tabindex="-1">Sign up</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    components: {},
    name: "forgot_password",
    data() {
        return {
            email: "",
        };
    },
    methods: {
        doSubmit: function (e) {
            e.preventDefault();

            if (!this.email) {
                this.$swal("Please enter your email!", "", "error");
                return;
            }

            axios
                .post(`/api/account/forgot`, {
                    email: this.email,
                })
                .then(r => {
                    console.log(r);
                    if (r.data.error) {
                        this.$swal(r.data.msg, "", "error");
                    } else {
                        this.$swal("Password reset instructions have been sent to your email. Please check your inbox and spam folder. If you don't receive it, contact support.", "", "success");
                        this.email = "";
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
};
</script>
