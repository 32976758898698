import axios from 'axios';
const state = {
    buyAvaible: false
}


const actions = {

    async getSetting({ commit }) {
        try {
            let { data } = await axios.get('/site/setting');
            if (!data.error) {
                commit("setBuyStatus", {
                    status: (data.sale_program == 1)
                });
            }
        } catch (error) {
            return;
        }
    },

};
const mutations = {

    setBuyStatus(state, { status }) {
        state.buyAvaible = status;
    },
};

export const website = {
    namespaced: true,
    state,
    actions,
    mutations
};