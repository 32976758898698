<style></style>

<template>
    <div class="container-xl">
        <div style="height: 200px;"></div>
        <div class="row  text-achivement">
            <div class="col-12">
                <div class="card card-md">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-10">
                                <h3 class="h1">Galactic Champions of the Month</h3>
                                <div class="markdown text-secondary">
                                    Earn points with every challenge you master, every fragment you collect, and every friend you refer to join you on your intergalactic adventures! The more you and your friends play, the higher you'll soar in the rankings.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center mt-4 pt-2">
                <table class="table table-vcenter table-mobile-md card-table text-white tbl-my-assets table-bordered">
                    <tr>
                        <th>Rank</th>
                        <th>Player Name</th>
                        <th>Points</th>
                    </tr>
                    <tr v-for="(item, index) in items" v-bind:key="item._id">
                        <td>
                            #{{ index + 1 }}
                        </td>
                        <td>
                            {{ item.user_name }}
                        </td>
                        <td>
                            {{ Math.round(item.point_number) }}
                        </td>
                    </tr>
                </table>
                <a @click="viewPage(i)" v-for="i in pages" :key="i" class="btn btn-page" :class="{ 'btn-primary': (i == page) }">{{ i }}</a>
            </div><!--end col-->
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";
import { mapState } from "vuex";

function objectToConcat(object1) {
    let item = [];
    for (const [key, value] of Object.entries(object1)) {
        if (value) {
            item.push(key);
        }
    }
    return item.join(',');
}


export default {
    name: "HomeMarketpalce",
    components: {
    },
    data() {
        return {
            filter: {
                element: {
                    ai: false,
                    alien: false,
                    earth: false
                },
                rarity: {
                    common: false,
                    rare: false,
                    epic: false,
                    legend: false
                },
                price: {
                    from: 0,
                    to: 0
                }
            },
            value: 1,
            items: [],
            total: '...',
            pages: [],
            page: 1
        };
    },

    methods: {
        viewPage(p) {
            this.page = p;
            this.fetchItems();
            document.getElementById("rowresult").scrollIntoView();
        },
        priceRound(p) {
            return '$' + p.toFixed(3)
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },

        fetchItems: async function () {
            try {
                let filterQuery = {
                }
                let { data } = await axios.get('/api/leaderboards/topref', { params: filterQuery });

                console.log(data);

                this.items = data.data;
                this.total = data.total;

            } catch (error) {
                console.log(error);
            }
        },
        filterBulder() {
            let filterQuery = {
                element: objectToConcat(this.filter.element),
                rarity: objectToConcat(this.filter.rarity),
                price_from: this.filter.price.from,
                price_to: this.filter.price.to,
            }
            this.$router.push({ query: filterQuery });
            this.fetchItems();
        }
    },

    computed: {
        ...mapState("account", ["status", "creditAmount", "user"]),
        ...mapState("cart", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "landType",
            "landCollection",
            "landPlotBuyResult",
            "addCartException",
            "showDialogPaymentStripe",
        ]),
        ...mapState("map", ["styleMap"]),
    },
    watch: {
        landPlotBuyResult: function ({ isError }) {
            this.clearSelectionLand();
            if (!isError) {
                this.fetchLands();
            }
        },

        addCartException: function ({ error, title, msg }) {
            this.$swal(title, msg, error ? "error" : false);
        },

        filter: {
            handler(newValue) {
                console.log(newValue);
                this.filterBulder();
            },
            deep: true
        }
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        this.fetchItems();
    },
};
</script>
