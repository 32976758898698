<style scoped>
.page {
    display: block;
}

.page.page-center {
    background-color: #1e293b;
}

.avatar.avatar-xl {
    border-radius: 50%;
    height: 100px;
    display: block;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.col-auto {
    position: relative;
}

#fileUpload {
    position: absolute;
    top: 0px;
    opacity: 0;
}

.card-login {
    max-width: 600px;
}

.form-control {
    color: white !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #999;
}

input:-webkit-autofill:focus {
    -webkit-text-fill-color: #999;
}

.input-group-text {
    height: 56px;
    color: #ffffff;
    background-color: #071838;
    border: none;
}

.btn-upload-avatar {
    color: white !important;
    cursor: pointer;
}
</style>
<template>
    <div class="page page-center">
        <div class="container container-tight py-4 card-login">
            <div style="height: 200px;"></div>
            <div class="card card-md bg-white">
                <div class="card-body ">
                    <h2 class="h2 text-center mb-4">Change Profile</h2>
                    <form autocomplete="off" novalidate="">
                        <div class="mb-3">
                            <label class="form-label">Username</label>
                            <input type="text" v-model="username" class="form-control" placeholder="Username" autocomplete="off" />
                        </div>
                        <div class="form-footer">
                            <button v-on:click="doSubmit()" class="btn btn-primary w-100" type="button">
                                Update
                            </button>
                        </div>
                        <hr />

                        <div class="mb-2">
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <span class="avatar avatar-xl" :style="'background-image: url(' + avatar + ')'"></span>
                                </div>
                                <div class="col-auto">

                                    <file-upload ref="upload" v-model="files" post-action="/api/account/change_avatar" @input-file="inputFile" @input-filter="inputFilter">
                                        <a class="btn btn-primary btn-upload-avatar"> Upload avatar </a>
                                    </file-upload>


                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {
    },
    name: "login-app",
    data() {
        return {
            username: "",
            newPassword: "",
            newPassword2: "",
            avatar: "/noavatar.svg",
            files: [],
        };
    },
    mounted() {
        let self = this
        axios
            .get("/api/account/profile")
            .then((response) => {
                self.avatar = response.data.user.avatar
                self.username = response.data.user.username
            })
            .catch((error) => {
                console.error("Check account", error);
            });
    },
    methods: {
        /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
        inputFile: function (newFile, oldFile) {
            if (newFile && oldFile && !newFile.active && oldFile.active) {
                // Get response data
                console.log('response', newFile.response)
                if (newFile.xhr) {
                    //  Get the response status code
                    console.log('status', newFile.xhr.status)
                }
            }
            if (newFile && !oldFile) {
                // add
                console.log("add", newFile);
                console.log(this.$refs.upload);

                axios.post('/api/account/avatar/sign_url', {
                    file_name: newFile.name
                }).then(({ data }) => {
                    newFile.putAction = data.url;
                    newFile.key = data.key;
                    this.$refs.upload.active = true;
                }).catch(e => {
                    console.log(e);
                })

            }
            if (newFile && oldFile) {
                // update
                console.log("update", newFile);
                if (newFile.success && !newFile.active) {
                    // So done
                    let response = newFile.response;
                    console.log(response);

                    // /api/account/avatar

                    axios.post('/api/account/avatar', {
                        key: newFile.key
                    }).then(({ data }) => {
                        console.log(data);
                        //alert('done');
                        this.$swal("Avatar update completed", "", "success");
                        this.$store.dispatch("account/checkSession");
                    }).catch(e => {
                        console.log(e);
                    })
                } else {
                    // So is loading process !!!
                    //this.progressLoading = newFile.progress;
                }
            }
            if (!newFile && oldFile) {
                // remove
                console.log("remove", oldFile);
            }
        },
        /**
         * Pretreatment
         * @param  Object|undefined   newFile   Read and write
         * @param  Object|undefined   oldFile   Read only
         * @param  Function           prevent   Prevent changing
         * @return undefined
         */
        inputFilter: function (newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Filter non-image file
                if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
                    return prevent()
                }
            }

            // Create a blob field
            newFile.blob = ''
            let URL = window.URL || window.webkitURL
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file)
                this.avatar = newFile.blob;
            }
        },
        changeFileUploadImage: function () {
            let self = this;
            var file = document.getElementById("fileUpload").files[0];
            var size_avatar = file.size / 1024 / 1024;
            if (size_avatar > 1) {
                this.$swal("Image size less than 1MB!", "", "error");
                return;
            }
            console.log("This file size is: " + file.size / 1024 / 1024 + "MiB");
            var reader = new FileReader();
            reader.onloadend = function () {
                self.avatar = reader.result;
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        },
        doSubmit() {
            let self = this
            if (!self.username) {
                self.$swal("Please enter username", "", "error");
                return;
            }
            axios
                .post(`/api/account/change_profile`, {
                    username: self.username,
                    avatar: self.avatar,
                })
                .then((r) => {
                    let data = r.data;
                    if (!data.error) {
                        self.$swal(data.msg, "", "success");
                        self.$store.dispatch("account/checkSession");
                    } else {
                        self.$swal(data.msg, "", "error");
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>
