<style scoped>
.btn-remove-all-cart {
    float: right;
}

.cart-box td {
    background-color: #333539;
}

.cart-box .text-right {
    text-align: right;
    color: red;
    font-weight: bold;
}

.cart-box .align-left {
    text-align: left;
    padding-left: 20px;
}

.opacity-20 {
    opacity: 0.2;
}
</style>
<template>
    <div class="cart-box">
        <div v-if="totalItems">
            <div>
                Cart ({{ totalItems }})
                <a @click="removeItemAllToCart()" class="btn-remove-all-cart">Remove all items</a>
            </div>
            <table>
                <tbody>
                    <tr v-for="(item, index) in itemsOnCart" v-bind:key="index">
                        <td class="align-left">
                            <b>{{ item.name }}</b>
                        </td>
                        <td>${{ item.sell_price }} x {{ item.total }}</td>
                        <td>
                            <a @click="removeItem(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                                    <path d="M10 10l4 4m0 -4l-4 4"></path>
                                </svg>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div class="col-4"><b>Total :</b></div>
                <div class="col-8 text-right text-red"> ${{ totalPrice }}</div>
            </div>
            <div class="mt-3">
                <a v-if="buyAvaible == true" @click="checkout" class="btn btn-primary active w-100">
                    CHECKOUT
                </a>
                <a v-else class="btn btn-primary active w-100 opacity-20">
                    CHECKOUT
                </a>
            </div>


            <!--

            <div class="card-header">
                <div class="card-title">

                </div>
                <div class="ms-auto">
                    <span class="text-azure" v-if="priceInCart.discountPercent">
                        {{ priceInCart.discountPercent }}% off activated!</span>
                </div>
            </div>
            <div class=" ">
                <div class="cart-table">
                    <div class="table-responsive">

                    </div>
                </div>

                <div class="px-3">
                    <div class="d-flex">
                        <b>Sub Total</b>
                        <div class="ms-auto">${{ priceInCart.totalsub }}</div>
                    </div>
                    <div class="d-flex">
                        <b>Discount</b>
                        <div class="ms-auto">-${{ priceInCart.discount }}</div>
                    </div>
                </div>

                <div class="px-3">
                    <div class="d-flex">
                        <b>Total</b>
                        <div class="ms-auto text-end">
                            <h3 class="mb-0">${{ priceInCart.priceTotal }}</h3>
                        </div>
                    </div>
                </div>

                <div class="px-3">
                    <div class="d-flex mb-3">
                        <b>Total payment</b>
                        <div class="ms-auto text-end">
                            <h3 class="mb-0">${{ priceInCart.priceTotal }}</h3>
                                                          <span class="text-azure">or 10 Credits</span> 
                        </div>
                    </div>

                    <div class="text-center text-azure mt-3 mb-1" v-if="priceInCart.missingCount">
                        Select {{ priceInCart.missingCount }} more plots and get
                        {{ priceInCart.nextDiscount }}% off
                    </div>
                    <div>
                        <a @click="checkout" class="btn btn-primary active w-100">
                            CHECKOUT
                        </a>
                    </div>
                </div>
            </div>

            -->
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
    data() {
        return {
            topupMenuIsOpen: false,
            menuIsOpen: false,
            priceInCart: {
                totalsub: 0,
                discount: 0,
                priceTotal: 0,
                nextDiscount: 0,
                missingCount: 0,
                discountPercent: 0,
                basePrice: 0,
            },
            type: "individual",
            collection_ar: [],
            collection: undefined,
            land_collection_name: "",
            collection_name_error: false,
            API_URL: process.env.VUE_APP_BASE_URL
        };
    },
    components: {},
    computed: {
        ...mapState("account", ["status", "user", "creditAmount"]),
        ...mapState("shop", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "cartIsOpen",
        ]),
        ...mapState("website", ["buyAvaible"])
    },
    methods: {

        removeItem(index) {
            this.$store.dispatch("shop/removeItem", {
                index: index,
            });
        },

        removeItemAllToCart() {
            this.$store.dispatch("shop/removeItemAll");
        },

        checkout() {
            let isLogined = this.status && this.status.loggedIn;
            if (!isLogined) {
                this.$store.dispatch("swal/show", {
                    title: "",
                    msg: "To initiate payment process, please log into your account.",
                    type: "info",
                    allowOutsideClick: true,
                    showConfirmButton: true
                }, { root: true });
                return;
            }
            this.$store.dispatch("shop/showCheckoutDialog");
        },

        openCart() {
            this.$store.dispatch("shop/openCart", {
                status: true,
            });
        },

        getPriceInCart() {
            let self = this;

            axios
                .post(`/api/getprice`, {
                    items: self.itemsOnCart.map((e) => {
                        return e;
                    }),
                })
                .then((response) => {
                    if (response.data && !response.data.error) {
                        self.priceInCart = response.data.data;

                        self.$store.dispatch("cart/updatePrice", {
                            price: self.priceInCart.priceTotal,
                            price_items: self.priceInCart,
                        });
                    } else {
                        this.$swal(response.data.msg, "Sorry", "error");
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.$swal("Please try again, network error", "Sorry", "error");
                });
        },

        showTopupCreditDialog() {
            this.$store.dispatch("landCreditTopup/showModal", {});
        },

        onCloseLandCreditMenu() {
            this.topupMenuIsOpen = false;
        },
        onCloseUserMenu() {
            this.menuIsOpen = false;
        },
        onCloseCart(event) {
            if (!event.target.classList.contains("btn")) {
                this.$store.dispatch("cart/openCart", { status: false });
            }
        },
    },
    async mounted() {
        let self = this;
        let userInfomation = await this.$store.dispatch("account/checkSession");
        //this.getMyCollection();
        if (userInfomation) {
            if (!userInfomation.error) {
                if (!userInfomation.user.active) {
                    if (
                        self.$router.currentRoute.value.fullPath != "/link_email_account"
                    ) {
                        this.$swal
                            .fire({
                                title:
                                    "Please link your account with an email address. After inserting your email, you will need to verify it through the link you receive in your inbox.",
                                showCancelButton: true,
                                confirmButtonText: "Link email",
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    self.$router.push("/link_email_account");
                                }
                            });
                    }
                }
            }
        }
    },

    updated() { },

    watch: {
        totalItems: function () {
            let data = {
                items: this.itemsOnCart,
                count: this.totalItems,
                price: this.totalPrice,
            };
            console.log("data", data);
            //this.getPriceInCart();
        },
        type: function () {
            if (this.type == "collection") {
                // this.getMyCollection();
            }
        },
    },
};
</script>
